import React from 'react'
import { Form, Input, Button, Table, Pagination, Divider, Modal,Tag } from 'antd'
import { withRouter } from 'react-router-dom'
import { expressTemplateLists, expressTemplateDelete } from '@/api/express'
import message from '@/utils/message'
const FormItem = Form.Item

class ExpressTemplate extends React.Component{
    state = {
        searchForm:{
            name:''
        },
        columns:[
            {
                title:'模板名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div>
                                {rowData.name || ''}
                                {
                                    rowData.is_free_post == 1
                                        ?
                                        <Tag color={'purple'} className={'mgl10'}>默认模板</Tag>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'计费方式',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            { rowData.type == 1 ? '按件数' : '按重量' }
                        </div>
                    )
                }
            },
            {
                title:'配送区域',
                dataIndex:'province_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.province_name || ''}</p>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.is_free_post == 1 ? '--' : rowData.created_at }</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.is_free_post == 1
                                    ?
                                    <div>
                                        <p>系统默认不支持修改</p>
                                    </div>
                                    :
                                    <div>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.handleDetail(rowData,index) } }>编辑</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.handleDelete(rowData,index) } }>删除</Button>
                                    </div>
                            }

                        </div>
                    )
                }
            },
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page
        this.setState({
            tableLoading:true
        })
        expressTemplateLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.data.data,
                        total = res.data.data.total,
                        per_page = res.data.data.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:'/express/add-template'
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleDelete = (rowData,index) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:'是否删除这个快递模板？',
            onOk:()  => {
                expressTemplateDelete(sendData)
                    .then(res => {
                         if(res.code == 1){
                             message.success('操作成功')
                                 .then(() => {
                                     this.setState({
                                         page:1
                                     },() => {
                                           this.fetchData();
                                     })
                                 })
                         }
                    })

            }
        })
    }
    handleDetail = (rowData,index) => {
        this.props.history.push({
            pathname:`/express/add-template/${rowData.id}`
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'运费模板'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索运费模板名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增运费模板</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default Form.create({})(withRouter(ExpressTemplate))
