import React from 'react'
import { Form, Input, Radio, DatePicker, Select, Tooltip, Icon, Checkbox, Button, Table, Pagination, Divider, Switch, TimePicker } from 'antd'
import { withRouter } from 'react-router-dom'
import { panicSave, panicDetail ,flowZoneMove} from '@/api/panic-buying'
import { storeLists } from '@/api/store'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import { timeDiscountSave, timeDiscountDetail } from '@/api/time-discount'
import { labelGroupList} from '@/api/user'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment'
import message from '@/utils/message'
import SetItemDiscount from './components/set-item-discount'
import MarketItemPicker from './components/market-item-picker'
import GoodsModal from './components/goodsList';
import ZoneList from './components/zoneList'
import { array } from 'prop-types'
const FormItem = Form.Item;
const format = 'HH:mm';

class AddPanicBuying extends React.Component {
    state = {
        step: 1,
        formData: {},
        isLoading: false,
        storeLists: [],
        tagsLists:[],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        currentItem: '',
        searchText: '',
        searchedColumn: '',
    }
    componentDidMount() {
        // this.fetchStore();
        // this.fetchTages()
        if (this.props.match.params.id) {
            this.fetchData()
        }

    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
            .finally(() => {
            })
    }
    fetchTages = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        labelGroupList(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,arr=[];

                    lists.forEach(item=>{
                        let ids=item.label_value.split(','),
                            names=item.label_value_name.split(',');
                        ids.forEach((val,i)=>{
                            arr.push({
                                id:item.id+'_'+ids[i],
                                name:item.group_name+'-'+names[i]
                            })
                        })
                    })


                    this.setState({
                        tagsLists: arr
                    })
                }
            })
            .finally(() => {
            })
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        panicDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail
                    if(detail.goods_list){
                        detail.goods_list= detail.goods_list.map(val=>{
                            val.disabled=true;
                            return val
                        })
                    }
                    detail.goods = detail.goods_list
                    this.setState({
                        formData: detail,
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            // eslint-disable-next-line default-case
            switch (name) {
                case 'cover_img':
                    formData.cover_img = imgs[0];
                    break;
                case 'share_img':
                    
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleItem = () => {
        let tableData = this.state.tableData || []
        this.MarketItemPicker.show(res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }
                    })
                    lists.push(item)
                })
                this.setState({
                    tableData: lists
                })
            }
        })
    }
    handleMove=(item,i)=>{
      
        let qianggou_id=this.state.formData.id ;
        this.ZoneList.show(res => {
            if (res && res.length) {
                let target_qianggou_id=res[0].id;
                let sendData={
                    qianggou_id,
                    target_qianggou_id,
                    goods_id:item.id
                }

                this.setState({
                    isLoading: true
                })
                flowZoneMove(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            this.handleDelete(item,i)
                            message.success('操作成功！')
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    onchangeStatus = async (checked) => {
        let formData = this.state.formData || {};
        if (checked === false) {
            formData.status = 2
        } else if (checked === true) {
            formData.status = 1
        }
        this.setState({
            formData
        })
    }

    onchangeOpen = async (checked) => {
        let formData = this.state.formData || {};
        if (checked === false) {
            formData.weekday_open = 0
        } else if (checked === true) {
            formData.weekday_open = 1
        }
        this.setState({
            formData
        })
    }

    handleEdit = (rowData, index) => {
        let sendData = {},
            tableData = this.state.formData || {};
            index=tableData.goods.findIndex(val=>val.id==rowData.id)
        for (var i in rowData['goods_extend']) {
            if (!rowData[i]) {
                rowData[i] = rowData['goods_extend'][i]
            }
        }
        delete rowData['goods_extend']
        this.setState({
            currentItem: rowData
        }, () => {
            this.SetItemDiscount.show(result => {
                if (result.item.sku_list && result.item.sku_list.length) {
                    let tmp_stock = 0,
                        sku_price_arr = []
                    result.item.sku_list.forEach((sku, skuKey) => {
                        if (sku.is_join) {
                            sku.stock = 1;
                            // sku.stock = sku.stock ? Number(sku.stock) : 0
                            tmp_stock = tmp_stock + sku.stock;

                            sku.sales_price = sku.sales_price ?? sku.price;
                            sku_price_arr.push(parseFloat(sku.sales_price))
                        }
                    })
                    result.item.stock = tmp_stock;
                    if (sku_price_arr.length) {
                        if (Math.min(...sku_price_arr) == Math.max(...sku_price_arr)) {
                            result.item.sales_price = `${Math.max(...sku_price_arr).toFixed(2)}`
                        } else {
                            result.item.sales_price = `${Math.min(...sku_price_arr).toFixed(2)}~${Math.max(...sku_price_arr).toFixed(2)}`
                        }
                    }
                }   
                rowData = result.item;
                rowData.sort = result.sort || 0;
                rowData.basic_sales = result.basic_sales || 0;
                rowData.can_sale_stock = result.item.stock;
                rowData.sales_price = result.item.sales_price ?? result.item.price;

                tableData.goods[index] = rowData;
                this.setState({
                    formData: {...tableData}
                })
                console.log('----------',this.state.formData)
            })
        })
    }
    handleNextStep = () => {
        let formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for (var i in values) {
                    formData[i] = values[i]
                }
                formData.start_time = moment(formData.start_time).format('YYYY-MM-DD HH:mm:ss')
                formData.end_time = moment(formData.end_time).format('YYYY-MM-DD HH:mm:ss')
                if(values.label_ids){
                    formData.label_ids=values.label_ids.join(',');
                }
                this.setState({
                    formData,
                    step: 2
                })
            }
        })

    }

    showCoupon = ()=> {
        let row = [];
        let disable_ids = [];
        let formData = this.state.formData || {};
        // row = formData.goods_ids;
        if(formData.goods){
            formData.goods.forEach((item,index)=>{
                if(item.disabled){
                    disable_ids.push(item.id)
                }
                row.push(item.id)
            })
        }
        this.couponCreate.show(row,disable_ids,(res)=>{
            
        })
    }

    handleCouponAdd = res =>{
        console.log('子组件传递的值', res);
        let formData = this.state.formData;
        // formData.goods = res
        res.forEach((item,index)=>{
            if(formData.goods){
                formData.goods.forEach((items,indexs)=>{
                    if(item.id == items.id){
                        res[index] = items
                    }else if(item.sku_list && item.sku_list.length== 0){
                        res[index].sales_price = item.price;
                        res[index].stock = 1;
                    }
                })
            }
        })

        formData.goods = res
        this.setState({
            formData:{...formData}
        },()=>{
            console.log(this.state.formData)
        })
        // console.log(this.state.formData)
    }

    
    handlePrevStep = () => {
        this.setState({
            step: 1
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for (var i in values) {
                    formData[i] = values[i]
                }
                formData.goods=formData.goods?formData.goods:[];
                formData.goods_num = formData.goods.length;
                let arr = [];
                formData.goods.forEach((item,index) => {
                    // arr.push(item.id)
                    let sku_list=[]
                    if(item.sku_list){
                        sku_list=item.sku_list.map(val=>{
                            
                            return {
                                id:val.id,
                                is_join:val.is_join ? 1 : 0,
                                price:val.sales_price  ?? val.price ,
                                // stock:val.stock ?? 0
                                name:val.name,
                                stock:1
                            }
                        })
                    }
        
                    let goods_list =  {
                        id:item.id,
                        price:item.sales_price ?? item.price,
                        stock:item.stock ||1 ,
                        sku_list,
                        sort:item.sort,
                        basic_sales:item.basic_sales,
                        name:item.name
                    }
                    arr.push(goods_list)
                })
                console.log(formData.start_at)
                formData.start_at = moment(formData.start_at).format(format)
                formData.end_at = moment(formData.end_at).format(format)
                formData.weekday_open = formData.weekday_open? 1: 0;
                formData.status = formData.status? 1: 2;

                this.setState({
                    formData
                })
                this.setState({
                    isLoading: true
                })
                sendData = {
                    cover_img:formData.cover_img ,
                    id:formData.id ||undefined,
                    name:formData.name ,
                    start_at:formData.start_at ,
                    status:formData.status ,
                    sub_title:formData.sub_title ,
                    type:formData.type ,
                    weekday_open:formData.weekday_open ,
                    end_at:formData.end_at ,
                    goods_list:arr
                }
                panicSave(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('保存成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname: `/panic-buying/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }
    handleDelete = (rowData, index) => {
        let formData = this.state.formData;
        index=formData.goods.findIndex(val=>val.id==rowData.id)
        formData.goods.splice(index, 1);
        this.setState({
            formData
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`请输入`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              搜索
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              重置
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, fontSize: '20px',marginTop:'-5px' }} />
        ),
        onFilter: (value, record) =>{
            return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        }
    })
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    }
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 14
            }
        }

        const columns=[
            {
                title: '商品名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`lh25`}>{ rowData.name || `-` }</div>
                            </div>
                        </div>
                    )
                },
                ...this.getColumnSearchProps('name'),
            },
            {
                title: '价格',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <p>&yen;{rowData.price}</p>
                    )
                }
            },
            {
                title: '活动价',
                dataIndex: 'sales_price',
                width:"100px",
                render: (text, rowData, index) => {

                    if (!rowData.sales_price) {
                        return <div>未设置</div>
                    }
                    return (
                        <div>&yen;{rowData.sales_price}</div>
                    )
                }
            },
            {
                title: '活动库存',
                dataIndex: 'can_sale_stock',
                width:"100px",
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {/* {
                                rowData.stock_edit
                                    ?
                                    <Input
                                        style={{ width: '60px' }}
                                        value={rowData.can_sale_stock || ''}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.showStockEdit(e, rowData, index, false) }}
                                        data-name={`can_sale_stock`}
                                    />
                                    : */}
                                    <span>{rowData.stock ?? `未设置`}</span>
                            {/* } */}
                            {/* {
                                rowData.sku_list && rowData.sku_list.length ? null :
                                    <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showStockEdit(e, rowData, index, true) }} data-name={`can_sale_stock`} />
                            } */}

                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '25%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                           
            
                            {
                                rowData.disabled?
                                <>
                                 <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleMove(rowData, index) }}>移至流单专区</Button>
                                </>
                                :
                                <>
                                 <Divider type={`vertical`}></Divider>
                                 <Button type={"link"} size={"small"} onClick={() => { this.handleDelete(rowData, index) }}>删除</Button>
                                </>
                            }
                           
                        </div>
                    )
                }
            },
        ]

        return (
            <div className={`shadow-radius`}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <MarketItemPicker
                    onRef={e => { this.MarketItemPicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></MarketItemPicker>
                <SetItemDiscount
                    onRef={e => { this.SetItemDiscount = e }}
                    item={this.state.currentItem}
                ></SetItemDiscount>
                <ZoneList
                     onRef={e => { this.ZoneList = e }}
                     item={[]}
                ></ZoneList>
                <Form {...formItemLayout} className={'model-form'}>
                    {
                        this.state.step == 1
                            ?
                            <div>
                                <div className={`msg-title mgb15`}>基本信息</div>
                                <div className="flex">
                                    <div className={`flex1`}>

                                        <FormItem label={'活动标题'}>
                                            {getFieldDecorator('name', {
                                                initialValue: data && data.name,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入活动标题'
                                                    }
                                                ]
                                            })(<Input
                                                placeholder={'请输入活动标题，限12字符'}
                                                maxLength={20}
                                                suffix={<span>{data.name && data.name.length ? data.name.length : 0}/12</span>}
                                            />
                                            )}
                                        </FormItem>
                                        <FormItem label={'活动副标题'}>
                                            {getFieldDecorator('sub_title', {
                                                initialValue: data && data.sub_title,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入活动副标题'
                                                    }
                                                ]
                                            })(<Input
                                                placeholder={'请输入活动副标题，限30字符'}
                                                maxLength={50}
                                                suffix={<span>{data.sub_title && data.sub_title.length ? data.sub_title.length : 0}/50</span>}
                                            />
                                            )}
                                        </FormItem>
                                        <FormItem label={'活动封面'} required={true}>
                                            <div className={`clearfix`}>
                                                {
                                                    data.cover_img
                                                        ?
                                                        <PictureCard
                                                            onRef={ e => { this.PictureCard = e } }
                                                            imgs={ data.cover_img }
                                                            onDel={ e => { this.delImg(e,'cover_img') } }
                                                        ></PictureCard>
                                                        :
                                                        <ImgAdd
                                                            onRef={ e => { this.ImgAdd = e } }
                                                            onClick={ e => { this.chooseImg(e,'cover_img') } }
                                                        ></ImgAdd>
                                                }
                                            </div>
                                            <span className={'fi-help-text'}>建议尺寸：750x326像素</span>
                                        </FormItem>
                                        <FormItem
                                            label={
                                                <span>
                                                    活动类型
                                                </span>
                                            }
                                        >
                                            {getFieldDecorator('type', {
                                                initialValue: data.type || 1,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择活动类型'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group disabled={!!data.id}>
                                                    <Radio value={1}>流单专场</Radio>
                                                    <Radio value={2}>指定商品</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        <FormItem label={<span>指定商品</span>}>
                                            <Button onClick={this.showCoupon} disabled={getFieldValue('type')==1?true:false}>选择商品</Button>
                                            <span className='fz12 mgl20'>最多添加200件商品</span>
                                            <div className={'content-body mgt10'}>
                                                {this.state.formData.goods && this.state.formData.goods.length>0 ? <Table
                                                    size={`middle`}
                                                    columns={columns}
                                                    dataSource={data.goods}
                                                    loading={this.state.tableLoading}
                                                    rowKey={(record, index) => index}
                                                    // pagination={false }
                                                ></Table> : null } 
                                            </div>
                                        <div className={'clearfix mgt10'}>

                                        </div>
                                        </FormItem>
                                        <FormItem label={'每天活动时间'} required={true}>
                                            <div className={'form-group'}>
                                                {
                                                    getFieldDecorator('start_at', {
                                                        initialValue: data.start_at ? moment(data.start_at, format) : null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择开始时间'
                                                            }
                                                        ]
                                                    })(
                                                        <TimePicker defaultValue={moment('00:00', format)} format={format} />
                                                    )
                                                }
                                                 至 
                                                {
                                                    getFieldDecorator('end_at', {
                                                        initialValue: data.end_at ? moment(data.end_at, format) : null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择结束时间'
                                                            }
                                                        ]
                                                    })(
                                                        <TimePicker defaultValue={moment('00:00', format)} format={format} />
                                                    )
                                                }
                                            </div>
                                        </FormItem>
                                        <FormItem
                                            label={
                                                <span>
                                                    允许工作日开放
                                                </span>
                                            }
                                        >
                                            {getFieldDecorator('weekday_open', {
                                                initialValue: data.weekday_open || 1,
                                            })(
                                                <Switch checked={data.weekday_open == 1 ? true : false} onChange={this.onchangeOpen}></Switch>
                                            )}
                                             <span className={'fi-help-text'}>开启后，周一至周五有活动，关闭仅周六、周日有活动</span>
                                        </FormItem>
                                        <FormItem
                                            label={
                                                <span>
                                                    立即启用
                                                </span>
                                            }
                                        >
                                            {getFieldDecorator('status', {
                                                initialValue: data.status || 1,
                                            })(
                                                <Switch checked={data.status == 1 ? true : false}  onChange={this.onchangeStatus}></Switch>
                                            )}
                                        </FormItem>
                                        <div className={`txtCenter`}>
                                            <Button type={`primary`} onClick={this.saveData}>保存</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </Form>
                {this.state.isLoading ? <Loading /> : null}
                <GoodsModal onRef={e => { this.couponCreate = e }} sendFn={this.handleCouponAdd}></GoodsModal>
            </div>
        )
    }

}

export default Form.create({})(withRouter(AddPanicBuying))

