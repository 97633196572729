import React from 'react'
import { Form, Input, Button, Select, Icon, Radio } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import { connect } from 'react-redux'
import { setAdmin } from '@/redux/actions/admin'
import { setShop } from '@/redux/actions/shop'
import ljw from '@/utils/ljw'
import { adminComplete, adminDetail } from '@/api/admin'

const FormItem = Form.Item;

class ShopInfo extends React.Component{
    state = {
        formData:{},
        isLoading:false

    }

    componentDidMount() {
        this.fetchAdmin()
        let arr = [3,2,5,4,1,6,7,9,8];
        //console.log(ljw.bubbleSort(arr))
        //console.log('quickSort',ljw.quickSort(arr))
       //console.log('mergeSort',ljw.mergeSort(arr))
      //console.log('selectSort',ljw.selectSort(arr))
       //console.log('insertSort',ljw.insertSort(arr))
       console.log('shellSort',ljw.shellSort(arr))

    }
    fetchAdmin = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        adminDetail(sendData)
            .then(res =>  {
                if(res.code == 1){
                    let admin = res.data.admin,
                        shop = res.data.shop;
                    this.setState({
                        formData:shop
                    })
                    this.props.setShop(shop);
                    this.props.setAdmin(admin);
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'logo':
                case 'staff_qrcode':
                    formData[name] = imgs[0];
                    break;
                default:
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                console.log(values)
                sendData = values;
                sendData.logo = formData.logo || '';
                sendData.staff_qrcode = formData.staff_qrcode || '';
                this.setState({
                    isLoading:true
                })
                adminComplete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchAdmin();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })


            }
        })

    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={`shadow-radius`}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form {...formItemLayout} className={'model-form'}>
                    {/*<div className={`msg-title mgb15`}></div>*/}
                    <FormItem label={'品牌名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || '',
                            rules: [
                                {
                                    required: true,
                                    message: '请输入品牌名称'
                                }
                            ]
                        })(<Input
                            placeholder={'品牌名称，限30字符'}
                            maxLength={30}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/30</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'品牌Logo'} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.logo
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.logo }
                                        onDel={ e => { this.delImg(e,'logo') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'logo') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>建议上传尺寸：750px*750px</span>
                    </FormItem>
                    <FormItem label={'客服'}>
                        {getFieldDecorator('staff_type', {
                            initialValue: data.staff_type  || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择客服类型'
                                }
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>客服电话</Radio>
                                <Radio value={2}>客服二维码</Radio>
                                {/* <Radio value={3}>小程序官方客服</Radio> */}
                            </Radio.Group>
                        )}
                        <div className={`fi-help-text`}>选择不同的客服对接方式将在商城内用不同形式展现，请根据需求配置</div>
                    </FormItem>
                    {
                        getFieldValue('staff_type') == 1
                            ?
                            <FormItem label={'客服电话'}>
                                {getFieldDecorator('staff_phone', {
                                    initialValue: data.staff_phone || ``,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入联系电话'
                                        }
                                    ]
                                })(<Input placeholder={'电话号码'} maxLength={30} />)}
                            </FormItem>
                            :
                            null
                    }
                    {
                        getFieldValue('staff_type') == 2
                            ?
                            <FormItem label={'客服二维码'} required={true}>
                                <div className={'clearfix'}>
                                    {
                                        data.staff_qrcode
                                            ?
                                            <PictureCard
                                                onRef={ e => { this.PictureCard = e } }
                                                imgs={ data.staff_qrcode }
                                                onDel={ e => { this.delImg(e,'staff_qrcode') } }
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={ e => { this.ImgAdd = e } }
                                                onClick={ e => { this.chooseImg(e,'staff_qrcode') } }
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>
                                可上传个人微信码，群码或企业微信码。<br />开启后，web端用户点击客服时会弹出对应的二维码，便于维护自己的客户。
                                </span>
                            </FormItem>
                            :
                            null
                    }

                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>
                </Form>
                {  this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
    setAdmin:data => {
        dispatch(setAdmin(data))
    },
    setShop:data => {
        dispatch(setShop(data))
    }
})


export default Form.create({})(withRouter(connect(mapStateToProps,mapDispatchToProps)(ShopInfo)))

