import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form, Icon, Tag, Tooltip, Input, Select, DatePicker, Button, Popover, Tabs } from 'antd'
import { GetFxUser, FxUserChange, ChangeFxCommission, ChangeRefer } from '@/api/fx'
import Loading from '@/components/Loading'
import moment from 'moment'
import message from '@/utils/message'
import SubGuest from './sub-guest'
import SubFx from './sub-fx'
import FxCommissionSettled from './fx-commission-settled'
import FxCommissionRecord from './fx-commission-record'
import FxWithdrawRecord from './fx-withdraw-record'
import ChangeFx from './change-fx'
import FxPicker from '@/components/FxPicker/FxPicker'
import '@/views/user/components/UserDetail.scss'
const FormItem = Form.Item;
const { RangePicker } = DatePicker
const { TextArea } = Input
const { TabPane } = Tabs

class FxUserDetail extends React.Component {
    state = {
        user: '',
        agent:'',
        static_data:'',
        isLoading: false,
        storeLists: [],
        tableLoading: false,
        popVisible: false,
        page: 1,
        total: 0,
        per_page: 0,
        activeStatus:'1'


    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        if (this.props.user_id) {
            sendData.user_id = this.props.user_id;
            this.setState({
                isLoading:true
            })
            GetFxUser(sendData)
                .then(res => {
                    if(res.code == 1){
                        let user = res.data.user,
                            agent = res.data.agent,
                            static_data = res.data.static_data;
                        this.setState({
                            user, agent, static_data,
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }

    }
    handleCancel = () => {
        let sendData = {}
        this.ChangeFx.show(res => {
            if(res){
                console.log(res)
                sendData.type = res.type || '';
                sendData.target_user_id = res.target_user_id || '';
                sendData.user_id = this.props.user_id;
                this.setState({
                    isLoading: true
                })
                FxUserChange(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.onClose && this.props.onClose();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }
    handleInputChange = (e) => {
        let formData = this.state.formData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value;
        formData[name] = val;
        this.setState({
            formData
        })
    }
    handleEdit = (e) => {
        let editForm = this.state.editForm,
            name = e.currentTarget.dataset.name || '';
        editForm[name] = true;
        this.setState({
            editForm
        })

    }
    handleSaveCommission = () => {
        let sendData = {}
        if (this.props.user_id) {
            sendData.user_id = this.props.user_id;
            this.setState({
                isLoading: true
            })
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    sendData.change_commission = values.change_commission;
                    sendData.remark = values.remark;
                    ChangeFxCommission(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success('操作成功')
                                    .then(() => {
                                        this.closePop();
                                        this.props.onClose && this.props.onClose();
                                    })

                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                }
            })
        }
    }
    closePop = () => {
        let datas = {}
        datas[`change_commission`] = '';
        datas['remark'] = ''
        this.props.form.setFieldsValue(datas)
        this.setState({
            popVisible: false
        })
    }
    handlePop = () => {
        this.setState({
            popVisible:true
        })
    }
    handleChangeSuper = () => {
        let sendData = {};
        sendData.user_id = this.props.user_id;
        this.FxPicker.show(res => {
            if(res && res.length){
                sendData.target_user_id = res[0]['user_id'];
                this.setState({
                    isLoading: true
                })
                ChangeRefer(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.onClose && this.props.onClose();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })


            }
        })
    }
    clickTab = (val) => {
        this.setState({
            activeStatus:val
        })
    }

    render() {
        let user = this.state.user || {},
            agent = this.state.agent || {},
            static_data = this.state.static_data || {};
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }
        const content = (
            <div>
                <Form {...formItemLayout} className={`model-form`}>
                    <FormItem label={`增减佣金`} className={`mgb5`}>
                        {
                            getFieldDecorator('change_commission', {
                                initialValue: '',
                            })(
                                <Input placeholder='请输入' />
                            )
                        }
                        <span className={`fi-help-text`}>请输入要增/减的佣金金额（-1代表减1元佣金）</span>
                    </FormItem>
                    <FormItem label={`备注`} className={`mgb5`}>
                        {
                            getFieldDecorator('remark', {
                                initialValue: '',
                            })(
                                <TextArea rows={2} maxLength={20} placeholder='最多输入20个字' />
                            )
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false} className={`clearfix mgb5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={this.handleSaveCommission}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={this.closePop}>取消</Button>
                    </FormItem>
                </Form>
            </div>
        )
        return (
            <div className={`drawer-cont`}>
                <div className={`msg-title`}></div>
                <div className={`flex justify-between align-center pd20`}>
                    <div className={`flex jusify-start align-center`}>
                        <img src={user.head_img_url} alt="" width={60} />
                        <div className={`mgl10`}>
                            <div className={`lh30`}>
                                { user.name || '-' }
                                <i className={`icon-${user.sex == 1 ? 'male' : user.sex == 2 ? 'female' : ''}`}></i>
                            </div>
                            <div>
                                <Tag>初始等级</Tag>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`btn-row`}>
                            <Button type={`default`} style={{ width:'120px' }} onClick={this.handleCancel}>取消身份</Button>
                            <Popover
                                content={content}
                                title={null}
                                placement={`top`}
                                trigger="click"
                                visible={this.state.popVisible}>
                                <Button type={`default`} style={{ width:'120px' }} onClick={this.handlePop}>调整佣金</Button>
                            </Popover>
                            <Button type={`default`} style={{ width:'120px' }} onClick={this.handleChangeSuper}>调整邀请人</Button>
                        </div>
                        <div className={`btn-row`}>
                        </div>
                    </div>

                </div>
                <div className={`msg-title`}></div>
                <div className={'user-content'}>
                    <div className={`user-content-wrapper`}>
                        <div className={`user-info-wrapper`}>
                            <div className={`user-card h100`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>基本信息</div>
                                    </div>
                                </div>
                                <div className={`user-card-body`}>
                                    <ul className={`user-info`} style={{ marginBottom: '0px' }}>
                                         <li className={`user-info-li`}>
                                            <div className={`left`}>手机号</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{ user.mobile || '-' }</div>
                                            </div>
                                        </li>
                                         <li className={`user-info-li`}>
                                            <div className={`left`}>邀请人</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{ user.first_user ? user.first_user.name : '-' }</div>
                                            </div>
                                        </li>
                                         <li className={`user-info-li`}>
                                            <div className={`left`}>成为分销员时间</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{ agent.created_at }</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`flex1`}>
                            <div className={`user-card`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>分销数据</div>
                                    </div>
                                </div>
                                <div className={`user-card-body inner`} style={{ padding: '40px 0px' }}>
                                    <div className={`overview-panel-wrapper fx-panel`}>
                                        <div className={`panel-item`} >
                                            <div className={`column`}>
                                                <div className={`title`}>下级客户数</div>
                                                <div className={`value`}>{ static_data.lower_num }</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>下级分销员数</div>
                                                <div className={`value`}>{ static_data.lower_agent_num }</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>累计收益</div>
                                                <div className={`value`}>&yen;{ static_data.total_commission }</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>累计分销订单数</div>
                                                <div className={`value`}>{ static_data.trade_num }</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>
                                                    待结算佣金
                                                    <Tooltip placement="topLeft" title={`包含订单待使用，待确认收货状态下的佣金，需要发放佣金金额的总和`}>
                                                        <Icon type="question-circle"  className={`mgl5`} />
                                                    </Tooltip>
                                                </div>
                                                <div className={`value`}>&yen;{ static_data.to_handle_commission }</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>可提现金额</div>
                                                <div className={`value`}>&yen;{ static_data.commission }</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="msg-title"></div>
                    <div className={`fx-tab-cont`}>
                        <Tabs onChange={this.clickTab} type="card" className={''} activeKey={this.state.activeStatus}>
                            <TabPane tab="下级客户" key={`1`}>
                                <SubGuest
                                    onRef={ e => { this.SubGuest = e } }
                                    user_id={ this.props.user_id || '' }
                                ></SubGuest>
                            </TabPane>
                            <TabPane tab="下级分销员" key={`2`}>
                                <SubFx
                                    onRef={ e => { this.SubFx = e } }
                                    user_id={ this.props.user_id || '' }
                                ></SubFx>
                            </TabPane>
                            <TabPane tab="收益订单" key={`3`}>
                                <FxCommissionSettled
                                    onRef={ e => { this.FxCommissionSettled = e } }
                                    user_id={ this.props.user_id || '' }
                                ></FxCommissionSettled>
                            </TabPane>
                            {/* <TabPane tab="佣金明细" key={`4`}>
                                <FxCommissionRecord
                                    onRef={ e => { this.FxCommissionRecord = e } }
                                    user_id={ this.props.user_id || '' }
                                ></FxCommissionRecord>
                            </TabPane> */}
                            <TabPane tab="提现记录" key={`5`}>
                                <FxWithdrawRecord
                                    onRef={ e => { this.FxWithdrawRecord = e } }
                                    user_id={ this.props.user_id || '' }
                                ></FxWithdrawRecord>
                            </TabPane>
                        </Tabs>

                    </div>

                </div>
                {this.state.isLoading ? <Loading /> : null}
                <ChangeFx onRef={ e => { this.ChangeFx = e } }></ChangeFx>
                <FxPicker onRef={ e => { this.FxPicker = e } }></FxPicker>
            </div>
        )
    }

}
export default Form.create({})(withRouter(FxUserDetail))
