let LINK_TYPES  = [
    {
        label:'常用链接',
        type:1,
        options:[
            { linkType:7, name:'自定义链接', link:''},
            { linkType:0, name:'店铺首页', link:'/pages/index/index'},
            { linkType:1, name:'商品列表', link:'/pages/item/lists'},
            { linkType:2, name:'个人中心', link:'/pages/mine/mine'},
            { linkType:4, name:'积分商城', link:'/pages/pointMall/index'},
            { linkType:5, name:'领券中心', link:'/pages/coupon/center'},
            { linkType:8, name:'分销员', link:'/pages/fx/index'}
        ]
    },
    {
        label:'商品链接',
        type:2,
        options:[
            { linkType:6, name:'商品详情', link:'/pages/item/detail' }
        ]
    },
    // {
    //     label:'课程链接',
    //     type:5,
    //     options:[
    //         { linkType:16, name:'课程详情', link:'pages/course/detail' }
    //     ]
    // },
    // {
    //     label:'拨打电话',
    //     type:3,
    //     options:[
    //         { linkType:11, name:'拨打电话', link:'' }
    //     ]
    // },
    // {
    //     label:'地图导航',
    //     type:4,
    //     options:[
    //         { linkType:12, name:'地图导航', link:'', lat:'', lng:'', address:'' }
    //     ]
    // },


]
export default LINK_TYPES
