import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Radio, Input, Checkbox, Modal, Button } from 'antd'
import Sortable from 'sortablejs'
import { userCenterConfig, setConfig } from '@/api/config'
import { adminDetail } from '@/api/admin'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import UserStyle3 from './components/user-style3'
import defaultData from './constants/default'
import message from '@/utils/message'
import '@/assets/css/miniprogram/user-center.scss'
import Loading from '@/components/Loading'
const FormItem = Form.Item

class UserCenter extends React.Component {
    state = {
        version_id: '',
        formData: '',
        style: 3,
        isLoading: false,
        default_img1: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/4a7ba5fa6ca563d77bc2914de0883b6a.png',
        default_img2: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/01ec0cd4af17f4cc63a4c0b4e98d99a2.png'
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading: true
        })
        userCenterConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    let config = res.data.config,
                        formData = config.data,
                        style = config.style

                    this.setState({
                        formData, style
                    }, () => {
                        this.initSort()
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    initSort = () => {
        let sortable = new Sortable(this.refs.userCenterTable, {
            group: 'shared', // set both lists to same group
            animation: 150,
            onEnd: (event) => {
                this.handleSortEnd(event)
            }
        })
    }
    handleSortEnd = (e) => {
        let formData = this.state.formData || {},
            style = this.state.style || 1,
            oldIndex = e.oldIndex,
            newIndex = e.newIndex,
            current = formData[style] || '';
        if (current) {
            if (oldIndex == newIndex) {
                return;
            }
            let targetRow = current['userCenter'].splice(oldIndex, 1)[0];
            current['userCenter'].splice(newIndex, 0, targetRow);
            this.setState({
                formData
            })
        }

    }
    handleStyleChange = (e) => {
        let val = e.target.value;
        this.setState({
            style: val
        })
    }
    handleRadioChange = (e, keyName) => {
        let val = e.target.value,
            formData = this.state.formData || {},
            style = this.state.style;
        formData[style][keyName] = val;
        this.setState({
            formData
        })
    }
    chooseImg = (item, index, keyName) => {
        let formData = this.state.formData || {},
            style = this.state.style || 1,
            current = formData[style] || '';
        this.Gallery.show(res => {
            if (res) {
                current[keyName][index]['icon'] = res[0];
                this.setState({ formData })

            }
        })
    }
    handleCheckChange = (e, item, index, keyName) => {
        let val = e.target.checked ? 1 : 0,
            formData = this.state.formData || {},
            style = this.state.style || 1,
            current = formData[style] || '';
        if (current) {
            current[keyName][index]['isShow'] = val
        }
        this.setState({
            formData
        })
    }
    handleReset = (item, index, keyName) => {
        let style = this.state.style,
            formData = this.state.formData || {},
            current = defaultData[style];
        for (var i in current) {
            if (typeof current[i] == 'object') {
                for (var j in current[i]) {
                    if (current[i][j]['id'] == item.id) {
                        formData[style][keyName][index] = current[i][j];
                        this.setState({
                            formData
                        })
                    }
                }
            }
        }
    }
    handleInputChange = (e, item, index, keyName) => {
        let formData = this.state.formData || {},
            style = this.state.style || 1,
            current = formData[style] || '',
            val = e.target.value;
        if (current) {
            current[keyName][index]['name'] = val
        }
        this.setState({
            formData
        })

    }
    chooseBgImg = (e) => {
        let formData = this.state.formData || {},
            style = this.state.style || 1;
        this.Gallery.show(imgs => {
            formData[style]['bgImg'] = imgs[0];
            this.setState({
                formData
            })
        })
    }
    handleResetImg = (keyNmae) => {
        return () => {
            const formData = this.state.formData || {},
                style = this.state.style || 1;
            formData[style][keyNmae] = this.state['default_img' + style]
            this.setState({
                formData
            })
        }
    }

    delBgImg = (e, keyName) => {
        let formData = this.state.formData || {},
            style = this.state.style || 1;
        formData[style]['bgImg'] = '';
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            config = {}
        config.style = this.state.style;
        config.data = this.state.formData || {};
        sendData.key = 'user_center_style';
        sendData.value = config;
        this.setState({
            isLoading: true
        })
        setConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    message.success('保存成功')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }
    resetAll = () => {
        let formData = this.state.formData || {},
            tmp = JSON.stringify(defaultData),
            style = 3;
        Modal.confirm({
            title: `是否重置当前模板数据`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                formData[style] = JSON.parse(tmp)[style];
                this.setState({
                    formData,
                    style
                })
                setTimeout(() => {
                    this.setState({
                        isLoading: false
                    })
                }, 500)

            }
        })
    }

    render() {
        let style = this.state.style || 3,
            data = (this.state.formData ? this.state.formData[style] : {}) || {}
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div className={`shadow-radius`}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <div className={`clearfix mgt15`}>
                    <div className={`diy-user-phone fl`}>
                        <div className={`phone-inner`}>
                            <UserStyle3 onRef={e => { this.UserStyle3 = e }} current={data}></UserStyle3>
                        </div>
                    </div>
                    <div className={`diy-user-ctrl fl mgl20`}>
                        <div className={`msg-title mgb15`}>会员中心设置</div>
                        <Form className={`model-form`} {...formItemLayout}>

                            <FormItem label={`背景图`}>
                                <div className={'clearfix'}>
                                    {
                                        data.bgImg
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.bgImg}
                                                onDel={e => { this.delBgImg(e) }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseBgImg(e) }}
                                            ></ImgAdd>
                                    }
                                    <Button type='link' className='mgt60' onClick={this.handleResetImg('bgImg')}>恢复默认</Button>
                                </div>
                                <span className={'fi-help-text'}>建议上传尺寸：750px*420px</span>
                            </FormItem>
                           
                            <FormItem label={`快捷服务`}>
                                <table className="cxtables cxtables-sku">
                                    <colgroup>
                                        <col width={`20%`} />
                                        <col width={`20%`} />
                                        <col width={`15%`} />
                                        <col width={`20%`} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <td>模块名称</td>
                                            <td>图标(85px * 85px)</td>
                                            <td>是否显示</td>
                                            <td>操作</td>
                                        </tr>
                                    </thead>
                                    <tbody ref={'userCenterTable'}>
                                        {
                                            data.userCenter && data.userCenter.map((item, index) => (
                                                <tr key={item.id} style={{ cursor: 'move' }}>
                                                    <td>
                                                        <Input width={100} value={item.name} onChange={(e) => { this.handleInputChange(e, item, index, 'userCenter') }} />
                                                    </td>
                                                    <td>
                                                        <div className={'clearfix txtCenter'}>
                                                            <div className={'img-add'} onClick={() => { this.chooseImg(item, index, 'userCenter') }} data-name={`icon`}>
                                                                {
                                                                    item.icon
                                                                        ?
                                                                        <div>
                                                                            <img src={item.icon} alt="" />
                                                                        </div>
                                                                        :
                                                                        '+'
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Checkbox checked={!!item.isShow} onChange={(e) => { this.handleCheckChange(e, item, index, 'userCenter') }} >显示</Checkbox>
                                                    </td>
                                                    <td>
                                                        <Button type={`default`} size={`small`} onClick={() => { this.handleReset(item, index, 'userCenter') }}>一键还原</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </table>

                            </FormItem>

                            <FormItem label={`更多工具`}>
                                <table className="cxtables cxtables-sku">
                                    <colgroup>
                                        <col width={`20%`} />
                                        <col width={`20%`} />
                                        <col width={`15%`} />
                                        <col width={`20%`} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <td>模块名称</td>
                                            <td>图标(85px * 85px)</td>
                                            <td>是否显示</td>
                                            <td>操作</td>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            data.myContent && data.myContent.map((item, index) => (
                                                <tr key={item.id} >
                                                    <td>
                                                        <Input width={100} value={item.name} onChange={(e) => { this.handleInputChange(e, item, index, 'myContent') }} />
                                                    </td>
                                                    <td>
                                                        <div className={'clearfix txtCenter'}>
                                                            <div className={'img-add'} onClick={() => { this.chooseImg(item, index, 'myContent') }} data-name={`icon`}>
                                                                {
                                                                    item.icon
                                                                        ?
                                                                        <div>
                                                                            <img src={item.icon} alt="" />
                                                                        </div>
                                                                        :
                                                                        '+'
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Checkbox checked={!!item.isShow} onChange={(e) => { this.handleCheckChange(e, item, index, 'myContent') }} >显示</Checkbox>
                                                    </td>
                                                    <td>
                                                        <Button type={`default`} size={`small`} onClick={() => { this.handleReset(item, index, 'myContent') }}>一键还原</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </table>
                            </FormItem>
                            <FormItem label={` `} colon={false}>
                                <Button type={`primary`} onClick={this.saveData}>立即保存</Button>
                                <Button type={`default`} className={`mgl10`} onClick={this.resetAll}>重置数据</Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
export default Form.create({})(withRouter(UserCenter))
