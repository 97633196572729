import Dashboard from '@/views/dashboard/index'
// import StoreLists from '@/views/store/lists'
// import StoreAdd from '@/views/store/add'
import OfficialAccount from '@/views/officialAccount/lists'
import OfficialFansLists from '@/views/officialAccount/fans-lists'
import ItemLists from '@/views/item/lists'
import ItemAdd from '@/views/item/add'
import ItemCategory from '@/views/item/category'
import Stock from '@/views/stock/stock'
import StockManage from '@/views/stock/manage'
import StockDetail from '@/views/stock/detail'
import AddStock from '@/views/stock/add'
import UserLists from '@/views/user/lists'
import UserLabel from '@/views/user/user-label'
import UserDetail from '@/views/user/detail'

import DesignLists from '@/views/design/lists'
import ShopInfo from '@/views/system/shop-info'
import PaySet from '@/views/system/payset'
import PayType from '@/views/system/pay-type'
import AddPaySet from '@/views/system/add-payset'
import ReleaseConfig from '@/views/system/miniapp-release-config'
import ExpressTemplate from '@/views/express/express-template'
import AddExpressTemplate from '@/views/express/add-express-template'
import OrderLists from '@/views/order/lists'
import OrderDetail from '@/views/order/detail'
// import PickUp from '@/views/order/pickup'
import Application from '@/views/application/lists'
import CouponLists from '@/views/coupon/coupon'
import CouponGift from '@/views/coupon/gift'
import CouponAdd from '@/views/coupon/add'
import Finance from '@/views/finance/index'
import Ticket from '@/views/system/ticket'
import ADPOP from '@/views/design/ad-pop'
import Print from '@/views/system/print'

// import TimeDiscountLists from '@/views/timeDiscount/lists'
// import AddTimeDiscount from '@/views/timeDiscount/add'
// import TimeDiscountDetail from '@/views/timeDiscount/detail'

import FillInformation from '@/views/user/fill-information'
import PointMall from '@/views/pointMall/index'
import AddPointGoods from '@/views/pointMall/add-point-goods'

import SystemMessage from '@/views/system/system-message'
import FxMemberLists from '@/views/fx/fx-member-lists'
import FxGoodsLists from '@/views/fx/fx-goods-lists'
import FxCommissionDetail from '@/views/fx/fx-commission-detail'
import FxDataAnalyse from '@/views/fx/fx-data-analyse'
import FxSetting from '@/views/fx/fx-setting'
import FxCardDiy from '@/views/fx/fx-card-diy'
import WithDrawAudit from '@/views/finance/withdraw-audit'

import FinanceBill from '@/views/finance/bill'
import ShortLink from '@/views/miniProgram/short-link'


import LessonLists from '@/views/lesson/lists'
import AddLesson from '@/views/lesson/add'
import LessonCates from '@/views/lesson/cates'


import Staff from '@/views/staff/staff'
import Role from '@/views/staff/role'
import AddRole from '@/views/staff/addRole'

// import CustomFormLists from '@/views/custom-form/lists'
// import CustomFormLog from '@/views/custom-form/log'

// import RecruitLists from "@/views/recruit/lists"
// import RecruitAdd from "@/views/recruit/add"
// import RecruitActivity from "@/views/recruit/activity"
// import RedGame from "@/views/red-game/lists"
// import RedGameAdd from "@/views/red-game/add"
// import RedGameActivity from "@/views/red-game/activity"

import OverallColor from '@/views/design/color'
import TradeSystem from '@/views/system/trade'
import RecommedNote from '@/views/recommend/note'
import NoteAdd from '@/views/recommend/note-add'
import CommentDetail from '@/views/recommend/comment-detail'
import UserSignIn from '@/views/user/sign-in'
import SearchSet from '@/views/design/search'
import UserCenter from '@/views/miniProgram/user-center'


// import LuckyLists from "@/views/lucky/lists"
// import LuckyAdd from "@/views/lucky/add"
// import LotteryDetail from '@/views/lucky/Lottery-detail'
import DataPoints from '@/views/data/points'
import PointsRules  from '@/views/operating-rules/points-rules'
import PointsRulesEdit  from '@/views/operating-rules/points-rules-edit'
// import UserLevel  from '@/views/level/user-level'
// import DevTask  from '@/views/level/dev-task'
// import Equity  from '@/views/level/equity'
// import EquityEdit from '@/views/level/equity-edit'
import ErrorPage from '@/views/error/index'
// import levelEdit from '@/views/level/level-edit'
// import vipNotice from '@/views/level/vip-notice'

import SignLists from '@/views/sign/lists'
import SignSetting from '@/views/sign/setting'
import SignDetail from '@/views/sign/detail'

import UserAddress from '@/views/user/user-address'
import UserIdentity from '@/views/user/user-identity'
import UserCollection from '@/views/user/user-collection'
import PanicBuyingLists from '@/views/panic-buying/lists'
import AddPanicBuying from '@/views/panic-buying/add'
import PanicBuyingDetail from '@/views/panic-buying/detail'
import PanicBuyingSetting from '@/views/panic-buying/setting'

import UserDispatch from '@/views/user/user_dispatch'
import PanicDeOrdertail from '@/views/order/panic-detail'

import PanicLists from '@/views/order/panic-lists'

import SignUserSetting from '@/views/sign/setting-user'


export const routes = [
    { path: '/dashboard', component: Dashboard, withoutSideMenu: true },
    // { path: '/store/lists', component: StoreLists },
    // { path: '/store/add/:id?', component: StoreAdd },
    { path: '/officialAccount/lists', component: OfficialAccount },
    { path: '/miniProgram/user-center', component: UserCenter },
    { path: '/item/lists', component: ItemLists },
    { path: '/item/add/:id?', component: ItemAdd },
    { path: '/item/category', component: ItemCategory },
    { path: '/stock/stock', component: Stock },
    { path: '/stock/manage', component: StockManage },
    { path: '/stock/detail/:code?', component: StockDetail },
    { path: '/stock/add/:type?', component: AddStock },
    { path: '/user/lists', component: UserLists },
    { path: '/user/user-label', component: UserLabel },
    { path: '/user/detail/:id?', component: UserDetail },
   
    { path: '/design/lists', component: DesignLists },
    { path: '/system/shop-info', component: ShopInfo },
    { path: '/system/payset', component: PaySet },
    { path: '/system/add-payset/:id?', component: AddPaySet },
    { path: '/system/system-message', component: SystemMessage },
    { path: '/express/template', component: ExpressTemplate },
    { path: '/express/add-template/:id?', component: AddExpressTemplate },
    { path: '/order/lists/:status?', component: OrderLists },
    { path: '/order/detail/:id?', component: OrderDetail },
    // { path: '/order/pickup', component: PickUp },
    { path: '/application/lists', component: Application },
    { path: '/coupon/lists', component: CouponLists },
    { path: '/coupon/gift', component: CouponGift },
    { path: '/coupon/add/:id?/:type?', component: CouponAdd },
    { path: '/finance/index', component: Finance },
    
    { path: '/system/ticket', component: Ticket },
    { path: '/system/print', component: Print },
    { path: '/design/ad-pop', component: ADPOP },
   

    { path: '/officialAccount/fans-lists', component: OfficialFansLists },
    // { path: '/time-discount/lists', component: TimeDiscountLists },
    // { path: '/time-discount/add/:id?', component: AddTimeDiscount },
    // { path: '/time-discount/detail/:id?', component: TimeDiscountDetail },
  
    { path: '/user/fill-information', component: FillInformation },
    { path: '/point-mall/index', component: PointMall },
    { path: '/point-mall/add-point-goods/:id?', component: AddPointGoods },
    { path: '/fx/member-lists', component: FxMemberLists },
    { path: '/fx/goods-lists', component: FxGoodsLists },
    { path: '/fx/commission-detail', component: FxCommissionDetail },
    { path: '/fx/data-analyse', component: FxDataAnalyse },
    { path: '/fx/setting', component: FxSetting },
    { path: '/fx/card-diy', component: FxCardDiy },
    { path: '/finance/withdraw-audit', component: WithDrawAudit },
   
    { path: '/system/pay-type', component: PayType },
    { path: '/finance/bill', component: FinanceBill },
    { path: '/miniProgram/short-link', component: ShortLink },
    
    { path: '/lesson/lists', component: LessonLists },
    { path: '/lesson/add/:id?', component: AddLesson },
    { path: '/lesson/category', component: LessonCates },
    { path: '/system/miniapp-release-config', component: ReleaseConfig },
   
  
    { path: '/staff/lists', component: Staff },
    { path: '/staff/role', component: Role },
    { path: '/staff/role-add/:id?', component: AddRole },
   
    // { path: '/custom-form/lists', component: CustomFormLists },
    // { path: '/custom-form/log/:id?', component: CustomFormLog },
    
  
    // { path: '/recruit/lists', component: RecruitLists },
    // { path: '/recruit/add/:id?', component: RecruitAdd },
    // { path: '/recruit/activity/:id', component: RecruitActivity },
    // { path: '/red-game/lists', component: RedGame },
    // { path: '/red-game/add/:id?', component: RedGameAdd },
    // { path: '/red-game/activity/:id', component: RedGameActivity },

    { path: '/design/color', component: OverallColor },
    { path: '/system/trade', component: TradeSystem },
    { path: '/recommend/note', component: RecommedNote },
    { path: '/recommend/note-add/:id?', component: NoteAdd },
    { path: '/recommend/comment-detail/:id', component: CommentDetail },
    { path: '/user/sign-in', component: UserSignIn },
    { path: '/search/set', component: SearchSet },

    // { path: '/lucky/lists', component: LuckyLists },
    // { path: '/lucky/add/:id?', component: LuckyAdd },
    { path: '/data/points', component: DataPoints },
    // { path: '/lucky/Lottery-detail/:id', component: LotteryDetail },
    { path: '/operating/points-rules', component: PointsRules },
    { path: '/operating/points-rules-edit/:id', component: PointsRulesEdit },
    // { path: '/level/user-level', component: UserLevel },
    // { path: '/level/dev-task', component: DevTask },
    // { path: '/level/equity', component: Equity },
    // { path: '/level/equity-edit/:id?', component: EquityEdit },
    // { path: '/level/level-edit', component: levelEdit },
    // { path: '/level/vip-notice', component: vipNotice },

    { path: '/sign/lists', component: SignLists },
    { path: '/sign/setting', component: SignSetting },
    { path: '/sign/detail/:id?', component: SignDetail },
    
    
    { path: '/user/user-address', component: UserAddress },

    { path: '/user/user-identity', component: UserIdentity },
    { path: '/user/user-collection', component: UserCollection },
    
    { path: '/panic-buying/lists', component: PanicBuyingLists },
    { path: '/panic-buying/add/:id?', component: AddPanicBuying },
    { path: '/panic-buying/detail/:id?', component: PanicBuyingDetail },
    { path: '/panic-buying/setting', component: PanicBuyingSetting },


    { path: '/user/user_dispatch/:id?', component: UserDispatch },
    
    { path: '/order/panic-lists', component: PanicLists },
    { path: '/order/panic-detail/:id?', component: PanicDeOrdertail },

    { path: '/sign/setting-user', component: SignUserSetting },
    
    
    
    
    { path: '/error/404', component: ErrorPage },
]
