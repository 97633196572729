import React from 'react'
import { Form, Modal, Input ,Radio, message} from 'antd'
import { signatureSetStatus } from '../../../api/user';
import { adminDetail } from '@/api/admin'
const FormItem = Form.Item;

class ExamineModal extends React.Component {
    state = {
        formData:{},
        title: '审核签名',
        visible: false,
        modalLoading: false,
        callback: null,
        admininfo:{}

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible: true,
            callback: callback || null
        })
        this.getAdmin()
        if(this.props.data){
            let formData = this.props.data;
            this.setState({
                formData   
            })
        }
    }
    close = () => {
        this.setState({
            visible: false
        })
    }

    getAdmin = () =>{  
        let sendData = {}
        adminDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    console.log('000',res)
                    let admininfo = res.data.admin
                    this.setState({
                        admininfo
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleOk = () => {
        let callback = this.state.callback;
        let sendData = {};
        let formData = this.state.formData || {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData = {
                    id: formData.id,
                    status: values.status,
                    remark: values.remark
                }
                signatureSetStatus(sendData).then(res => {
                    message.success(res.msg)
                    if (res.code === 1) {
                       if(typeof callback == 'function'){
                         callback()
                       }
                       this.close()
                    } 
                })
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.state.formData || {}
        let admininfo = this.state.admininfo || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 17
            }
        }
        const { getFieldDecorator } = this.props.form
        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={500}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <FormItem label="审核通过" className={`mgb5`}>
                        {getFieldDecorator('status', {
                            initialValue: data.status || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否通过'
                                }
                            ]
                        })(
                            <Radio.Group >
                                <Radio value={1}>通过</Radio>
                                <Radio value={2}>拒绝</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label="审核意见" className={`mgb5`}>
                        {getFieldDecorator('remark', {
                            initialValue: data.remark || '',
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: '请输入审核意见'
                            //     }
                            // ]
                        })(
                            <Input.TextArea rows={4} />
                        )}
                    </FormItem>
                    <FormItem label="审核人">
                        {getFieldDecorator('check_name', {
                            initialValue: admininfo ? admininfo.name : '',
                        })(<Input disabled />)}
                    </FormItem>
                    <FormItem label="审核人手机号">
                        {getFieldDecorator('check_mobile', {
                            initialValue: admininfo ? admininfo.mobile : '',
                        })(<Input disabled />)}
                    </FormItem>
                </Form>
            </Modal>
        )
    }
}
export default Form.create({})(ExamineModal)
