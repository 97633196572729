import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon, Checkbox,Popover } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import { marketItemLists, qianggouItemLists } from '@/api/item'
import {  createLabelGroup } from '@/api/user'

const FormItem = Form.Item;

 class GoodsList extends Component {
    state={
        visible:false,
        modalLoading:false,
        selectedRowKeys:[],
        storeLists:[],
        disable_ids:[],
        popVisible:false,
        columns:[
            {
                title: '商品名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`lh25`}>{ rowData.name || `-` }</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '商品价格',
                dataIndex: 'last_num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>¥ {rowData.price}</p>
                    )
                }
            },
            {
                title: '库存',
                dataIndex: 'suit_store_name',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.unit || 0}</p>
                    )
                }
            },
        ],
        tableData:[],
        callback:null,
        total:0,
        per_page:20,
        goods_all:[],
        page_all:[],
        page:1,
        searchForm:{
            name:'',
            coupon_type:'',
        },
    }

    componentDidMount() {
        this.props.onRef(this)
    }


    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }

    fetchData = (row) => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.goods_type=3;
        this.setState({
            tableLoading:true
        })
        qianggouItemLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.goods_list.data,
                        total = res.data.goods_list.total,
                        per_page = res.data.goods_list.per_page,
                        current_page = res.data.goods_list.current_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                    this.appendGoods(current_page,lists);
                    //let arr = [];
                    // if(row){
                    //     row.forEach((item,index)=>{
                    //         arr.push(item.id)
                    //     })
                    //     this.setState({
                    //         selectedRowKeys:arr
                    //     })
                    // }
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }    


    appendGoods = (page, data) =>{
        let {page_all,goods_all}=this.state
        if(page_all.includes(page)){
            return
        }
        page_all.push(page)
        goods_all.push(...data)
        this.setState({
            page_all:[...page_all],
            goods_all:[...goods_all]
        })
    }

    show = (row,disable_ids,callback) => {
        this.setState({
            visible:true,
            disable_ids:disable_ids,
            callback : callback || null,
            //selectedRowKeys:row
        })
        
        if(!row){
            this.setState({
                selectedRowKeys:null
            });
        }else{
            this.setState({
                selectedRowKeys:row
            })
        }
        this.fetchData(row)
    }

    saveData=()=>{
        const { sendFn } = this.props;
        let resData = this.state.selectedRowKeys
        let disable_ids = this.state.disable_ids;
        let list=Array.from(new Set([...resData,...disable_ids]))
        // let data = this.state.tableData
        let data = this.state.goods_all;
        let arr = [];
        list.forEach((item,index)=>{
            const element =  data.find(items=> items.id === item);
            if(element){
                arr.push(element)
            }else{
                arr.push({
                    id:item
                })
            }
        })
        sendFn(arr)
        this.setState({
            visible:false
        })

    }

    handleDel = (index) =>{
        // let resData = this.state.tableData;
        // resData.label_value.splice(index,1);
        // this.setState({
        //     tableData:resData
        // })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    getInputValue = e => {
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_name = val;
        this.setState({
            tableData: datas
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
      
    }

    getLabelInput = (e,index) =>{
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_value[index].name = val;
        this.setState({
            tableData: datas
        })
    }

    handlePoints = () => {
        this.setState({
            popVisible: true
        })
    }

    handleSavePoints = () => {
        this.setState({
            popVisible: false
        })
    }

    closePop = () => {
        this.setState({
            popVisible: false
        })
    }

    

    onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        this.setState({
            selectedRowKeys:newSelectedRowKeys
        })
    };

    render() {
        const { getFieldDecorator } = this.props.form
        const tableData = this.state.tableData || []
        const selectedRowKeys = this.state.selectedRowKeys
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Modal
            title={"选择商品"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={1300}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <div>
            <div className={'content-head mgb15'}>
                    <Form className={'search-form flex aglin-center'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>商品名称</span>} className={'fl'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className='mgt15'>
                    <Table
                        rowSelection={rowSelection}
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={tableData}
                        rowKey={record => record.id}
                        style={{ width:'100%' }}
                        pagination={false}
                    ></Table>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        )
    }
}

export default Form.create({})(GoodsList)
