// 当前id 截止 1-13
const defaultData = {
    3:{
        bgImg:'https://img.cxkoo.com/chengxuan/1/2024-03-02/f9fb4e124f026e730a5f8719ead4b5ea.png',
        showMemberCard:1,
        showTrain:1,
        userCenter:[
            {
                id:'5-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/89aa9906b7df68add5c37635ae010964.png',
                name: '收款管理',
                link: 'pages/user/gathering',
                isShow:1
            },
            {
                id:'5-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/fbd1a350a83daefe07cbffb443d2b45e.png',
                name: '我的地址',
                link: 'pages/address/lists',
                isShow:1
            },
            {
                id:'5-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/6dbd3e5e1b65b44dafb055f7c06626a5.png',
                name: '积分商城',
                link: 'pages/pointMall/index',
                isShow:1
            },
            {
                id:'5-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/eec2691d6a65e0cf6827ccfcc8e382a3.png',
                name: '领券中心',
                link: '/pages/coupon/center',
                isShow:1
            },
            {
                id:'5-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/3aeac65b9df756f05a5655289485f2e6.png',
                name: '分销员',
                link: 'pages/fx/index',
                isShow:1
            },
            // {
            //     id:'5-6',
            //     icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/efee1ef8ed79c860994136298ec2729e.png',
            //     name: '购物车',
            //     link: 'pages/index/index',
            //     isShow:1
            // },
            {
                id:'5-7',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/68ef093d452862210196cf9774935e8d.png',
                name: '专属客服',
                link: '',
                isShow:1
            },
        ],
        myContent:[
            {
                id:'6-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-03-02/ec72156fc2ee83e20d7e93f9d1433517.png',
                name: '我的仓库',
                link: 'pages/panicBuying/store',
                isShow:1
            },
            {
                id:'6-6',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-09-02/6029d8777d1a7413e79e5927839cacb2.png',
                name: '我的商品',
                link: 'pages/panicBuying/listed',
                isShow:1
            },
            {
                id:'6-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-03-02/fc3cd8e18cd6d3bbbd1232fcfe4f398b.png',
                name: '我的卖单',
                link: 'pages/panicBuying/sell',
                isShow:1
            },
            {
                id:'6-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-03-02/b618d1532cf536868aa4a206040f49e3.png',
                name: '我的收益',
                link: 'pages/user/income',
                isShow:1
            },
            {
                id:'6-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-03-02/e3a0e3103a7ba91e860114182bf63784.png',
                name: '我的粉丝',
                link: 'pages/user/fans',
                isShow:1
            },
       
            {
                id:'6-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-03-02/0a1b696e92d8c750c36cb47d301c1310.png',
                name: '退出登录',
                link: '',
                isShow:1
            },
        ],
      
    }
}
export default defaultData
