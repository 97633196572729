import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Switch } from 'antd'
import '@/assets/css/item/item.scss';
const FormItem = Form.Item
const { TextArea } = Input

class SetItemDiscount extends React.Component{
    state = {
        title:'设置活动折扣',
        visible:false,
        modalLoading:false,
        callback:null,
        tableData:[],
        sku:{},
        skuCache:{},
        props:{}, //属性
        norms:[], //规格
        currentItem:'',
        patch_stock:'',
        patch_sales_price:'',
        patch_join:false

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        },() => {
            this.initSku()
        })
    }
    initSku = () => {
        let item = this.props.item || {};
        console.log('--------',item)
        if(item.sku_list && item.sku_list.length){
            let norms = item.norms,
                props = item.props,
                sku = this.state.sku,
                skuCache = this.state.skuCache;
            item.sku_props = {};
            item.sku_list.forEach((child,childKey) => {
                let props = child.props;
                child.props_arr = Object.values(props);
                child.sales_price= child.sales_price ?? child.price;
                console.log('000000',child.props_arr);
                item.sku_props[Object.values(props).join(';')] = child;
            })
            console.log('----init数据',item.sku_props)
            skuCache = sku = item.sku_props;
            this.setState({
                norms,
                props,
                skuCache,
                sku
            },() => {
                this.combinaSku()
            })
        }else{
            item.sales_price=item.sales_price??item.price
            item.stock=1;
        }
        this.setState({
            currentItem:this.props.item
        })
    }
    combinaSku = () => {
        //组合sku
        let keys=[],
            norms = this.state.norms || [],
            sku = this.state.sku || {}
        norms.forEach((item,index) => {
            if(item.props && item.props.length){
                keys.push(item.props);
            }
        })
        sku = {};
        this.setState({
            sku
        },() => {
            //向sku中插入数据，并更新skuCache缓存
            // eslint-disable-next-line default-case
            // eslint-disable-next-line default-case
            switch(keys.length){
                case 0:
                    break;
                case 1:
                    for(var i=0;i<keys[0].length;i++){
                        var arr=[keys[0][i]];
                        this.addSku(arr.join(";"));
                    }
                    break;
                case 2:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            var arr=[keys[0][i],keys[1][j]];
                            this.addSku(arr.join(";"));
                        }
                    }
                    break;
                case 3:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                var arr=[keys[0][i],keys[1][j],keys[2][k]];
                                this.addSku(arr.join(";"));
                            }
                        }
                    }
                    break;
                case 4:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n]];
                                    this.addSku(arr.join(";"));
                                }

                            }
                        }
                    }
                    break;
                case 5:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    for(var m=0;m<keys[4].length;m++){
                                        var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n],keys[4][m]];
                                        this.addSku(arr.join(";"));
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        })

    }
    addSku = (key,val) => {
        let skuCache = this.state.skuCache || {},
            sku = this.state.sku || {},
            itemInfo = this.props.item || {};
        //添加sku
        if(!skuCache[key]){
            let tmparr=key.split(";"),
                defaultSku = { market_price:'',price:'',basic_sales:'',cover_img:'',weight:'',goods_sku_code:'',store_price:[], props:tmparr };
            defaultSku.price = itemInfo.price || ``;
            defaultSku.market_price = itemInfo.market_price || ``;
            defaultSku.basic_sales = itemInfo.basic_sales || ``;
            defaultSku.weight = itemInfo.weight || ``;
            skuCache[key]= sku[key] = defaultSku;
        }else {
            sku[key] = skuCache[key];
        }

        let tableData = [];
        for(var i in sku){
            sku[i]['sku_key'] = i
            tableData.push(sku[i])
        }
        this.setState({
            sku,skuCache,tableData
        },() => {
        })
    }
    getInputValue = (e,rowData,index) => {
        let name = e.currentTarget.dataset.name || ``,
            value = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
     
        skuCache[sku_key][name] = sku[sku_key][name] = value;
        this.setState({
            sku,skuCache
        })
    }
    handleIsJoin = (e,rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key]['is_join'] = sku[sku_key]['is_join'] = e;
        this.setState({
            sku,skuCache
        })
    }
   
    
    handlePatchSet = (e,name) => {
        let val = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            keyName = `patch_${name}`,
            skuCache = this.state.skuCache;
        let floatValue = val ? parseFloat(val) : '';
        if(floatValue){
            for(var i in tableData){
                let sku_key = tableData[i]['sku_key'];
                skuCache[sku_key][name] = sku[sku_key][name] = floatValue;
            }
        }
        let datas = {};
        datas['sku'] = sku;
        datas['skuCache'] = skuCache;
        datas[keyName] = val;
        this.setState(datas)
    }
    handlePatchJoin = (e) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache;
        for(var i in tableData){
            let sku_key = tableData[i]['sku_key'];
            skuCache[sku_key]['is_join'] = sku[sku_key]['is_join'] = e;
        }

        this.setState({
            patch_join:e,
            sku,skuCache
        })
    }
 
    handleCommonChange = (e,name) => {
        let val = e.target.value,
            currentItem = this.state.currentItem || {};
       currentItem[name] = val;
        this.setState({
            currentItem
        })

    }
    toDo = () => {}
    close = () => {
        this.setState({
            visible:false,
            tableData:[],
            sku:{},
            skuCache:{},
            props:{}, //属性
            norms:[], //规格
            patch_stock:'',
            patch_join:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            callback = this.state.callback,
            currentItem = this.state.currentItem,
            sku = this.state.sku;
        this.props.form.validateFields((err,values) => {
            if(!err){
                res = values;
                res.item = currentItem
                if(typeof callback == 'function'){
                    callback(res)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let currentItem = this.state.currentItem || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 21
            }
        }
        const { getFieldDecorator } = this.props.form

        console.log('----',this.state.tableData);
        return(
            Object.keys(currentItem).length
                ?
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={1100}
                    centered={true}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                >
                    <Form className={`model-form`} { ...formItemLayout }>
                        <FormItem label={`商品名称`} required={true}>
                            <div>
                                <p>{ currentItem.name }</p>
                                {
                                    currentItem.props
                                        ?
                                        <table className="cxtables cxtables-sku">
                                            <thead>
                                            <tr>
                                                {
                                                    this.state.norms.map((item, index) => (
                                                        <td key={index}>{item.name}</td>
                                                    ))
                                                }
                                                <td>是否参与</td>
                                                <td>零售价</td>
                                                <td>活动库存</td>
                                                <td>活动价</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.tableData.map((item, index) => (
                                                    <tr key={index} className="cbMoney">
                                                        {
                                                            this.state.tableData[index].props_arr?
                                                                this.state.tableData[index].props_arr.map((k, v) => (
                                                                    <td key={v}>{this.state.props[k]}</td>
                                                                ))
                                                            : null
                                                        }
                                                        <td>
                                                            <Switch checked={!!item.is_join } onChange={ (e) => { this.handleIsJoin(e,item,index) } }></Switch>
                                                        </td>
                                                      
                                                        <td>
                                                            &yen;{item.price}
                                                        </td>
                                                        <td>
                                                            <Input
                                                                placeholder={'活动库存'}
                                                                style={{ width:'100px' }}
                                                                data-name={`stock`}
                                                                // value={item.stock}
                                                                value={1}
                                                                disabled
                                                                onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                placeholder={'活动价'}
                                                                style={{ width:'100px' }}
                                                                data-name={`sales_price`}
                                                                value={item.sales_price}
                                                                onChange={(e) => { this.getInputValue(e,item,index) }}
                                                                addonAfter="元"
                                                            />
                                                        </td>
                                                      
                                                    </tr>
                                                ))
                                            }
                                            <tr className="cbMoney">
                                                <td>
                                                    批量设置
                                                </td>
                                                <td>
                                                    <Switch checked={ !!this.state.patch_join } onChange={ (e) => { this.handlePatchJoin(e) } }></Switch>
                                                </td>
                                                <td colSpan={this.state.norms.length + 1}></td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动库存'}
                                                        style={{ width:'100px' }}
                                                        data-name={`stock`}
                                                        // value={ this.state.patch_stock }
                                                        value={1}
                                                        disabled
                                                        onChange={(e) => { this.handlePatchSet(e,'stock') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动价'}
                                                        style={{ width:'100px' }}
                                                        data-name={`sales_price`}
                                                        value={ this.state.patch_sales_price }
                                                        onChange={(e) => { this.handlePatchSet(e,'sales_price') }}
                                                        addonAfter="元"
                                                    />
                                                </td>
                                               
                                            </tr>
                                            </tbody>
                                        </table>
                                        :
                                        <table className="cxtables cxtables-sku">
                                            <thead>
                                            <tr>
                                                <td>零售价</td>
                                                <td>活动库存</td>
                                                <td>活动价</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={`cbMoney`}>
                                               
                                                <td>
                                                    &yen;{ currentItem.price }
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动库存'}
                                                        style={{ width:'100px' }}
                                                        // value={ currentItem.stock}
                                                        value={1}
                                                        disabled
                                                        onChange={ (e) => { this.handleCommonChange(e,'stock') } }
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动价'}
                                                        style={{ width:'100px' }}
                                                        value={ currentItem.sales_price}
                                                        onChange={ (e) => { this.handleCommonChange(e,'sales_price') } }
                                                        addonAfter="元"
                                                    />
                                                </td>
                                               
                                               
                                            </tr>
                                            </tbody>
                                        </table>
                                }

                            </div>
                        </FormItem>
                        
                        <FormItem label={`排序`}>
                            {getFieldDecorator('sort', {
                                initialValue:  currentItem.sort || 0,
                            })(<Input placeholder={''} style={{ width:'150px' }} />)}
                            <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                        </FormItem>
                        
                        <FormItem label={`初始销量`}>
                            {getFieldDecorator('basic_sales', {
                                initialValue: currentItem.basic_sales || 0 ,
                            })(<Input placeholder={''} style={{ width:'150px' }} />)}
                            <span className={`fi-help-text`}>客户看到的销量=初始活动销量+已售数量，会在活动开始后展示</span>
                        </FormItem>
                    </Form>
                </Modal>
                :
                null
        )
    }
}

export default Form.create({})(withRouter(SetItemDiscount))
