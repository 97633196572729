import React from 'react'
import { Form, Select, DatePicker, Input, Button, Table, Pagination, Tag } from 'antd'
import { withRouter } from 'react-router-dom'
import '@/assets/css/finance/index.scss'
import { financeDetail, financeExport } from '@/api/finance'
import { storeLists } from '@/api/store'
import moment from 'moment'
import message from '@/utils/message'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class Finance extends React.Component{
    state = {
        searchForm:{
            store_id:'',
            time_type:'',
            start_time:'',
            end_time:'',
            pay_type:'', //1-订单金额为零2微信js支付3支付宝4会员卡支付5线下现金6记账支付7.微信刷卡付
            order_sn:'',
            user_mobile:'',
            trade_type:'',//1-普通下单(购物车) 2-优惠券
            trade_status:'', //2-已完成 4-已退款
        },
        columns:[
            {
                title:'商户单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.order_sn || '--'}</div>
                    )
                }
            },
            {
                title:'订单类型',
                dataIndex:'trade_type_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.trade_type_name || '--'}</div>
                    )
                }
            },
            {
                title:'商品名称',
                dataIndex:'goods_info',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.goods_info || '--'}</div>
                    )
                }
            },
            {
                title:'客户',
                dataIndex:'user',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={`lh25`}>{ rowData.user ? rowData.user.name : '--'}</div>
                            <div className={`lh25`}>{ rowData.user ? rowData.user.mobile : '--'}</div>
                        </div>
                    )
                }
            },
            {
                title:'支付信息',
                dataIndex:'pay_info',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>实收金额：&yen;{rowData.pay_ment}</p>
                            <p className={`lh25`}>支付方式：{rowData.pay_type_name}</p>
                            {
                                rowData.trade_status == 4
                                    ?
                                    <p className={`lh25`}>退款时间：{ rowData.refund_time }</p>
                                    :
                                    <p className={`lh25`}>完成时间：{ rowData.finished_at }</p>
                            }
                        </div>
                    )
                }
            },
            // {
            //     title:'下单门店',
            //     dataIndex:'store',
            //     render:(text, rowData, index) => {
            //         return (
            //             <div className={`lh25`}>{rowData.store ? rowData.store.name : ''}</div>
            //         )
            //     }
            // },
            {
                title:'订单状态',
                dataIndex:'trade_status_name',
                render:(text, rowData, index) => {
                    return (
                        <Tag >{rowData.trade_status_name || '--'}</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <Button type={`link`} onClick={ () => { this.handleDetail(rowData) } }>查看详情</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        storeLists:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
        finance:{}
    }
    componentDidMount() {
        this.fetchData()
        // this.fetchStore()


    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        financeDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let money_data = res.data.money_data,
                        lists = res.data.trade_list.data,
                        total = res.data.trade_list.total,
                        per_page = res.data.trade_list.per_page;
                    this.setState({
                        finance:money_data,
                        tableData:lists,
                        total,per_page
                    })


                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })


    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {})
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.time_type == 1){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else if(values.time_type == 2){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else {
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                }
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                financeExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '财务对账.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })



            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleDetail = (row) => {
        if(row.trade_type === 18){
            this.props.history.push({
                pathname:`/order/panic-detail/${row.order_sn}`
            })
        }else{
            this.props.history.push({
                pathname:`/order/detail/${row.order_sn}`
            })
        }
        
    }

    render() {
        let finance = this.state.finance || {}
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={`content-head mgb15`}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            {/* <FormItem label={`下单门店`} className={`fl`}>
                                {
                                    getFieldDecorator('store_id',{
                                        initialValue:0
                                    })(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem> */}
                            <FormItem label={`选择时间`} className={`fl`}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:150}} placeholder={'请选择'} className={`mgr10`}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>月汇总</Select.Option>
                                            <Select.Option value={2}>日汇总</Select.Option>
                                            <Select.Option value={3}>自定义时间</Select.Option>
                                        </Select>
                                    )
                                }
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={`支付方式`} className={`fl`}>
                                {
                                    getFieldDecorator('pay_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>订单金额为0</Select.Option>
                                            <Select.Option value={2}>微信支付</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={`商户单号`} className={'fl mgl20'} >
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`客户电话`} className={'fl mgl20'} >
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={`订单类型`} className={`fl`}>
                                {
                                    getFieldDecorator('trade_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>普通下单</Select.Option>
                                            <Select.Option value={2}>优惠券订单</Select.Option>
                                            <Select.Option value={3}>自助买单</Select.Option>
                                            <Select.Option value={4}>扫码点餐</Select.Option>
                                            <Select.Option value={5}>包厢预定</Select.Option>
                                            <Select.Option value={6}>储值卡开卡</Select.Option>
                                            <Select.Option value={7}>储值卡充值</Select.Option>
                                            <Select.Option value={8}>限时抢购</Select.Option>
                                            <Select.Option value={9}>积分兑换</Select.Option>
                                            <Select.Option value={10}>场馆预定</Select.Option>
                                            <Select.Option value={11}>次卡开卡</Select.Option>
                                            <Select.Option value={12}>课程</Select.Option>
                                            <Select.Option value={14}>拼团</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={`订单状态`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('trade_status')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={2}>已完成</Select.Option>
                                            <Select.Option value={4}>已退款</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>


                        </div>
                    </Form>
                </div>
                <div className={`flex justify-start align-center`}>
                    <div className={`finance-data-cont`}>
                        <div className="data-finance-item">
                            <div className="txt1">
                                <div className="item-txt">实际收款（元）</div>
                            </div>
                            <div className="chart-num">{ finance.real_money || 0 }</div>
                            <div className="txt2">收款笔数：{ finance.real_trade_num || 0 }笔</div>
                        </div>
                        <div className="data-finance-item">
                            <div className="txt1">
                                <div className="item-txt">收款金额（元）</div>
                            </div>
                            <div className="chart-num">{ finance.receive_money || 0 }</div>
                            <div className="txt2">收款笔数：{ finance.receive_trade_num || 0 }笔</div>
                        </div>
                        <div className="data-finance-item">
                            <div className="txt1">
                                <div className="item-txt">退款金额（元）</div>
                            </div>
                            <div className="chart-num">{ finance.refund_money || 0 }</div>
                            <div className="txt2">退款笔数：{ finance.refund_trade_num || 0 }笔</div>
                        </div>
                    </div>

                </div>
                <div className={`content-body mgt15`}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(Finance))
