import $axios from '@/axios/axios.js'

export function getSatistics(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/statistics/dashboard`,
        method:'post',
        data
    })
}

//商品销售榜单
export function goodsRank(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/statistics/goodsRank`,
        method:'post',
        data
    })
}

//导出销售榜单
export function exportGoodsRank(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/statistics/exportGoodsRank`,
        method:'get',
        responseType:'blob',
        params
    })
}


//客户购买力榜单
export function userConsume(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/statistics/userConsume`,
        method:'post',
        data
    })
}


//导出购买力榜单
export function exportUserConsume(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/statistics/exportUserConsume`,
        method:'get',
        responseType:'blob',
        params
    })
}


