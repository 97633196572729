import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, DatePicker, Tag, Drawer, Divider, Modal} from 'antd'
import { withRouter,Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { identifyLists, identifyDel,identifyDetail } from '@/api/identify'
import message from '@/utils/message'
import IdentityDetails from './components/IdentityDetail'
import UserUpload from './components/UserUpload'
import CreateIdentity from './components/CreateIdentity'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker

class UserIdentity extends React.Component{
    state = {
        columns:[
            {
                title:'身份名称',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name?rowData.name:'-'}</p>
                    )
                }
            },
            // {
            //     title:'普通场抢单数量',
            //     dataIndex:'simple_num',
            //     width:140,
            //     render:(text, rowData, index) => {
            //         return (
            //             <p className={`lh25`}>{rowData.simple_num?rowData.simple_num:0}</p>
            //         )
            //     }
            // },
            {
                title:'会员场抢单数量',
                dataIndex:'member_num',
                width:140,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.member_num?rowData.member_num:0}</p>
                    )
                }
            },
            {
                title:'提前抢单时间(分钟)',
                dataIndex:'ahead_time',
                width:160,
                render:(text, rowData, index) => {
                    return (
                            <p className={`lh25`}>{rowData.ahead_time?rowData.ahead_time:0}</p>
                    )
                }
            },
            {
                title:'兑换积分',
                dataIndex:'value',
                width:160,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.value?rowData.value:0}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:200,
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"}  onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        formdata:[],
        tableLoading:false,
        drawerVisible:false,
        page:1,
        total:0,
        per_page:10,
        user_data:{},
        currentRow:'',
        tags_list:[]
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        identifyLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page,
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleUpload=()=>{
        this.userUpload.show(res=>{
            this.fetchData();
        })
    }

    handleEdit = async rowData => {
        await this.getLabelDetail(rowData.id)
        this.userCreate.show((rowData)=>{
            this.fetchData()
        })
    }

    getLabelDetail = async (id) =>{
        await identifyDetail({id}).then(res => {
            if (res.code === 1) {
                let resSult = res.data.detail;
                this.setState({
                    formdata:resSult
                })
            }   
        })
    }

    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
        this.fetchData();
    }

    handleDelete = rowData =>{
        Modal.confirm({
            title:`确定删除这个身份吗？`,
            okType:`danger`,
            onOk:() => {
                this.delLabel(rowData.id)
            }
        })
        
    }

    delLabel = async (id) =>{
        let sendData = {
            ids:[id]
        };
        await identifyDel(sendData).then(res => {
            if (res.code === 1) {
                message.info('删除成功')
                this.fetchData();
            }   
        })
    }

    handleAddUser= async ()=>{
        await this.setState({
            formdata:[]
        })
        this.userCreate.show(()=>{
            this.fetchData()
        })
    }

    handleUpload=()=>{
        this.userUpload.show(res => {
            this.fetchData();
        });
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        return(
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAddUser} icon={'plus'}>新增身份</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total}
                                            pageSize={this.state.per_page} onChange={this.handleCurrentChange}/>
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`身份详情`}
                            closable={true}
                            width={`88%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={this.handleDrawerClose}
                            headerStyle={{borderBottom: '0px'}}
                            bodyStyle={{padding: '0px'}}
                        >
                            <IdentityDetails
                                onRef={e => {
                                    this.UserDetail = e
                                }}
                                user_id={this.state.currentRow ? this.state.currentRow.id : ''}
                            ></IdentityDetails>
                        </Drawer>
                        :
                        null
                }

                <UserUpload onRef={e => {
                    this.userUpload = e
                }}/>

                <CreateIdentity onRef={e => {
                    this.userCreate = e
                }} formdata={ this.state.formdata } />
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserIdentity))
