import React from 'react'
import { Form, Button, Layout, Menu, Icon, Tooltip, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import { designAdd, designEdit, designDetail, tplLists, changeTpl } from '@/api/design'
import { adminDetail } from '@/api/admin'
import Loading from '@/components/Loading'
import SetPageTitle from './components/SetPageTitle'
import '@/assets/css/design/index.scss'
import ShortcutsPanel from "./components/ShortcutsPanel";
import { ICON_FONT_URL } from '@/constants/iconFont'
import { MODULE_DEFAULT_DATA } from './mockData/defaults'
import CX from '@/utils/CX'
import PropTypes from 'prop-types'
import moment from 'moment';
import Sortable from 'sortablejs';

import TplCtrlNav from './components/ctrl/tabbar-setting'
import TplCtrlType0 from './components/ctrl/page-setting'
import TplCtrlType1 from './components/ctrl/announce'
import TplCtrlType2 from './components/ctrl/img-ad'
import TplCtrlType3 from './components/ctrl/img-nav-class'
import TplCtrlType4 from './components/ctrl/split-line'
import TplCtrlType5 from './components/ctrl/swiper'
import TplCtrlType6 from './components/ctrl/txt-nav'
import TplCtrlType7 from './components/ctrl/img-hot'
import TplCtrlType8 from './components/ctrl/item-lists'
import TplCtrlType9 from './components/ctrl/item-search'
import TplCtrlType10 from './components/ctrl/scan-order'
import TplCtrlType11 from './components/ctrl/count-down'
import TplCtrlType12 from './components/ctrl/ktv-booking'
import TplCtrlType13 from './components/ctrl/time-discount'
import TplCtrlType14 from './components/ctrl/court'
import TplCtrlType15 from './components/ctrl/course-lists'
import TplCtrlType16 from './components/ctrl/teacher-lists'
import TplCtrlType17 from './components/ctrl/group-buying'
import TplCtrlType18 from './components/ctrl/note-list'
import TplCtrlType19 from './components/ctrl/web-view'
import TplCtrlType20 from './components/ctrl/coupon' 
import TplCtrlType21 from './components/ctrl/swiper-card' 
import TplCtrlType22 from './components/ctrl/swiper-3d' 
import TplCtrlType23 from './components/ctrl/group-solitaire' 
import TplCtrlType24 from './components/ctrl/point-exchange' 

import TplConType1 from './components/phone/announce'
import TplConType2 from './components/phone/img-ad'
import TplConType3 from './components/phone/img-nav-class'
import TplConType4 from './components/phone/split-line'
import TplConType5 from './components/phone/swiper'
import TplConType6 from './components/phone/txt-nav'
import TplConType7 from './components/phone/img-hot'
import TplConType8 from './components/phone/item-lists'
import TplConType9 from './components/phone/item-search'
import TplConType10 from './components/phone/scan-order'
import TplConType11 from './components/phone/count-down'
import TplConType12 from './components/phone/ktv-booking'
import TplConType13 from './components/phone/time-discount'
import TplConType14 from './components/phone/court'
import TplConType15 from './components/phone/course-lists'
import TplConType16 from './components/phone/teacher-lists'
import TplConType17 from './components/phone/group-buying'
import TplConType18 from './components/phone/note-list'
import TplConType19 from './components/phone/web-view'
import TplConType20 from './components/phone/coupon'
import TplConType21 from './components/phone/swiper-card'
import TplConType22 from './components/phone/swiper-3d'
import TplConType23 from './components/phone/group-solitaire'
import TplConType24 from './components/phone/point-exchange'

const { Header, Sider, Content } = Layout
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})


class Design extends React.Component {
    static childContextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }

    getChildContext = () => {
        return {
            updateDesign: (e) => {
                this.updateDesign(e)
            },
            deleteModule: (module) => {
                this.deleteModule(module)
            }
        }
    }

    state = {
        app_id: "",
        version_id:'',
        design: {
            page: {},
            CXModules: [],
            tabBar: {}
        },
        currentModule: null, //当前编辑的模块
        conTplNames: {
            /* 这个对象是加载动态标签用的 */
            1: TplConType1,
            2: TplConType2,
            3: TplConType3,
            4: TplConType4,
            5: TplConType5,
            6: TplConType6,
            7: TplConType7,
            8: TplConType8,
            9: TplConType9,
            10: TplConType10,
            11: TplConType11,
            12: TplConType12,
            13: TplConType13,
            14: TplConType14,
            15: TplConType15,
            16: TplConType16,
            17: TplConType17,
            18: TplConType18,
            19: TplConType19,
            20: TplConType20,
            21: TplConType21,
            22: TplConType22,
            23: TplConType23,
            24: TplConType24
        },
        ctrlTplNames: {
            /* 这个对象是加载动态标签用的 */
            1: TplCtrlType1,
            2: TplCtrlType2,
            3: TplCtrlType3,
            4: TplCtrlType4,
            5: TplCtrlType5,
            6: TplCtrlType6,
            7: TplCtrlType7,
            8: TplCtrlType8,
            9: TplCtrlType9,
            10: TplCtrlType10,
            11: TplCtrlType11,
            12: TplCtrlType12,
            13: TplCtrlType13,
            14: TplCtrlType14,
            15: TplCtrlType15,
            16: TplCtrlType16,
            17: TplCtrlType17,
            18: TplCtrlType18,
            19: TplCtrlType19,
            20: TplCtrlType20,
            21: TplCtrlType21,
            22: TplCtrlType22,
            23: TplCtrlType23,
            24: TplCtrlType24
        },
        isLoading: false,
        showModal: false,
        modalLoading: false,
        pageTitle: '',
        tplId: 1, //当前模板id
        designType: 1, //1店铺主页 2自定义页面
        new_id:'', //新建模板后返回id
    }
    componentDidMount() {
        this.init()
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
        this.initSort();
        this.fetchAdmin()
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    handleWindowBeforeUnload = (e) => {
        let confirmationMessage = '系统可能不会保存您所做的更改';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
    init = () => {
        let sendData = {}
        if (this.props.match.params.id) {
            //编辑模式
            sendData.id = this.props.match.params.id
            this.setState({
                isLoading: true
            })
            designDetail(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let detail = res.data.detail,
                            tplId = detail.tpl_id,
                            page_title = detail.page_title,
                            design = {};
                        design.page = JSON.parse(detail.content).page
                        design.CXModules = JSON.parse(detail.content).CXModules
                        design.tabBar = detail.navigation ? JSON.parse(detail.navigation) : {}

                        if (!('showCoreIcon' in design.tabBar)) {
                            design.tabBar.showCoreIcon = 0;
                        }
                        if (!('coreIcon' in design.tabBar)) {
                            design.tabBar.coreIcon = '';
                        }
                        if (!('corePagePath' in design.tabBar)) {
                            design.tabBar.corePagePath = '';
                        }

                        this.setState({
                            currentModule: design.page,
                            pageTitle: page_title,
                            designType: this.props.match.params.type || 1,
                            design,
                            tplId
                        }, () => { })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })


        } else {
            //创建模式
            sendData.id = 1; //空白模板
            this.setState({
                isLoading: true
            })
            changeTpl(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let detail = res.data.template_detail,
                            tplId = detail.id,
                            design = {};
                        design.page = JSON.parse(detail.content).page
                        design.CXModules = JSON.parse(detail.content).CXModules
                        design.tabBar = detail.navigation ? JSON.parse(detail.navigation) : {}

                        design.tabBar.showCoreIcon = 0;
                        design.tabBar.coreIcon = '';
                        design.tabBar.corePagePath = '';

                        this.setState({
                            currentModule: design.page,
                            designType: this.props.match.params.type || 1,
                            design,
                            tplId
                        })

                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        }

    }
    initSort = () => {
        let sortable = new Sortable(this.refs.phoneChild, {
            group: 'shared', // set both lists to same group
            animation: 150,
            onEnd: (event) => {
                this.handleSortEnd(event)
            }
        })
    }
    fetchAdmin = () => {
        let sendData = {};
        adminDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let shop = res.data.shop,
                        program = shop.program || {},
                        app_id = program.app_id || ''

                    this.setState({
                        app_id,
                        version_id: shop.version_id
                    })

                }
            })
    }
    handleSortEnd = e => {
        let design = this.state.design,
            CXModules = design.CXModules,
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = CXModules.splice(oldIndex, 1)[0];
        CXModules.splice(newIndex, 0, targetRow);
        this.setState({
            design
        })
    }
    handleAddModule = module => {
        module.type = Number(module.type)
        let design = this.state.design,
            CXModule = {};
        switch (module.type) {
            case 0:
                //页面设置
                CXModule = design.page;
                break;
            default:
                //添加模块
                let CXModules = design.CXModules;
                CXModule.id = CX.genUUID();
                for (var i in MODULE_DEFAULT_DATA[module.type]) {
                    CXModule[i] = MODULE_DEFAULT_DATA[module.type][i]
                }
                CXModules.push(CXModule)
                break;
        }
        this.setState({
            currentModule: CXModule,
            design
        }, () => {
            setTimeout(() => {
                let height = this.refs.phoneChild.getBoundingClientRect().height;
                this.refs.phone.scrollTop = height
            }, 100)

        })
    }
    renderMenuContent = () => {
        return (
            <div>
                <ShortcutsPanel
                    onRef={e => { this.ShortcutsPanel = e }}
                    handleCurrentChange={e => { this.handleAddModule(e) }}
                    version_id={this.state.version_id}
                ></ShortcutsPanel>
            </div>

        )
    }
    renderCtrl = currentModule => {
        if (currentModule) {
            if (currentModule.type) {
                let TplName = this.state.ctrlTplNames[currentModule.type];
                return (
                    <TplName onRef={e => { this[`TplCtrlType${currentModule.type}`] = e }} module={currentModule} />
                )
            } else {
                switch (currentModule.dataType) {
                    case 'page':
                        return (
                            <TplCtrlType0 onRef={e => { this.TplCtrlType0 = e }} module={currentModule} />
                        )
                    case 'tabBar':
                        return (
                            <TplCtrlNav onRef={e => { this.TplCtrlNav = e }} module={currentModule} />
                        )
                }

            }

        } else {
            return null
        }
    }
    updateDesign = newData => {
        let design = this.state.design,
            currentModule = this.state.currentModule,
            CXModules = design.CXModules;
        if (!newData['id']) {
            //导航设置或页面设置
            currentModule = newData;
            switch (newData.dataType) {
                case 'page':
                    design.page = newData
                    break;
                case 'tabBar':
                    design.tabBar = newData
                    break;
            }
        } else {
            //其他模块
            for (var i in CXModules) {
                CXModules[i]['is_choose'] = 0
                if (CXModules[i]['id'] == newData['id']) {
                    CXModules[i] = newData
                    currentModule = newData
                    CXModules[i]['is_choose'] = 1

                }
            }
        }
        this.setState({
            design, currentModule
        })
    }
    deleteModule = module => {
        let design = this.state.design,
            currentModule = this.state.currentModule,
            CXModules = design.CXModules;
        for (var i in CXModules) {
            if (CXModules[i]['id'] == module['id']) {
                CXModules.splice(i, 1);
                currentModule = design.page;
            }
        }
        this.setState({
            design, currentModule
        })
    }
    showNavSetting = () => {
        let tabBar = this.state.design.tabBar || '';
        if (tabBar) {
            this.setState({
                currentModule: tabBar
            })
        }
    }
    handleEmpty = () => {
        let design = this.state.design,
            currentModule = this.state.currentModule;
        Modal.confirm({
            title: `是否清空当前装修的内容？`,
            onOk: () => {
                design.CXModules = [];
                currentModule = design.page;
                this.setState({
                    design, currentModule
                })
                message.success('操作成功')
            }
        })
    }
    handleCancel = () => {
        this.setState({
            showModal: false
        })
    }
    handleOk = () => {
        let sendData = {},
            design = this.state.design;
        sendData.type = this.state.designType;
        sendData.tpl_id = this.state.tplId;
        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                sendData.page_title = values.title;
                if (sendData.type == 1) {
                    sendData.navigation = Object.keys(design.tabBar).length ? JSON.stringify(design.tabBar) : '';
                } else {
                    sendData.navigation = ''
                }
                let content = {}
                content.page = design.page;
                content.CXModules = design.CXModules;
                sendData.content = JSON.stringify(content);
                this.setState({
                    isLoading: true,
                    showModal: false
                })
            
                if (this.props.match.params.id || this.state.new_id) {
                    sendData.id = this.props.match.params.id ||  this.state.new_id;
                    designEdit(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success(`操作成功`)
                                    .then(() => {
                                        // window.location.reload();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                } else {
                    designAdd(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success(`操作成功`)
                                    .then(() => {
                                        // window.location.reload();
                                        if(res.code==1){
                                            let detail =res.data.detail;

                                            let new_id=detail && detail.id ||''
                                            this.setState({
                                                new_id
                                            })
                                        }
                                        
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                }


            }
        });

    }
    handleAddDesign = () => {
        this.setState({
            pageTitle: this.state.pageTitle || `自定义页面-${moment().format('YYYY-MM-DD-HH:mm:ss')}`,
            showModal: true
        })
    }
    handlePreview = () => {
        // message.info('敬请期待')
        // let img_url = process.env.REACT_APP_BASE_API + '/mini-qrcode?app_id=' + this.state.app_id
        let page_url = process.env.REACT_APP_BASE_API +'/#/pages/index/custom?id='+ (this.props.match.params.id || this.state.new_id);
        let img_url = process.env.REACT_APP_BASE_API + '/img/qrcode?code=' + encodeURIComponent(page_url);
        console.log(img_url)
        Modal.info({
            title: '请保存后，扫码预览效果',
            className: "coustom-modal",
            content: (
                <div>
                    <img src={img_url} alt="" width={300} height={300} />
                </div>
            ),
            onOk() { },
        });
    }
    handleTpl = () => {
        message.info('敬请期待')
    }
    back = () => {
        Modal.confirm({
            title: `确定返回主页吗，未保存的数据将会丢失`,
            okType: `danger`,
            onOk: () => {
                this.props.history.push({
                    pathname: `/design/lists`
                })
            }
        })
    }

    renderTabBar = (tabBar) => {
        if (!tabBar.list) {
            return null
        }

        let lists = tabBar.list && tabBar.list.map((item, index) => (
            <div className={`navlist`} key={index}>
                <img src={item.iconPath} alt="" />
                <p style={{ color: `${tabBar.color}` }}>{item.text}</p>
            </div>
        ))

        let item=(
            <div className='nav-core' key={'yyy'} >
                <img src={tabBar.coreIcon}></img>
            </div>
        )

        let arrLength=tabBar.list.length

        if([2,4].includes(arrLength) && tabBar.showCoreIcon ){
            lists.splice(arrLength/2,0,item)
        }

        return lists

    }

    render() {
        let CXModules = this.state.design.CXModules || [],
            page = this.state.design.page,
            tabBar = this.state.design.tabBar || {},
            currentModule = this.state.currentModule || null;
        return (
            <Layout style={{ height: '100vh' }} className={`editor-layout`}>
                <Header className={`header`}>
                    <div className={`editor-logo`} onClick={this.back}>
                        返回主页
                    </div>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px', float: 'right', background: 'transparent' }}
                    >
                        <Menu.Item key={'1'} className={`transparent-bg pdl10 pdr10`} >
                            <Button type={`primary`} size={`small`} onClick={this.handlePreview}>预览</Button>
                        </Menu.Item>
                        <Menu.Item key={'2'} className={`transparent-bg pdl10 pdr10`}>
                            <Button size={`small`} onClick={this.handleAddDesign}>保存</Button>
                        </Menu.Item>
                        {/*<Menu.Item key={'3'} className={`transparent-bg pdl10 pdr10`}>*/}
                        {/*<Button size={`small`}>设置为模板</Button>*/}
                        {/*</Menu.Item>*/}
                    </Menu>
                </Header>
                <Layout>
                    <Sider theme={'light'} width={240} style={{ background: '#fff', padding: '12px' }}>
                        {this.renderMenuContent()}
                    </Sider>
                    <Layout className={`canvas-outer-wrapper`}>
                        <Content style={{ transform: 'scale(1)', transformOrigin: 'center top' }}>
                            <div className={`diy-phone`}>
                                <div className={`phone-contain`}>
                                    <div className={`phone-title`} style={{ backgroundColor: `${page.NavigationBackgroundColor}` }}>
                                        <p className={`phone-title-txt`} style={{ color: `${page.titleColorStyle == 0 ? '#111111' : '#FFFFFF'}` }} >{page.title}</p>
                                    </div>
                                    <div className={`phone-inner ${this.state.designType != 1 ? `withoutNav` : ''} `} ref={`phone`} style={{ backgroundColor: `${page.backgroundColor}` }}>
                                        <div className={`phone-inner-cont`} ref={`phoneChild`}>
                                            {
                                                CXModules.map((item, index) => {
                                                    let TplName = this.state.conTplNames[item.type];
                                                    return (
                                                        <TplName onRef={e => { this[`TplConType${item.id}`] = e }} key={index} module={item} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        Object.keys(tabBar).length && this.state.designType == 1
                                            ?
                                            <div className={`phone-nav ${[2, 4].includes(tabBar.list && tabBar.list.length) && tabBar.showCoreIcon ? 'show-core' : ''}`} style={{ backgroundColor: `${tabBar.backgroundColor}` }} onClick={this.showNavSetting}>
                                                {
                                                    // tabBar.list && tabBar.list.map((item, index) => (
                                                    //     <div className={`navlist`} key={index}>
                                                    //         <img src={item.iconPath} alt="" />
                                                    //         <p style={{ color: `${tabBar.color}` }}>{item.text}</p>
                                                    //     </div>
                                                    // ))
                                                    this.renderTabBar(tabBar)
                                                }
                                            </div>
                                            
                                            :
                                            null
                                    }

                                </div>
                            </div>
                        </Content>
                    </Layout>
                    <Sider theme={`light`} width={40} style={{ background: '#fff', border: '1px solid #eee' }}>
                        <Button.Group style={{ display: 'flex', flexDirection: 'column' }}>
                            <Tooltip effect="dark" placement="left" title={`选择模板`}>
                                <Button
                                    block
                                    className={`transparent-bg`}
                                    type={`link`}
                                    size={`small`}
                                    style={{ height: '40px', color: '#000' }}
                                    onClick={this.handleTpl}
                                >
                                    <EditorIcon type={`icon-moban`} className={`shortcut-icon fz20`} />
                                </Button>
                            </Tooltip>
                            <Tooltip effect="dark" placement="left" title={`清空装修内容`}>
                                <Button
                                    block
                                    className={`transparent-bg`}
                                    type={`link`}
                                    size={`small`}
                                    style={{ height: '40px', color: '#000' }}
                                    onClick={this.handleEmpty}
                                >
                                    <EditorIcon type={`icon-qingkong`} className={`shortcut-icon fz20`} />
                                </Button>
                            </Tooltip>
                            {
                                this.state.designType == 1
                                    ?
                                    <Tooltip effect="dark" placement="left" title={`设置底部导航`}>
                                        <Button
                                            block
                                            className={`transparent-bg`}
                                            type={`link`}
                                            size={`small`}
                                            style={{ height: '40px', color: '#000' }}
                                            onClick={this.showNavSetting}
                                        >
                                            <EditorIcon type={`icon-dibudaohang-fuwu1`} className={`shortcut-icon fz20`} />
                                        </Button>
                                    </Tooltip>
                                    :
                                    null
                            }

                        </Button.Group>
                    </Sider>
                    <Sider theme='light' className={`cx-ctrl`} width={320}>
                        <div>
                            {
                                this.renderCtrl(currentModule)
                            }
                        </div>

                    </Sider>

                </Layout>
                {this.state.isLoading ? <Loading /> : null}
                {
                    this.state.showModal
                        ?
                        <Modal
                            title={`设置装修页名称`}
                            visible={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            maskClosable={false}
                        >
                            <SetPageTitle
                                title={this.state.pageTitle}
                                visible={this.state.showModal}
                                wrappedComponentRef={form => { this.formRef = form }}
                                confirmLoading={this.state.modalLoading}
                            ></SetPageTitle>
                        </Modal>
                        :
                        null
                }
            </Layout>
        )
    }

}

export default Form.create({})(withRouter(Design))
