import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import { storeLists } from '@/api/store'
import {  identifySave } from '@/api/identify'

const FormItem = Form.Item;

 class CreateIdentity extends Component {
    state={
        visible:false,
        modalLoading:false,
        storeLists:[],
        callback:null,
        tableData:[]
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        console.log('0000'  ,this.props.formdata)
        if(this.props.formdata){
            let data = this.props.formdata;
            this.setState({
                tableData:data   
            })
        }
        console.log(this.state.tableData)
    }

    saveData=()=>{
        let  sendData = {},callback=this.state.callback
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
                if(this.props.formdata.id){
                    sendData.id = this.props.formdata.id
                }
                this.setState({
                    modalLoading: true
                })
                identifySave(sendData).then(res => {
                    message.success(res.msg)
                    if (res.code === 1) {
                       if(typeof callback == 'function'){
                         callback()
                       }
                    } 
                    
                    this.setState({
                        modalLoading: false,
                        visible:false
                    })
                })

            }
        })

    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        const tableData = this.state.tableData || []
        return (
            <Modal
            title={"添加身份"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={700}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form {...formItemLayout} className={'model-form'}>
                
                <Row gutter={24}>
                    <Col span={23}>
                        <FormItem label="身份名称">
                            {getFieldDecorator('name', {
                                initialValue: tableData.name || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入身份名称    '
                                    }
                                ]
                            })(<Input maxLength={15} />)}
                        </FormItem>

                        {/* <FormItem label="备注名">
                            {getFieldDecorator('second_name', {
                                initialValue: undefined,
                            })(<Input maxLength={15} />)}
                        </FormItem> */}
                        <div className={`data-title mgb10`}>
                            <span className={`fi-title`}>身份设置</span>
                            <span className={`fi-sub-title mgl15`}></span>
                        </div>
                        {/* <FormItem label={'普通场抢单数量'}>
                            <div>
                                {getFieldDecorator('simple_num', {
                                    initialValue: tableData.simple_num || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入抢单数量'
                                        }
                                    ]
                                })(<Input placeholder={'请输入抢单数量'} suffix="单" />)}
                            </div>
                            <span className={`fi-help-text`}>默认为1单</span>
                        </FormItem> */}
                        <FormItem label={'会员场抢单数量'}>
                            <div>
                                {getFieldDecorator('member_num', {
                                    initialValue: tableData.member_num || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入抢单数量'
                                        }
                                    ]
                                })(<Input placeholder={'请输入抢单数量'} suffix="单" />)}
                            </div>
                            <span className={`fi-help-text`}>默认为1单</span>
                        </FormItem>
                        <FormItem label={'提前抢单时间'}>
                            <div>
                                {getFieldDecorator('ahead_time', {
                                    initialValue: tableData.ahead_time || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入提前抢单时间'
                                        }
                                    ]
                                })(<Input placeholder={'请输入抢单时间'} suffix="分钟" />)}
                            </div>
                            <span className={`fi-help-text`}>默认为0分钟</span>
                        </FormItem>

                        <div className={`data-title mgb10`}>
                            <span className={`fi-title`}>身份兑换</span>
                            <span className={`fi-sub-title mgl15`}></span>
                        </div>
                        
                        <FormItem label={'兑换积分'}>
                            <div>
                                {getFieldDecorator('value', {
                                    initialValue: tableData.value || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入兑换积分'
                                        }
                                    ]
                                })(<Input placeholder={'请输入兑换积分'} suffix="积分" />)}
                            </div>
                            <span className={`fi-help-text`}>兑换一天该等级所需的积分</span>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                    </Col>
                </Row>
            </Form>

        </Modal>
        )
    }
}

export default Form.create({})(CreateIdentity)
