/**
 * 底部导航设置
 * @type NaN
 * @return Object
 */

import React from 'react'
import { Form, Input, Button, Modal, Icon, Select, Radio } from 'antd'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import NavIconPicker from '@/components/NavIconPicker/NavIconPicker'
import PropTypes from "prop-types";

const FormItem = Form.Item
const ctrlItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }
const NavConstantLists = [
    {
        pagePath: '/pages/index/index',
        name: '店铺首页',
    },
    {
        pagePath: '/pages/item/lists',
        name: '商品列表',
    },
    {
        pagePath: '/pages/mine/mine',
        name: '个人中心',
    },
    {
        pagePath: 'pages/mine/coupon',
        name: '我的优惠券',
    }

]


class tpl_ctrl_nav extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleBgChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.backgroundColor = hex;
        this.context.updateDesign(moduleData);
    }
    handleTxtChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.color = hex;
        this.context.updateDesign(moduleData);
    }
    handleTxtSelectChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.selectedColor = hex;
        this.context.updateDesign(moduleData);
    }
    chooseImg = (e, item, index) => {
        let moduleData = this.state.moduleData,
            name = e.currentTarget.dataset.name || ``;
        this.NavIconPicker.show(res => {
            if (res) {
                moduleData['list'][index][name] = res.icon
                this.context.updateDesign(moduleData)
            }
        })
    }
    chooseCoreImg=()=>{
        let moduleData = this.state.moduleData;
        this.NavIconPicker.show(res => {
            if (res) {
                moduleData['coreIcon'] = res.icon
                this.context.updateDesign(moduleData)
            }
        })
    }
    delItem = (item, index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定要删除这一项吗`,
            okType: `danger`,
            onOk: () => {
                moduleData['list'].splice(index, 1);
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleAddNav = () => {
        let moduleData = this.state.moduleData,
            newData = {
                iconPath: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/318ccd722007e2c8f1840190bfc98bef.png',
                selectedIconPath: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/55389c7dd443991b1268e79e5b5420ff.png',
                pagePath: '/pages/index/index',
                text: '店铺'
            };
        moduleData.list.push(newData);
        this.context.updateDesign(moduleData)

    }
    changeLink = (e, item, index) => {
        let moduleData = this.state.moduleData;
        console.log(e)
        console.log(item, index)
        moduleData.list[index]['pagePath'] = e
        this.context.updateDesign(moduleData)
    }
    changeCoreLink = () => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                moduleData['corePagePath']=res['link']
                this.context.updateDesign(moduleData)
            }
        })
    }
    changeText = (e, item, index) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.list[index]['text'] = value;
        this.context.updateDesign(moduleData)
    }

    handleRedioChange = (e,name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData[name] = value;
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return (
            Object.keys(moduleData).length
                ?
                <div>
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <PopSelectLink onRef={e => { this.PopSelectLink = e }}></PopSelectLink>
                    <NavIconPicker onRef={e => { this.NavIconPicker = e }}></NavIconPicker>
                    <div className={`ctrl-right-title`}>底部导航</div>
                    <Form className={`cx-ctrl-panel`} >
                        <div className={`cx-ctrl-panel--box`}>
                            <FormItem label={`导航背景颜色`} {...ctrlItemLayout}>
                                <ColorPicker onRef={e => { this.ColorPicker = e }} color={moduleData.backgroundColor} callback={this.handleBgChange} ></ColorPicker>
                            </FormItem>
                            <FormItem label={`导航文字颜色`} {...ctrlItemLayout}>
                                <ColorPicker onRef={e => { this.ColorPicker1 = e }} color={moduleData.color} callback={this.handleTxtChange} ></ColorPicker>
                            </FormItem>
                            <FormItem label={`导航选中颜色`} {...ctrlItemLayout}>
                                <ColorPicker onRef={e => { this.ColorPicker2 = e }} color={moduleData.selectedColor} callback={this.handleTxtSelectChange} ></ColorPicker>
                            </FormItem>
                            <p className='mgt10 mgb10'>注：开启中心图标后，当导航数为2，4的时候生效</p>
                            <div className="cx-img-list">
                                <div className={`cx-img-item clearfix`}>
                                    <FormItem label={`中心图标`} {...ctrlItemLayout} >
                                        <Radio.Group value={moduleData.showCoreIcon} onChange={(e) => { this.handleRedioChange(e,'showCoreIcon') }} >
                                            <Radio value={0}>隐藏</Radio>
                                            <Radio value={1}>显示</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                    <FormItem label={`选择图标`} {...ctrlItemLayout}>
                                        <div className={'clearfix'}>
                                            <div className={'img-add'} onClick={() => { this.chooseCoreImg() }} >
                                                {
                                                    moduleData.coreIcon
                                                        ?
                                                        <div>
                                                            <img src={moduleData.coreIcon} alt="" />
                                                        </div>
                                                        :
                                                        '+'
                                                }
                                            </div>
                                        </div>
                                    </FormItem>
                                    <FormItem label={`链接地址`} {...ctrlItemLayout}>
                                        <Input type={`text`} value={moduleData.corePagePath} onClick={this.changeCoreLink}  placeholder={`请选择`} />
                                    </FormItem>
                                </div>
                            </div>

                            {
                                moduleData.list.map((item, index) => (
                                    <div className={`cx-img-list`} key={index}>
                                        <div className={`cx-img-item clearfix`}>
                                            <FormItem label={`导航名称`} {...ctrlItemLayout}>
                                                <Input type={`text`} value={item.text} onChange={(e) => { this.changeText(e, item, index) }} placeholder={`设置导航名称`} maxLength={5} />
                                            </FormItem>
                                            <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                <Select
                                                    placeholder={'请选择'}
                                                    value={item.pagePath}
                                                    data-name={`pagePath`}
                                                    onChange={(e) => { this.changeLink(e, item, index) }}
                                                >
                                                    {
                                                        NavConstantLists.map((item, index) => (
                                                            <Select.Option value={item.pagePath} key={index}>{item.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormItem>
                                            <FormItem label={`默认图标`} {...ctrlItemLayout}>
                                                <div className={'clearfix'}>
                                                    <div className={'img-add x-m'} onClick={(e) => { this.chooseImg(e, item, index) }} data-name="iconPath">
                                                        <div>
                                                            <img src={item.iconPath} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormItem>
                                            <FormItem label={`选中图标`} {...ctrlItemLayout}>
                                                <div className={'clearfix'}>
                                                    <div className={'img-add x-m'} onClick={(e) => { this.chooseImg(e, item, index) }} data-name="selectedIconPath">
                                                        <div>
                                                            <img src={item.selectedIconPath} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormItem>
                                        </div>
                                        {
                                            index > 1
                                                ?
                                                <Icon type="close-circle" theme="filled" className={`delete-icon`} onClick={() => { this.delItem(item, index) }} />
                                                :
                                                null
                                        }
                                    </div>
                                ))
                            }

                            <Button type={`primary`} className={`w100`} onClick={this.handleAddNav} disabled={moduleData.list.length >= 5} >添加导航</Button>
                        </div>

                    </Form>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_nav)
