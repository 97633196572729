import React from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Icon, Tooltip, Modal,Table,Divider } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { qiangGouTradeList, cancelTrade, confirmPay, confirmShelve, addRemark, maxRefundMoney, orderPartRefund, orderExport, pickUpCode } from '@/api/order'
import { storeLists } from '@/api/store'
import { TRADE_TYPES } from '@/constants/trade'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import Remark from './components/remark'
import ItemRefund from './components/item-refund'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

// 订单类型 1普通订单 2优惠券订单 3自助买单订单 4扫码下单订单 5KTV订单  8限时打折 9 积分兑换
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
//goods_lists type:1-商品 2-套餐 3-优惠券 4-课程
class PanicLists extends React.Component{
    state = {
        activeStatus:'0',
        searchForm:{
            order_sn:'',
            goods_name:'',
            user_name:'',
            user_mobile:'',
            saler_user_name:'',
            saler_user_mobile:'',
            time_type:0,
            trade_info:{},
            // status:0,
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.goods_lists?rowData.goods_lists[0].cover_img:null} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`lh25`}>{rowData.goods_lists? rowData.goods_lists[0].name : `-` }</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'订单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.order_sn || '-'}</p>
                    )
                }
            },
            {
                title:'单价/数量',
                dataIndex:'goods_num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>单价：{rowData.goods_lists? rowData.goods_lists[0].price : `-` }¥</p>
                            <p>数量：{rowData.goods_lists? rowData.goods_lists[0].num : `-` }</p>
                        </div> 
                    )
                }
            },
            {
                title:'卖家',
                dataIndex:'start_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.saler_info? rowData.saler_info.name : ''}</p>
                    )
                }
            },
            {
                title:'实付金额',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.pay_ment || '-'}</p>
                    )
                }
            },
            {
                title:'买家',
                dataIndex:'start_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.user_info? rowData.user_info.name : ''}</p>
                    )
                }
            },
            {
                title:'支付方式',
                dataIndex:'pay_type_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.pay_type_name || ' '}</p>
                        </div>
                    )
                }
            },
            {
                title:'订单状态',
                dataIndex:'trade_status_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={'mgl10'}>{rowData.trade_status_name}</div>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDetail(rowData)}}>订单详情</Button>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            {/* <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                            <Divider type={`vertical`}></Divider> */}
                            {
                                rowData.trade_status == 0
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData,0)}}>取消订单</Button>
                                    :
                                    null
                            }
                            {
                                rowData.trade_status == 13
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData,13)}}>确认支付</Button>
                                    :
                                    null
                            }
                            {
                                rowData.trade_status == 14
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData,14)}}>确认上架</Button>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
        ],
        tableLoading:false,
        tableData:[],
        storeLists:[],
        isLoading:false,
        noData:false,
        page:1,
        total:0,
        per_page:10,
        lastRefundMoney:0,
        payMent:0,
        refundMoney:0
    }

    componentDidMount() {
        if(this.props.match.params.status){
            let status = this.props.match.params.status
            this.setState({
                activeStatus:String(status)
            },() => {
                this.fetchData()
            })
        }else {
            this.fetchData()
        }
        this.fetchStore();
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            isLoading:true
        })
        qiangGouTradeList(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page,
                        trade_info = res.data.trade_info;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page,
                        trade_info
                    })
                    if(lists.length < 1){
                        this.setState({
                            noData:true
                        })
                    }else {
                        this.setState({
                            noData:false
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {})
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                sendData.trade_type = 18;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                orderExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '订单导出.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })

            }
        })
    }
    handleDeliver = () => {}
    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }

    handleDetail = (rowData) => {
        this.props.history.push({
            pathname:`/order/panic-detail/${rowData.order_sn || ''}`
        })

    }
    handleStatus = (rowData,type) =>{
        let sendData = {}
        switch (type) {
            case 0:
                sendData.order_sn = rowData.order_sn;
                Modal.confirm({
                    title:`是否取消该单订？`,
                    onOk:() => {
                        this.setState({
                            isLoading:true
                        })
                        cancelTrade(sendData)
                            .then(res => {
                                if(res.code == 1){
                                    message.success('操作成功')
                                        .then(() => {
                                            this.fetchData()
                                        })
                                }

                            })
                            .finally(() => {
                                this.setState({
                                    isLoading:false
                                })
                            })
                    }
                })
                break;
            
            case 13:
                sendData.order_sn = rowData.order_sn;
                Modal.confirm({
                    title:`该订单是否确认支付？`,
                    onOk:() => {
                        this.setState({
                            isLoading:true
                        })
                        confirmPay(sendData)
                            .then(res => {
                                if(res.code == 1){
                                    message.success('操作成功')
                                        .then(() => {
                                            this.fetchData()
                                        })
                                }

                            })
                            .finally(() => {
                                this.setState({
                                    isLoading:false
                                })
                            })
                    }
                })
                break;
            
            case 14:
                sendData.order_sn = rowData.order_sn;
                Modal.confirm({
                    title:`该订单是否确认上架？`,
                    onOk:() => {
                        this.setState({
                            isLoading:true
                        })
                        confirmShelve(sendData)
                            .then(res => {
                                if(res.code == 1){
                                    message.success('操作成功')
                                        .then(() => {
                                            this.fetchData()
                                        })
                                }

                            })
                            .finally(() => {
                                this.setState({
                                    isLoading:false
                                })
                            })
                    }
                })
                break;

            default:
                break;
        }
    }

    handleRemark = (rowData) => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = rowData.order_sn;
            if(!sendData.remark){ return false };
            this.setState({
                isLoading:true
            })
            addRemark(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })
    }
    handlePartRefund = (rowData) =>  {
        let sendData = {};
        sendData.refund_id = rowData.refund_id || ''
        this.setState({
            isLoading:true
        })
        maxRefundMoney(sendData)
            .then(res => {
                if(res.code == 1){
                    let last_refund_money = res.data.last_refund_money,
                        pay_ment = res.data.pay_ment,
                        refund_money = res.data.refund_money;
                    this.setState({
                        lastRefundMoney:last_refund_money,
                        payMent:pay_ment,
                        refundMoney:refund_money
                    },() => {
                        this.ItemRefund.show((values) =>  {
                            let postData = values;
                            postData.refund_id = rowData.refund_id || ''
                            this.setState({
                                isLoading:true
                            })
                            orderPartRefund(postData)
                                .then(result => {
                                    if(result.code == 1){
                                        message.success('操作成功')
                                            .then(() => {
                                                this.fetchData();
                                            })
                                    }
                                })
                                .finally(() => {
                                    this.setState({
                                        isLoading:false
                                    })
                                })
                        })
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handlePickUp = (rowData) => {
        let sendData = {};
        sendData.order_sn = rowData.order_sn;
        sendData.use_code = rowData.use_code || ``;
        Modal.confirm({
            title:'是否对这笔订单进行核销？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pickUpCode(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('核销成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const trade_info = this.state.trade_info || {}
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <Remark onRef={ e => { this.Remark = e } }></Remark>
                <ItemRefund
                    onRef={ e => { this.ItemRefund = e } }
                    refundMoney={ this.state.refundMoney }
                    lastRefundMoney={ this.state.lastRefundMoney }
                    payMent = { this.state.payMent }
                ></ItemRefund>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;订单编号</span>} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入订单编号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;商品名称</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('goods_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称/商品编码' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;买家昵称</span>} className={'mgl5 fl'}>
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称/ID' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;买家手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;卖家昵称</span>} className={'fl'}>
                                {
                                    getFieldDecorator('saler_user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称/ID' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;卖家手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('saler_user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            {/* <FormItem label={'支付方式'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('trade_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>线上支付</Select.Option>
                                            <Select.Option value={2}>线下支付</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem> */}
                        </div>
                        <div className={`clearfix`}>
                            {/* <FormItem className={' fl'} label={<span>&emsp;时间筛选</span>}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem> */
                            <FormItem className={' fl'} label={<span>&emsp;时间筛选</span>}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={1}>下单</Select.Option>
                                            <Select.Option value={2}>支付</Select.Option>
                                            <Select.Option value={3}>完成</Select.Option>
                                        </Select>
                                    )
                                }
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>}
                        </div>
                        <div className={`clearfix`}>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                                {/*<Button type={"default"} className={'mgl10'} onClick={() => {this.handleDeliver()}}>批量发货</Button>*/}
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={`0`}></TabPane>
                    <TabPane tab={ <span>待付款{ Number(trade_info.wait_pay_num) >0 ? <span className={'tab-num'}>{ trade_info.wait_pay_num }</span> : ''} </span>} key={`1`}></TabPane>
                    <TabPane tab={ <span>待确认{ Number(trade_info.wait_confirm_num) >0 ? <span className={'tab-num'}>{ trade_info.wait_confirm_num }</span> : ''} </span>} key={`2`}></TabPane>
                    <TabPane tab={ <span>待上架{ Number(trade_info.wait_up_num) >0 ? <span className={'tab-num'}>{ trade_info.wait_up_num }</span> : ''} </span>} key={`3`}></TabPane>
                    <TabPane tab={ <span>已上架</span>} key={`4`}></TabPane>
                    <TabPane tab={ <span>待发货{ Number(trade_info.wait_delivery_num) >0 ? <span className={'tab-num'}>{ trade_info.wait_delivery_num }</span> : ''} </span>} key={`7`}></TabPane>
                    <TabPane tab={ <span>交易成功</span>} key={`5`}></TabPane>
                    <TabPane tab={ <span>已取消</span>} key={`6`}></TabPane>
                </Tabs>
                <div className={''}>
                    <table className="cxtables cxtables-sku">
                        <colgroup>
                            <col width={`18%`} />
                            <col width={`8%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            {/* <col width={`10%`} /> */}
                            <col width={`8%`} />
                            <col width={`8%`} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>商品信息</td>
                                <td>价格/数量</td>
                                <td>买家</td>
                                <td>实付金额</td>
                                <td>卖家</td>
                                <td>支付方式</td>
                                {/* <td>支付凭证</td> */}
                                <td>订单状态</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        {
                            this.state.noData ?
                                <tbody>
                                    <tr>
                                        <td colSpan={8}>
                                            <div className={`lh25 txtCenter`}>暂无订单数据</div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                null
                        }

                    </table>
                    {
                        this.state.tableData.map((item, index) => (
                            <table className="cxtables cxtables-sku mgt10" key={index}>
                                <colgroup>
                                    <col width={`18%`} />
                                    <col width={`8%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    {/* <col width={`10%`} /> */}
                                    <col width={`8%`} />
                                    <col width={`8%`} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td colSpan={8} className={`ftnormal clearfix`}>
                                            <Tag color={`#ff0000`}>抢购订单</Tag>
                                            <span>商户单号：<span>{item.order_sn}</span></span>
                                            <Icon type="file-text" className={`mgl5 cursor`} onClick={() => { this.handleCopy(item.order_sn) }} />
                                            <span className={`mgl10`}>下单时间：<span>{item.created_at}</span></span>
                                            {
                                                item.remark
                                                    ?
                                                    <Tooltip placement="left" title={`${item.remark}`}>
                                                        <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/6b8f2e533456302124ee110cd006b18f.png" width={20} className={`fr cursor`} alt="" />
                                                    </Tooltip>
                                                    :
                                                    <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/610b1cfaa3f361987ea789c8cacd7a8a.png" width={20} className={`fr cursor`} alt="" onClick={() => { this.handleRemark(item) }} />
                                            }
                                            {item.assign_type === 1? <Tag className={'mgl20'}>总部代下单</Tag> : ''}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={`noPadding bd-right`} colSpan={2}>
                                            <div className={`clearfix`}>

                                                {
                                                    item.goods_lists && item.goods_lists.map((good, goodIndex) => (
                                                        <div className={`clearfix bd-bottom`} key={goodIndex}>
                                                            <div className={`flex jusify-start align-center td-item fl`} style={{ width: '75%' }}>
                                                                <img src={good.cover_img} alt="" width={60} />
                                                                <div className={`mgl10`}>

                                                                    <div className={`lh25`}>{good.name ? good.name : ''}</div>
                                                                </div>
                                                            </div>
                                                            <div className={` td-price fr`}>
                                                                <div className={`lh25 txtRight`}>&yen;{item.pay_ment}</div>
                                                                <div className={`lh25 txtRight`}>x{good.num}</div>
                                                                {
                                                                    good.refund_info
                                                                        ?
                                                                        <div className={`lh25 txtRight colorRed`}>已退{good.refund_info.refund_num}件</div>
                                                                        :
                                                                        null
                                                                }
                                                               

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.user_info? item.user_info.name : "-"}</div>
                                            <div className={`lh25`}>{item.user_info? item.user_info.mobile : "-"}</div>
                                        </td>
                                        <td>
                                            {
                                                [0, 6, 7, 8].includes(item.trade_status)
                                                    ?
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>待支付：￥{item.pay_ment}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>支付合计：￥{item.pay_ment}</div>

                                                    </div>
                                            }
                                            {
                                                item.trade_extends && item.refund_price > 0
                                                    ?
                                                    <div className={`lh25 colorRed`}>退款金额：￥{item.refund_price || '-'}</div>
                                                    :
                                                    null
                                            }
                                            <div className={`lh25`} > 售卖金额：¥ {item.sale_price || '0'}</div>
                                            <div className={`lh25`} style={{color:'#999'}}>（ 委托上架费：¥ {item.trust_money || '0'} ）</div>
                                            <div className={`lh25`}>支付时间：{item.pay_time || '-'}</div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.saler_info? item.saler_info.name : "-"}</div>
                                            <div className={`lh25`}>{item.saler_info? item.saler_info.mobile : "-"}</div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>支付方式：{item.pay_type_name || '-'}</div>
                                        </td>
                                        {/* <td>
                                            <div className={`lh25`}>支付凭证</div>
                                        </td> */}
                                        <td>
                                            <div>
                                                {
                                                    [1, 2,11,15].includes(item.trade_status)
                                                        ?
                                                        <Tag color="green">{item.trade_status_name}</Tag>
                                                        :
                                                        null
                                                }
                                                {
                                                    [5,9,10,12,13,14].includes(item.trade_status)
                                                        ?
                                                        <Tag>{item.trade_status_name}</Tag>
                                                        :
                                                        null

                                                }
                                                {
                                                    [0,3,4,6,7,8].includes(item.trade_status)
                                                        ?
                                                        <Tag color="orange">{item.trade_status_name}</Tag>
                                                        :
                                                        null

                                                }
                                            </div>
                                            {/* <div className={'btn-row mgt20'}>
                                                <Link to={`/order/panic-detail/${item.order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                                            </div> */}
                                        </td>
                                        <td>
                                             <Button type={"link"} size={"small"} onClick={() => {this.handleDetail(item)}}>查看详情</Button>
                                            {/* <div className={'btn-row mgt20'}>
                                                <Link to={`/order/panic-detail/${item.order_sn}`} target={`_blank`} className={`fi-link fz14 w150 mgl10`}>查看详情</Link>
                                            </div> */}
                                            {
                                                [0,13].includes(item.trade_status)
                                                    ?
                                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(item,0)}}>取消订单</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                [13].includes(item.trade_status)
                                                    ?
                                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(item,13)}}>确认支付</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                item.trade_status == 14
                                                    ?
                                                    <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(item,14)}}>确认上架</Button>
                                                    :
                                                    null
                                            }
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        ))
                    }

                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }


            </div>
        )
    }


}

export default Form.create({})(withRouter(PanicLists))
