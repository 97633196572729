import React from 'react'
import { Modal, Input, Button, Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { itemLists } from '@/api/item'
import {flowZone} from '@/api/panic-buying'
import CX from '@/utils/CX'

export default class ZoneList extends React.Component{

    state = {
        title:'选择商品',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        columns:[
            {
                title:'活动名称',
                dataIndex:'name',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'商品数量',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                           <p>{rowData.goods_num}</p>
                        </div>
                    )
                }
            },
            {
                title:'是否启用',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 2
                                    ?
                                    <Tag color={`orange`}>未启用</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`green`}>已启用</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
        ],
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        per_page:10,
        total:0,
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    show = (callback) => {
        let selectIds = this.props.selectIds || [],
            items = this.props.items || [];
        this.setState({
            visible:true,
            callback: callback ? callback : null,
            selectedRowKeys:selectIds,
            selectRows:items
        },() => {
            this.fetchData();
        })
    }
    close = () => {
        this.setState({
            name:'',
            page:1,
            visible:false
        })
    }
    fetchData = () => {
        let sendData = {
            page:this.state.page,
            name:this.state.name,
            per_page:this.state.per_page
        }
        this.setState({
            tableLoading:true
        })
        flowZone(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list;
                    this.renderSelectRows(tableData)
                    this.setState({
                        tableData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if(selectedRowKeys.length){
            for(var k in lists){
                if(selectedRowKeys.includes(lists[k]['id'])){
                    let selectRowsIds = selectRows.map(item => {return item.id})
                    if(!selectRowsIds.includes(lists[k]['id'])){
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }


    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(this.state.selectRows)
        }
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val =>  {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name: e.target.value
        })
    }

    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {

                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    })


                }
            }
        }
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}

                >
                    <div className={'mgt20'}>
                        <Table
                            size={`middle`}
                            rowSelection={rowSelection}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        >
                        </Table>

                    </div>

                </Modal>
            </div>
        )

    }

}


