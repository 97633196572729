import React from 'react'
import { Form, Input, Radio, DatePicker, Select, Tooltip, Icon, Checkbox, Button, Table, Pagination, Divider, Switch, TimePicker } from 'antd'
import { withRouter } from 'react-router-dom'
import { panicSave, panicDetail } from '@/api/panic-buying'
import { storeLists } from '@/api/store'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import { timeDiscountSave, timeDiscountDetail } from '@/api/time-discount'
import { assignTrade } from '@/api/user'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment'
import message from '@/utils/message'
import SkuItemPicker from '@/components/SkuItemDispatch/SkuItemDispatch'
import { array } from 'prop-types'
const FormItem = Form.Item;
const format = 'HH:mm';

class UserDispatch extends React.Component {
    state = {
        formData: {},
        isLoading: false,
        storeLists: [],
        tagsLists:[],
        skuItemLists:[],
        columns: [
            {
                title: '商品名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`lh25`}>{ rowData.name || `-` }</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '价格',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <p>&yen;{rowData.price}</p>
                    )
                }
            },
            {
                title: '库存',
                dataIndex: 'stock',
                width:"100px",
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.stock>0 ?rowData.stock : `未设置`}</span>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '25%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDelete(rowData, index) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        currentItem: ''
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            // this.fetchData()
        }
    }
    fetchData = () => {
        // let sendData = {}
        // sendData.id = this.props.match.params.id;
        // this.setState({
        //     isLoading: true
        // })
        // panicDetail(sendData)
        //     .then(res => {
        //         if (res.code == 1) {
        //             let detail = res.data.detail
        //             detail.goods = detail.goods_list
        //             this.setState({
        //                 formData: detail,
        //             })
        //         }
        //     })
        //     .finally(() => {
        //         this.setState({
        //             isLoading: false
        //         })
        //     })

    }

    showCoupon = ()=> {
        this.SkuItemPicker.show(res => {
            if(res){
                console.log('----',res)
                let skuItemLists = res;
                this.setState({
                    skuItemLists
                })
            }
        })
    }

    
    handlePrevStep = () => {
        this.setState({
            step: 1
        })
    }
    saveData = () => {
        let sendData = {}
        let skulist  = this.state.skuItemLists;
        if(skulist.length === 0){
            message.error('请选择派单的商品');
            return;
        }
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                this.setState({
                    isLoading: true
                })
                sendData = {
                    user_id: this.props.match.params.id,
                    act_id: skulist[0].act_id,
                    goods_id: skulist[0].goods_id,
                    sku_id: skulist[0].sku_id,
                    pay_ment: Number(values['pay_ment']),
                }
                assignTrade(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('订单已指派')
                                .then(() => {
                                    this.props.history.push({
                                        pathname: `/user/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }
    handleDelete = (rowData, index) => {
        let skuItemLists = this.state.skuItemLists;
        skuItemLists.splice(index, 1);
        this.setState({
            skuItemLists
        })
    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        let skuItemLists = this.state.skuItemLists || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 14
            }
        }

        return (
            <div className={`shadow-radius`}>
                <Form {...formItemLayout} className={'model-form'}>
                    <div>
                        <div className={`msg-title mgb15`}>商品信息</div>
                        <div className="flex">
                            <div className={`flex1`}>
                                <FormItem label={<span>指定商品</span>}>
                                    <Button onClick={this.showCoupon}>选择商品</Button>
                                    <div className={'content-body mgt10'}>
                                        <Table
                                            size={`middle`}
                                            columns={this.state.columns}
                                            dataSource={skuItemLists}
                                            loading={this.state.tableLoading}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                        ></Table>
                                    </div>
                                <div className={'clearfix mgt10'}>

                                </div>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`msg-title mgb15`}>订单信息</div>
                        <div className="flex">
                            <div className={`flex1`}>
                                <FormItem label={'实付金额'}>
                                    {getFieldDecorator('pay_ment', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入订单金额'
                                            }
                                        ],
                                    })(<Input placeholder={'请输入实付金额'} suffix="元" style={{ width: '200px' }} type='number'/>)}
                                </FormItem>
                            </div>
                        </div>
                        <div className={`txtCenter`}>
                            <Button type={`primary`} onClick={this.saveData}>保存</Button>
                        </div>
                    </div>
                </Form>
                {this.state.isLoading ? <Loading /> : null}
                <SkuItemPicker onRef={ (e) => { this.SkuItemPicker = e } }  multiple={true} shouldChooseDisable={true} store_id={getFieldValue('store_id')}  selectIds={this.state.skuItemLists.map(item => {return item.id })}></SkuItemPicker>
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserDispatch))

