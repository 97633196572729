import React from 'react'
import { Form, Input, Button, Tooltip, Radio, Icon, Select, DatePicker } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { cateLists } from '@/api/point-mall'
import { pointMallSave, pointMallDetail } from '@/api/point-mall'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import MarketItemPicker from '@/views/timeDiscount/components/market-item-picker'
import PointItem from './components/point-item'
import PointCoupon from './components/point-coupon'
import PointItemSku from './components/point-item-sku'
import moment from 'moment'
import CateManage from './components/cate-manage'
const FormItem = Form.Item;
const { TextArea } = Input

class AddPointGoods extends React.Component{
    state = {
        formData:'',
        storeLists:[],
        cateLists:[],
        isLoading:false
    }
    componentDidMount() {
        this.fetchStore();
        this.fetchCate()
        if( this.props.match.params.id ){
            this.fetchData()
        }
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id || ``;
        this.setState({
            isLoading:true
        })
        pointMallDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail,
                        formData = {};
                    if(detail.type == 1){
                        //商品
                        let goods_info = detail.goods_info;
                        for(var i in goods_info['goods_extend']){
                            if(!goods_info[i]){
                                goods_info[i] = goods_info['goods_extend'][i]
                            }
                        }
                        delete goods_info['goods_extend']
                        if(detail.goods_list && detail.goods_list.length){
                            goods_info['sku_list'] = detail.goods_list
                        }
                        formData = detail;
                        formData.suit_goods = [detail.goods_info];
                        formData.suit_stores = formData.suit_store_ids || []
                        let valid_type = Number(formData.valid_type)
                        switch (valid_type) {
                            case 2:
                                formData.valid_day_today = formData.valid_day
                                break;
                            case 3:
                                formData.valid_day_tomorrow = formData.valid_day

                                break;

                        }


                        this.setState({
                            formData
                        })

                    }else if(detail.type == 2){
                        //优惠券
                        formData = detail;
                        formData.suit_coupons = [detail.goods_info];
                        formData.suit_stores = formData.suit_store_ids || []
                        this.setState({
                            formData
                        })
                    }

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
            })
    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = 1000;
        this.setState({
            isLoading:true
        })
        cateLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.category_list.data;
                    this.setState({
                        cateLists:lists,
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleItem = () => {
        let formData = this.state.formData || {};
        this.MarketItemPicker.show(res => {
            if(res.length){
                if(res[0]['goods_extend']){
                    let rowData = res[0];
                    for(var i in rowData['goods_extend']){
                        if(!rowData[i]){
                            rowData[i] = rowData['goods_extend'][i]
                        }
                    }
                    delete rowData['goods_extend']
                }
                formData.suit_goods = res
                this.setState({
                    formData
                })
            }
        })

    }
    handleCoupon = () => {
        let formData = this.state.formData || {};
        this.CouponPicker.show(res => {
            if(res.length){
                formData.suit_coupons = res
                this.setState({
                    formData
                })
            }
        })
    }
    handleDel = () => {
        let formData = this.state.formData || {};
        formData.suit_coupons = [];
        formData.suit_goods = [];
        this.setState({
            formData
        })
    }
    handleAdd = () => {
        this.CateManage.show(res => {
            this.fetchCate();
        })

    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                if(values.valid_type == 0){
                    //永久有效
                    values.start_at = ''
                    values.end_at = ''
                    values.valid_day = ''
                }else if(values.valid_type == 1){
                    //固定日期
                    values.start_at = values.start_at ? moment(values.start_at).format('YYYY-MM-DD') : ''
                    values.end_at = values.end_at ? moment(values.end_at).format('YYYY-MM-DD') : ''
                    values.valid_day = ''
                }else if(values.valid_type == 2) {
                    //当日开始
                    values.start_at = ''
                    values.end_at = ''
                    values.valid_day = values.valid_day_today

                }else {
                    //次日开始
                    values.start_at = ''
                    values.end_at = ''
                    values.valid_day = values.valid_day_tomorrow
                }
                sendData = values;
                if(sendData.type == 1){
                    //商品
                    if(formData.suit_goods && formData.suit_goods.length){
                        sendData.prize_id = formData.suit_goods[0]['goods_id']
                        if(formData.suit_goods[0]['props']){
                            let isPassed = true,
                                itemSkuData = this.PointItemSku.getData().item;
                            itemSkuData.sku_list && itemSkuData.sku_list.forEach((sku,skuKey) => {
                                sku.is_join = sku.is_join ? 1 : 0;
                                sku.cost_type = sku.cost_type || 1;
                                sku.stock = sku.stock || '';
                                sku.cost_points = sku.cost_points || '';
                                sku.cost_price = sku.cost_price || '';
                                if(sku.is_join){
                                    if(!sku.stock || !sku.cost_points){
                                        isPassed = false
                                    }
                                }
                            })
                            if(!isPassed){
                                message.error('商品兑换规则未完善，请设置');
                                return ;
                            }
                            sendData.sku_list = itemSkuData.sku_list || [];
                        }
                    }else {
                        message.error('请选择商品');
                        return ;
                    }

                }else if(sendData.type == 2){
                    //优惠券
                    if(formData.suit_coupons && formData.suit_coupons.length){
                        sendData.prize_id = formData.suit_coupons[0]['id']
                    }else {
                        message.error('请选择优惠券');
                        return ;
                    }

                }
                if(formData.id){
                    sendData.id = formData.id
                }
                this.setState({
                    isLoading:true
                })
                pointMallSave(sendData)
                    .then(res  => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/point-mall/index`
                                    })
                                })

                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    render() {
        let data = this.state.formData || {},
            currentItem = data.suit_goods && data.suit_goods.length ? data.suit_goods[0] : {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={`shadow-radius`}>
                <MarketItemPicker
                    onRef={ e => { this.MarketItemPicker = e } }
                    selectIds={ data.suit_goods && data.suit_goods.length ? data.suit_goods.map(item => {return item.id}) : []}
                    multiple={ false }
                ></MarketItemPicker>
                <CouponPicker
                    onRef={ e => { this.CouponPicker = e } }
                    multiple={ false }
                    selectIds={  data.suit_coupons && data.suit_coupons.length ? data.suit_coupons.map(item => {return Number(item.id)}) : [] }
                ></CouponPicker>
                <CateManage onRef={ e => { this.CateManage = e } }></CateManage>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={``}>
                        <div className={`msg-title mgb15`}>基本信息</div>
                        <FormItem label={'选择积分商品'}>
                            {getFieldDecorator('type', {
                                initialValue: data.type  || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择积分商品'
                                    }
                                ],
                            })(
                                <Radio.Group  disabled={!!this.props.match.params.id}>
                                    <Radio value={1}>商品</Radio>
                                    <Radio value={2}>优惠券</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        {
                            getFieldValue('type') == 1
                                ?
                                <FormItem label={'选择商品'} required={true}>
                                    <Button type={`primary`} onClick={ this.handleItem }   disabled={!!this.props.match.params.id} >选择商品</Button>
                                </FormItem>
                                :
                                <FormItem label={'选择优惠券'} required={true}>
                                    <Button type={`primary`} onClick={ this.handleCoupon }  disabled={!!this.props.match.params.id} >选择优惠券</Button>
                                </FormItem>
                        }
                        {
                            getFieldValue('type') == 1 && data.suit_goods && data.suit_goods.length
                                ?
                                <FormItem label={` `} colon={false}>
                                    <PointItem
                                        onRef={ e => { this.PointItem = e } }
                                        item={ data.suit_goods }
                                        onDel={ (e) => { this.handleDel(e) } }
                                    ></PointItem>
                                </FormItem>
                                :
                                null
                        }
                        {
                            getFieldValue('type') == 2 && data.suit_coupons && data.suit_coupons.length
                                ?
                                <FormItem label={` `} colon={false}>
                                    <PointCoupon
                                        onRef={ e => { this.PointCoupon = e } }
                                        item={ data.suit_coupons }
                                        onDel={ (e) => { this.handleDel(e) } }
                                    ></PointCoupon>
                                </FormItem>
                                :
                                null
                        }

                        <FormItem label={'选择分类'}>
                            <div>
                                {getFieldDecorator('category_id', {
                                    initialValue: data.category_id || 0,
                                })(
                                    <Select style={{width:300}} placeholder={'请选择'}>
                                        <Select.Option value={0} >请选择</Select.Option>
                                        {
                                            this.state.cateLists.map( item => (
                                                <Select.Option value={item.id} key={item.id}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )}
                                <Button type={'primary'} ghost onClick={this.handleAdd} icon={'plus'} className={`mgl10`}>新增分类</Button>
                            </div>

                        </FormItem>
                        {/* <FormItem
                            label={
                                <span>
                                    活动门店
                                    <Tooltip placement={`right`} title={`所有售有选中商品的门店全部参加活动，后续创建的售有选中商品的门店自动加入该活动。`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('suit_store_type', {
                                initialValue: data.suit_store_type  || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择营业类型'
                                    }
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>全部门店</Radio>
                                    <Radio value={2}>指定门店</Radio>
                                </Radio.Group>
                            )}
                        </FormItem> */}
                        {/* {
                            getFieldValue('suit_store_type') == 2
                                ?
                                <FormItem label={'选择门店'}>
                                    {getFieldDecorator('suit_stores', {
                                        initialValue: data.suit_stores || [],
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择适用门店'
                                            }
                                        ]
                                    })(
                                        <Select mode="multiple" style={{width: '300px'}} placeholder="请选择">
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option
                                                        value={store.id}
                                                        key={store.id}
                                                    >{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                :
                                ''
                        } */}
                        {
                            currentItem.props && getFieldValue('type') == 1
                                ?
                                <FormItem label={`兑换规则`} wrapperCol={{ span:17 }} required={true}>
                                    <PointItemSku
                                        onRef={ e => { this.PointItemSku = e } }
                                        item = { currentItem }
                                    ></PointItemSku>
                                </FormItem>
                                :
                                <div>
                                    <FormItem label={'兑换规则'}>
                                        {getFieldDecorator('cost_type', {
                                            initialValue: data.cost_type  || 1,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择兑换规则'
                                                }
                                            ],
                                        })(
                                            <Radio.Group>
                                                <Radio value={1}>纯积分</Radio>
                                                <Radio value={2}>积分+金额兑换</Radio>
                                            </Radio.Group>
                                        )}
                                    </FormItem>
                                    {
                                        getFieldValue('cost_type') == 1
                                            ?
                                            <FormItem label={' '} colon={false}>
                                                <div>
                                                    {
                                                        getFieldDecorator('cost_points', {
                                                            initialValue: data.cost_points || '',
                                                        })(
                                                            <Input style={{ width:'100px' }} className={`mgr5`} />
                                                        )
                                                    }
                                                    积分
                                                </div>
                                            </FormItem>
                                            :
                                            <FormItem label={' '} colon={false}>
                                                <div>
                                                    {
                                                        getFieldDecorator('cost_points', {
                                                            initialValue: data.cost_points || '',
                                                        })(
                                                            <Input style={{ width:'100px' }} className={`mgr5`} />
                                                        )
                                                    }
                                                    积分+
                                                    {
                                                        getFieldDecorator('cost_price', {
                                                            initialValue: data.cost_price || '',
                                                        })(
                                                            <Input style={{ width:'100px' }} className={`mgl5 mgr5`} />
                                                        )
                                                    }
                                                    元
                                                </div>

                                            </FormItem>
                                    }
                                    <FormItem label={'可兑换数量'}>
                                        {getFieldDecorator('stock', {
                                            initialValue: data.stock || '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入数量'
                                                }
                                            ]
                                        })(<Input
                                                placeholder={''}
                                                maxLength={20}
                                                style={{ width:'200px' }}
                                            />
                                        )}
                                    </FormItem>
                                </div>
                        }

                        <FormItem label={'兑换限制'}>
                            {getFieldDecorator('limit_type', {
                                initialValue: data.limit_type  || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择兑换限制'
                                    }
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={2}>
                                        限制兑换
                                        {
                                            getFieldDecorator('limit_num',{
                                                initialValue: data.limit_num  || '',
                                            })(

                                                <Input style={{ width:'100px' }} className={`mgl5 mgr5`} />
                                            )
                                        }
                                        件
                                    </Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        {
                            getFieldValue('type') == 1
                                ?
                                <FormItem label={'使用有效期'}>
                                    {getFieldDecorator('valid_type', {
                                        initialValue: data.valid_type || 0,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择使用有效期'
                                            }
                                        ]
                                    })(
                                        <Radio.Group>
                                            <Radio value={0} className={`block  lh40`}>
                                                永久有效
                                            </Radio>
                                            <Radio value={1} className={`block mgb15`}>
                                                {
                                                    getFieldDecorator('start_at',{
                                                        initialValue:data.start_at ? moment(data.start_at, 'YYYY-MM-DD')   : null
                                                    })(
                                                        <DatePicker  />
                                                    )
                                                }
                                                <span className={`mgl10 mgr10`}>至</span>
                                                {
                                                    getFieldDecorator('end_at',{
                                                        initialValue:data.end_at ? moment(data.end_at, 'YYYY-MM-DD')   : null
                                                    })(
                                                        <DatePicker />
                                                    )
                                                }
                                            </Radio>
                                            <Radio value={2} className={`block mgb15`}>
                                                兑换后当日开始
                                                {
                                                    getFieldDecorator('valid_day_today',{
                                                        initialValue:data.valid_day_today || ''
                                                    })(
                                                        <Input type={'number'}  style={{ width:'80px' }} className={`mgl10`} ></Input>
                                                    )
                                                }
                                                <span className={`mgl10`}>天内有效</span>
                                            </Radio>
                                            <Radio value={3} className={`block`}>
                                                兑换后次日开始
                                                {
                                                    getFieldDecorator('valid_day_tomorrow',{
                                                        initialValue:data.valid_day_tomorrow || ''
                                                    })(
                                                        <Input type={'number'}  style={{ width:'80px' }} className={`mgl10`} ></Input>
                                                    )
                                                }
                                                <span className={`mgl10`}>天内有效</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    <span className={`fi-help-text`}>使用有效期只针对门店自提的商品，有效期过了则不能到店进行兑换。</span>
                                </FormItem>
                                :
                                null
                        }

                        <FormItem label={'排序'}>
                            {getFieldDecorator('sort', {
                                initialValue: data.sort || '',
                            })(<Input
                                    placeholder={''}
                                    maxLength={20}
                                    style={{ width:'200px' }}
                                />
                            )}
                            <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                        </FormItem>
                        <FormItem label={'初始兑换数'}>
                            {getFieldDecorator('basic_sales', {
                                initialValue: data.basic_sales || '',
                            })(<Input
                                    placeholder={''}
                                    maxLength={20}
                                    style={{ width:'200px' }}
                                />
                            )}
                            <span className={`fi-help-text`}>客户看到的已兑数=初始兑换数+实际兑换数量</span>
                        </FormItem>
                        <FormItem label={'积分兑换说明'}>
                            {
                                getFieldDecorator('content',{
                                    initialValue: data.content || '',
                                    rules: []
                                })(
                                    <TextArea rows={6} placeholder={'积分兑换说明'} style={{ width:'350px' }}   />
                                )
                            }
                        </FormItem>
                        <FormItem label={' '} colon={false}>
                            <Button type={`primary`} onClick={ this.saveData } >立即保存</Button>
                        </FormItem>

                    </div>
                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(AddPointGoods))
