import $axios from '@/axios/axios.js'

export function groupShoppingSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/save`,
        method:'post',
        data
    })
}

export function groupShoppingPut(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/setField`,
        method:'post',
        data
    })
}

export function groupShoppingLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping`,
        method:'post',
        data
    })
}

export function groupShoppingDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/detail`,
        method:'post',
        data
    })
}

export function groupShoppingEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/end`,
        method:'post',
        data
    })
}

export function groupShoppingDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/delete`,
        method:'post',
        data
    })
}

export function groupShoppingActiveData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/groupData`,
        method:'post',
        data
    })
}

export function groupShoppingSelectGroup(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/selectGroup`,
        method:'post',
        data
    })
}


export function exportActiveData(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/export`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function groupShoppingCopy(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/group-shopping/copy`,
        method:'post',
        data
    })
}
