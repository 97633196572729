import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, DatePicker, Tag, Drawer, Modal, Row ,Col } from 'antd'
import { withRouter,Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { userLists ,userLabelList, changeReferee, changePassword,userEdit } from '@/api/user'
import message from '@/utils/message'
import UserDetail from './components/UserDetail'
import UserUpload from './components/UserUpload'
import CreateUser from './components/CreateUser'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker

class UserLists extends React.Component{
    state = {
        columns:[
            {
                title:'客户ID',
                dataIndex:'id',
                width:'80px',
                render:(text, rowData, index) => {
                    return (
                            <p className={`lh25`}>{rowData.id}</p>
                    )
                }
            },
            {
                title:'客户信息',
                dataIndex:'logo',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url || 'https://img.cxkoo.com/chengxuan/1/2022-05-24/67968ca724e5676cfdf58c050a6beba5.png'} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} onClick={() => {this.handleEdit(rowData)}}>
                                    { rowData.name || `游客` }
                                    <i className={`icon-${rowData.sex == 1  ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.second_name
                                            ?
                                            <span>（{rowData.second_name}）</span>
                                            :
                                            null
                                    }
                                </div>
                                <div className={`lh25`}>{ rowData.mobile || `暂无手机号` }</div>
                                <div className={`lh25`}>
                                    <Tag>普通会员</Tag>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'客户资产',
                dataIndex:'name',
                width:140,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {/* <p className={`lh25`}>持卡：{rowData.card_num}张</p>
                            <p className={`lh25`}>余额：{rowData.wallet_money}元</p> */}
                            <p className={`lh25`}>积分：{rowData.points}</p>
                        </div>
                    )
                }
            },
            {
                title:'消费情况',
                dataIndex:'name',
                width:200,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>累计消费金额：¥ {rowData.consume_money  ? rowData.consume_money :0 }</p>
                            <p className={`lh25`}>累计消费次数：{rowData.consume_times ? rowData.consume_times :0 }次</p>
                            <p className={`lh25`}>最近消费时间：{rowData.last_trade_time  ? rowData.last_trade_time :'-'}</p>
                            <p className={`lh25`}>成为客户时间：{rowData.created_at ? rowData.created_at: '-'}</p>
                        </div>
                    )
                }
            },
            {
                title:'邀请人',
                dataIndex:'first_user',
                width:160,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>姓名：{ rowData.first_user?.name || '-' }</p>
                            <p className={`lh25`}>手机：{ rowData.first_user?.mobile || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:100,
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleEdit(rowData)}}>查看详情</Button>
                            <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleAdjust(rowData)}}>调上级</Button>
                            <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleDispatch(rowData)}}>派单</Button>
                            <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleUpdPwd(rowData)}}>修改密码</Button>
                            {
                                rowData.is_black?
                                <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleBlacklist(rowData,0)}}>取消黑名单</Button>:
                                <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleBlacklist(rowData,1)}}>加入黑名单</Button>
                            }
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            user_id:'',
            name:'',
            mobile:'',
            source:'',
            start_time:'',
            end_time:''
        },
        isModalAdjust:false,
        isModalPwd:false,
        tableData:[],
        select_user_id:'',
        tableLoading:false,
        drawerVisible:false,
        page:1,
        total:0,
        per_page:10,
        user_data:{},
        currentRow:'',
        tags_list:[]
    }

    componentDidMount() {
        this.fetchData()
        this.fetchLabel()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        userLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let user_data =  res.data.user_data
                    let lists = res.data.user_lists.data,
                        total = res.data.user_lists.total,
                        per_page = res.data.user_lists.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page,
                        user_data
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchLabel=()=>{
        userLabelList({}).then(res=>{
            if(res.code==1){
                let tags_list=res.data.list
                this.setState({
                    tags_list
                })
            }
        })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleUpload=()=>{
        this.userUpload.show(res=>{
            this.fetchData();
        })
    }

    handleEdit = rowData => {
        this.setState({
            drawerVisible:true,
            currentRow :rowData
        })
    }

    //调上级
    handleAdjust = rowData => {
        this.setState({
            isModalAdjust:true,
            select_user_id:rowData.id
        })
    }

    handleOk = () => {
        let  sendData = {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                sendData = {
                    user_id:this.state.select_user_id,
                    target_user_id:values.target_user_id
                };
                changeReferee(sendData).then(res => {
                    if(res.code == 1){
                        message.info('上级已调整')
                        this.setState({
                            isModalAdjust:false,
                        })
                        this.fetchData()
                    }
                })
                .finally(() => {
                    this.setState({
                        isModalAdjust:false
                    })
                })

            }
        })
        
    };
    handleCancel = () => {
        this.setState({
            isModalAdjust:false,
        })
    }


    //派单
    handleDispatch = rowData => {
        this.props.history.push({
            pathname:`/user/user_dispatch/${rowData.id}`
        })
    }

    //修改密码
    handleUpdPwd = rowData => {
        this.setState({
            isModalPwd:true,
            select_user_id:rowData.id
        })
    }

    handleBlacklist=(row,num)=>{
        let titles;
        let that = this;
        if(num == 0){
            titles = '确定要取消拉黑该用户吗？'
        }else{
            titles = '确定要拉黑该用户吗？'
        }
        Modal.confirm({
            title:titles,
            onOk(){
                userEdit({user_id:row.id,is_black:num}).then(res=>{
                    if (res.code == 1) {
                        message.success('修改成功')
                        that.fetchData()
                    }
                })
            }
        })
    }
    
    handleUpdPWdOk = () => {
        let  sendData = {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                sendData = {
                    user_id:this.state.select_user_id,
                    password:values.newPassword
                };
                changePassword(sendData).then(res => {
                    if(res.code == 1){
                        message.info('密码已修改')
                        this.setState({
                            isModalPwd:false,
                        })
                        this.fetchData()
                    }
                })
                .finally(() => {
                    this.setState({
                        isModalPwd:false
                    })
                })

            }
        }) 
    };
    handleUpdCancel = () => {
        this.setState({
            isModalPwd:false,
        })
    }

    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
        this.fetchData();
    }

    handleAddUser=()=>{
        this.userCreate.show(()=>{
            this.fetchData()
        })
    }

    handleUpload=()=>{
        this.userUpload.show(res => {
            this.fetchData();
        });
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        let user_data = this.state.user_data || {} ;
        return(
            <div className={`shadow-radius`}>
                <div className={`flex justify-start align-center`}>
                    <div className={`data-statistic`}>
                        <div className={`data-title`}>
                            <span className={`fi-title`}>客户数据</span>
                            <span className={`fi-sub-title mgl15`}></span>
                        </div>
                        <div className={`data-items-cont mgt30 pdb25`}>
                            <div className={`data-item`}>
                                <div className={`item-txt `}>总客户数</div>
                                <div className={`item-num ft-bold primary`}>{ user_data.total_user_num > 0  ? user_data.total_user_num : 0 }</div>
                                <div className={`item-txt `}>昨日 { user_data.yesterday_total_num  > 0  ? user_data.yesterday_total_num  : 0 }</div>
                            </div>
                            <div className={`data-item`}>
                                <div className={`item-txt `}>今日新增客户数</div>
                                <div className={`item-num ft-bold primary`}>{ user_data.today_new_num   > 0  ? user_data.today_new_num   : 0 }</div>
                                <div className={`item-txt `}>昨日 { user_data.yesterday_new_num   > 0  ? user_data.yesterday_new_num   : 0 }</div>
                            </div>
                            <div className={`data-item`}>
                                <div className={`item-txt `}>今日支付人数</div>
                                <div className={`item-num ft-bold primary`}>{ user_data.today_pay_num    > 0  ? user_data.today_pay_num    : 0 }</div>
                                <div className={`item-txt `}>昨日 { user_data.yesterday_pay_num    > 0  ? user_data.yesterday_pay_num    : 0 }</div>
                            </div>
                            <div className={`data-item`}>
                                <div className={`item-txt `}>今日客单价</div>
                                <div className={`item-num ft-bold primary`}>{ user_data.today_single_price    > 0  ? user_data.today_single_price.toFixed(2)    : 0 }</div>
                                <div className={`item-txt `}>昨日 ¥{ user_data.yesterday_single_price    > 0  ? user_data.yesterday_single_price.toFixed(2)    : 0 }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'content-head mgb15 mgt20'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户姓名'} className={'fl'}>
                            {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户姓名' style={{width: '200px'}}/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'手机号码'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width: '200px'}}/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'客户来源'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('source')(
                                        <Select style={{width: 200}} placeholder={'请选择'}>
                                            <Select.Option value={1}>手工添加</Select.Option>
                                            <Select.Option value={2}>微信小程序</Select.Option>
                                            <Select.Option value={3}>外部导入</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        {/*<div className={`clearfix`}>*/}
                        {/*<FormItem label={'客户身份'} className={'fl'}>*/}
                        {/*{*/}
                        {/*getFieldDecorator('type')(*/}
                        {/*<Select style={{width:200}} placeholder={'请选择'}>*/}
                        {/*<Select.Option value={1}>非会员</Select.Option>*/}
                        {/*<Select.Option value={2}>普通会员</Select.Option>*/}
                        {/*</Select>*/}
                        {/*)*/}
                        {/*}*/}
                        {/*</FormItem>*/}
                        {/*<FormItem label={'客户归属'} className={'mgl20 fl'}>*/}
                        {/*{*/}
                        {/*getFieldDecorator('belong')(*/}
                        {/*<Select style={{width:200}} placeholder={'请选择'}>*/}
                        {/*<Select.Option value={1}>总部</Select.Option>*/}
                        {/*<Select.Option value={2}>门店1</Select.Option>*/}
                        {/*<Select.Option value={3}>门店2</Select.Option>*/}
                        {/*<Select.Option value={4}>门店3</Select.Option>*/}
                        {/*</Select>*/}
                        {/*)*/}
                        {/*}*/}
                        {/*</FormItem>*/}
                        {/*</div>*/}
                        <div className={`clearfix`}>
                            <FormItem label={'成为客户时间'} className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'客户标签'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('label_id')(
                                        <Select style={{width: 200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.tags_list.map(item => {
                                                    return <Select.Option
                                                        value={item.extra_id}>{item.extra_name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem className={'fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {
                                    this.handleReset()
                                }}>重置</Button>
                                {/* <Button type={"default"} className={'mgl10'} onClick={() => {this.handleUpload()}}>客户导入</Button> */}
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAddUser} icon={'plus'}>新增客户</Button>
                    {/* <Button type={'default'} onClick={() => {
                        this.handleUpload()
                    }} icon={'upload'} className={`mgl10`}>客户导入</Button> */}
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total}
                                            pageSize={this.state.per_page} onChange={this.handleCurrentChange}/>
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`客户详情`}
                            closable={true}
                            width={`88%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={this.handleDrawerClose}
                            headerStyle={{borderBottom: '0px'}}
                            bodyStyle={{padding: '0px'}}
                        >
                            <UserDetail
                                onRef={e => {
                                    this.UserDetail = e
                                }}
                                user_id={this.state.currentRow ? this.state.currentRow.id : ''}
                            ></UserDetail>
                        </Drawer>
                        :
                        null
                }

                <UserUpload onRef={e => {
                    this.userUpload = e
                }}/>

                <CreateUser onRef={e => {
                    this.userCreate = e
                }}/>

                <Modal title="调整上级" visible={this.state.isModalAdjust} onOk={this.handleOk} onCancel={this.handleCancel} destroyOnClose={true}>
                    <Form {...formItemLayout} className={'model-form'}>
                        <Row gutter={24}>
                            <Col span={23}>
                                <FormItem label="上级ID">
                                    {getFieldDecorator('target_user_id', {
                                        initialValue: undefined,
                                        // rules: [
                                        //     {
                                        //         required: true,
                                        //         message: '请输入上级ID'
                                        //     }
                                        // ]
                                    })(<Input maxLength={15} placeholder='请输入上级ID,0代表没有上级' />)}
                                </FormItem>
                            </Col>
                            <Col span={4}>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal title="修改密码" visible={this.state.isModalPwd} onOk={this.handleUpdPWdOk} onCancel={this.handleUpdCancel} destroyOnClose={true}>
                    <Form {...formItemLayout} className={'model-form'}>
                    
                    <Row gutter={24}>
                        <Col span={23}>
                            <FormItem label="新密码">
                                {getFieldDecorator('newPassword', {
                                    initialValue: undefined,
                                    // rules: [
                                    //     {
                                    //         required: true,
                                    //         message: '请输入新密码'
                                    //     }
                                    // ]
                                })(<Input maxLength={15} />)}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                </Form>
                </Modal>

            </div>
        )
    }

}

export default Form.create({})(withRouter(UserLists))
