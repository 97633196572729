import $axios from '@/axios/axios.js'

export function panicLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou`,
        method:'post',
        data
    })
}

export function panicDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/detail`,
        method:'post',
        data
    })
}

export function panicDel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/delete`,
        method:'post',
        data
    })
}

export function panicSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/save`,
        method:'post',
        data
    })
}


export function setStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/setStatus`,
        method:'post',
        data
    })
}

export function flowZone(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/flowZone`,
        method:'post',
        data
    })
}

export function flowZoneMove(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/move`,
        method:'post',
        data
    })
}