import $axios from '@/axios/axios.js'

export function orderLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/lists`,
        method:'post',
        data
    })
}
export function orderExport(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/export`,
        method:'get',
        responseType:'blob',
        params
    })
}



export function orderDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/detail`,
        method:'post',
        data
    })
}

export function acceptRefund(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/refund`,
        method:'post',
        data
    })
}

export function refuseRefund(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/refuseRefund`,
        method:'post',
        data
    })
}

export function pickUpLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/useCodeList`,
        method:'post',
        data
    })
}

export function searchPickUp(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/useCodeDetail`,
        method:'post',
        data
    })
}

export function pickUpCode(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/useCode`,
        method:'post',
        data
    })
}

export function expressLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/express`,
        method:'post',
        data
    })
}

export function deliverTrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/deliver`,
        method:'post',
        data
    })
}


export function addRemark(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/addRemark`,
        method:'post',
        data
    })
}

export function maxRefundMoney(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/goodsMaxRefundMoney`,
        method:'post',
        data
    })
}

export function orderPartRefund(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/scanOrderPartRefund`,
        method:'post',
        data
    })
}

export function orderPrint(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/printTicket`,
        method:'post',
        data
    })
}

export function bookingOrder(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/bookingOrder`,
        method:'post',
        data
    })
}

export function exportBookingOrder(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/exportBookingOrder`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function exportSpaceOrder(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/exportSpaceOrder`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function AcceptOrder(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/acceptTrade`,
        method:'post',
        data
    })
}

export function verifyTimesCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/verifyTimeCard`,
        method:'post',
        data
    })
}

export function courseTrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/courseTrade`,
        method:'post',
        data
    })
}

export function courseOrderExport(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/exportCourseOrder`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function tiktokOrderRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/doudianTrade/exchangeRecord`,
        method:'post',
        data
    })
}

//修改订单地址
export function modifyAddress(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/modifyAddress`,
        method:'post',
        data
    })
}

//抢购订单列表
export function qiangGouTradeList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/qiangGouTrade`,
        method:'post',
        data
    })
}

//取消订单
export function cancelTrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/cancelTrade`,
        method:'post',
        data
    })
}

//确认订单
export function confirmPay(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/confirmPay`,
        method:'post',
        data
    })
}

//确认上架
export function confirmShelve(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/confirmShelve`,
        method:'post',
        data
    })
}


