import React from 'react'
import { Form, Tabs, Checkbox, Input, Radio, Button, Switch } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Editor from '@/components/Editor/Editor'
import {getConfig,setConfig} from '@/api/config'
const FormItem = Form.Item;

class SignSetting extends React.Component {
    state = {
        isLoading: false,
        showEditor: false,
        formData: {
            is_switch:0,
            name:'用户协议',
            content:''
        }
    }

    componentDidMount() {
       this.fetchData()
    }

    fetchData(){
        this.setState({isLoading:true})
        getConfig({key:'sign_setting'}).then(res=>{
            if(res.code==1){
                let {value}=res.data.config;
                if(value){
                    value=JSON.parse(value)
                    this.setState({
                        formData:value
                    })
                }
            }
            this.setState({
                showEditor: true
            })
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }

    saveData=()=>{
        let sendData = {key:'sign_setting'},
        formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                values.is_switch=values.is_switch?1:0;

                sendData['value'] = {...formData, ...values};
               
                this.setState({isLoading:true})
                setConfig(sendData).then(res=>{
                    if(res.code==1){
                        message.success('保存成功')
                    }

                }).finally(()=>{
                    this.setState({
                        isLoading:false
                    })
                })

            }
        })
    }


    render() {
        let data = this.state.formData || {};
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 15
            }
        }

        return (
            <div className={`shadow-radius`}>

                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>用户注册协议</div>
                    <FormItem label={'是否启用协议'}>
                        {getFieldDecorator('is_switch', {
                            initialValue: !!data.is_switch ,
                            valuePropName: 'checked'
                        })(
                            <Switch />
                        )}
                       
                    </FormItem>

                    <FormItem label={'协议标题'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || '',
                            rules: [
                                {
                                    required: true,
                                    message: '请输入协议标题'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入协议标题'}
                            maxLength={10}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/10</span>}
                        />)}
                    </FormItem>

                    <FormItem label={'协议内容'}>
                        {
                            this.state.showEditor
                                ?
                                <Editor id={'text'} value={data.content} callback={content => this.EditorChange(content)}></Editor>
                                :
                                null
                        }
                    </FormItem>


                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>
                </Form>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}

export default Form.create({})(withRouter(SignSetting))
