import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import '@/assets/css/miniprogram/user-center.scss'

const FormItem = Form.Item

class UserStyle3 extends React.Component {
    state = {}

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {

    }

    render() {
        let data = this.props.current || {}

        return (
            <div className={`user-style3`}>
                <div className='mine-cont'>
                    <div className={`mine-head ${data.showMemberCard ? '' : ''}`} style={{ backgroundImage: `url(${data.bgImg || 'https://img.cxkoo.com/chengxuan/1/2024-03-02/f9fb4e124f026e730a5f8719ead4b5ea.png'})` }}>

                        <div className="head-cont">
                            <div className="user-cont flex justify-between align-center">
                                <div className="user-info">
                                    <div className="avatar"></div>
                                    <div className="info-desc">
                                        <div className="nick-name">登录/注册</div>
                                        
                                    </div>
                                </div>
                                <div className="right"></div>
                            </div>
                            <div >
                                <div className="member-card">
                                    <div className="txt1"></div>
                                    <div className="txt2">拥有身份，享受特权</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mine-content">
                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title cx-panel-title2">
                                <div className="left"></div>
                                <div className="right">
                                    <div className="txt">全部</div>
                                    <div className="arrow"></div>
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-between align-center">
                                <div className="point-item">
                                    <div className="point-img"></div>
                                    <div className="point-num">0</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon1">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待付款</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon2">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待发货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon3">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待收货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon5">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">退款/售后</div>
                                </div>
                            </div>
                        </div>

                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">快捷服务</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-start align-center flex-wrap">
                                {
                                    data.userCenter && data.userCenter.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="tool-item" key={item.id}>
                                                <img src={item.icon}></img>
                                                <div className="txt">{item.name}</div>
                                            </div>
                                            :
                                            null
                                    ))
                                }
                            </div>
                        </div>

                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">更多工具</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-quick-items">
                                {
                                    data.myContent && data.myContent.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="quick-item" key={index}>
                                                <div className="left">
                                                    <div className="icon"
                                                        style={{ backgroundImage: `url(${item.icon})` }}></div>
                                                    <div className="txt">{item.name}</div>
                                                </div>
                                                <div className="right">
                                                    <div className="arrow"></div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    ))
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserStyle3))

