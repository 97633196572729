import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Drawer, Icon, message } from 'antd'
import { signature } from '@/api/user.js'
import CX from '@/utils/CX.js'
const FormItem = Form.Item;

class SignLists extends Component {

    state = {
        columns: [
            {
                title: '客户ID',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.user_id ? rowData.user_id:'-'}</p>
                    )
                }
            },
            {
                title: '昵称',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name ? rowData.name:'-'}</p>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.mobile ? rowData.mobile:'-'}</p>
                    )
                }
            },
            {
                title: '审核人手机号',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.audit_info ? rowData.audit_info.mobile:'-'}</p>
                    )
                }
            },
            {
                title: '审核人昵称',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.audit_info ? rowData.audit_info.name:'-'}</p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? 'green' : rowData.status == 2 ? 'red' : ''}> {rowData.status == 0 ? '待审核' : rowData.status == 1 ? '审核通过' : '已拒绝'}</Tag>
                    )
                }
            },
            {
                title: '审核意见',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.audit_remark ? rowData.audit_remark:'-'}</p>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at ? rowData.created_at:'-'}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '9',
                width: '180px',
                render: (text, rowData, index) => {

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDownload(rowData)}}>下载注册协议</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>详情</Button>
                        </div>
                    )
                }
            }
        ],
        searchForm: {
            mobile: "",
        },
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
       

        this.setState({
            tableLoading: true
        })
        signature(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }


    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/sign/detail/${rowData.id}`
        })
    }


    handleDownload  = rowData => {
        let url;
        if(rowData.img_url){
            url = rowData.img_url
        }else{
            url = 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        }
        CX.downloadImage(url,'注册协议')
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

   

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }
  

    handleSetting = () => {
        this.props.history.push({
            pathname: '/sign/setting'
        })
    }

    handleSettingUser= () => {
        this.props.history.push({
            pathname: '/sign/setting-user'
        })
    }
  

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户手机号'} className={'fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号搜索' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem className={'fl mgl20'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>

                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleSetting} >设置寄售协议</Button>
                    <Button type={'primary'} className='mgl20' onClick={this.handleSettingUser} >设置用户服务协议</Button>
                </div>
               
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
              
            </div>
        )
    }
}

export default Form.create({})(withRouter(SignLists))