import React from 'react'
import { Form, Input, Button, Select, Table, Pagination,Switch } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import '@/assets/css/system/index.scss'
const FormItem = Form.Item;

class PaySet extends React.Component{
    state = {
        searchForm:{
            id:'',
            name:''
        },


    }

    componentDidMount() {
        //this.fetchData()
    }
    fetchData = () => {
    }
    handleSwitch = () => {}
    handleAdd = () => {
        this.props.history.push({
            pathname:'/system/add-payset'
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={`content-msg`}>请选择您要配置的收款方式进行配置</div>
                <div className={`flex justify-start align-center mgt15`}>
                    <div className={`cx-card`}>
                        <div className={`title flex justify-between align-center`}>
                            <div className={`title-txt`}>
                                <div className={`txt1`}>微信公众号</div>
                                <div className={`txt2`}>消费者在H5内进行付款</div>
                            </div>
                            <div className={`title-img wechat`}></div>
                        </div>
                        <div className={`card-btn-group flex justify-end align-center`}>
                            <Button type={`default`} onClick={ this.handleAdd }>配置</Button>
                        </div>
                    </div>
                </div>
                {/*<div className={`label-title mgt15`}>小程序端支付条件设置</div>*/}
                {/*<Form className={``}>*/}
                    {/*<FormItem>*/}
                        {/*<div>*/}
                            {/*<span className={`switch-title mgr20`}>小程序端消费允许使用储值卡余额付款</span>*/}
                            {/*<Switch checked={true} onChange={ this.handleSwitch } />*/}
                        {/*</div>*/}
                        {/*<span className={`fi-help-text`}>默认开启状态，关闭后，消费者将不能使用余额支付</span>*/}
                    {/*</FormItem>*/}
                    {/*<FormItem>*/}
                        {/*<div>*/}
                            {/*<span className={`switch-title mgr20`}>自助买单允许使用优惠券</span>*/}
                            {/*<Switch checked={false} onChange={ this.handleSwitch } />*/}
                        {/*</div>*/}
                        {/*<span className={`fi-help-text`}>默认关闭状态，开启后，可在小程序自助买单内使用优惠券（不包含兑换券）抵扣</span>*/}
                    {/*</FormItem>*/}
                    {/*<FormItem>*/}
                        {/*<div>*/}
                            {/*<span className={`switch-title mgr20`}>自助买单允许使用会员等级折扣</span>*/}
                            {/*<Switch checked={false} onChange={ this.handleSwitch } />*/}
                        {/*</div>*/}
                        {/*<span className={`fi-help-text`}>默认关闭状态，开启后，可在小程序自助买单内使用会员等级折扣</span>*/}
                    {/*</FormItem>*/}
                {/*</Form>*/}
            </div>
        )
    }

}

export default Form.create({})(withRouter(PaySet))

