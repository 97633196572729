import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider, Tabs, Select } from 'antd'
import { withRouter } from 'react-router-dom'
import { cateLists, pointMallLists, pointMallDelete, pointMallStatus, suitStores  } from '@/api/point-mall'
import CateManage from './components/cate-manage'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
import PointMallConfig from './components/point-mall-config'
import CouponFitStore from '@/views/coupon/components/CouponFitStore'

const FormItem = Form.Item;
const { TabPane } = Tabs
class PointMall extends React.Component{
    state = {
        columns:[
            {
                title:'积分商品信息',
                dataIndex:'prize_name',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.prize_name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'积分商品类型',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>
                                {
                                    rowData.type == 1
                                        ?
                                        '商品'
                                        :
                                        '优惠券'
                                }
                            </p>
                        </div>
                    )
                }
            },
            {
                title:'分类',
                dataIndex:'category_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.category_name || '--'}</p>
                    )
                }
            },
            {
                title:'积分价格',
                dataIndex:'show_points',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.show_points || '--'}</p>
                    )
                }
            },
            {
                title:'可兑换/已兑换数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.total_stock}/{rowData.real_sales}</p>
                    )
                }
            },
            // {
            //     title:'可兑换门店',
            //     dataIndex:'store',
            //     render:(text, rowData, index) => {
            //         return (
            //             <div className={`lh25`}>
            //                 {
            //                     rowData.suit_store_type == 1
            //                         ?
            //                         '全部'
            //                         :
            //                         <span className={`fi-link`} onClick={ () => { this.handleSuitStore(rowData) } }>{ rowData.suit_store_name }</span>
            //                 }
            //             </div>
            //         )
            //     }
            // },
            {
                title:'每人限兑',
                dataIndex:'limit',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.limit_type == 1 ? '不限' : rowData.limit_num }</p>
                    )
                }
            },
            {
                title:'排序',
                dataIndex:'sort',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.sort || '--'}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`green`}>可兑换</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <Tag color={``}>已下架</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 1
                                    ?
                                    <span>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData)}}>下架</Button>
                                        <Divider type={`vertical`}></Divider>
                                    </span>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <span>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleStatus(rowData)}}>上架</Button>
                                        <Divider type={`vertical`}></Divider>
                                    </span>
                                    :
                                    null
                            }
                            <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>推广</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:'',
            type:'',
            category_id:''
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:'',
        activeStatus:'0',
        tabLists:[
            {
                name:'全部',
                status:'0',
            },
            {
                name:'可兑换',
                status:'1',
            },
            {
                name:'已下架',
                status:'2',
            }
        ],
        cateLists:[],
        suitStores:[]


    }

    componentDidMount() {
        this.fetchData()
        this.fetchCate()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            tableLoading:true
        })
        pointMallLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.mall_list.data,
                        total = res.data.mall_list.total,
                        per_page = res.data.mall_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = 1000;
        this.setState({
            tableLoading:true
        })
        cateLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.category_list.data;
                    this.setState({
                        cateLists:lists,
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:'/point-mall/add-point-goods'
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleConfig = () => {
        this.PointMallConfig.show(res => {

        })
    }
    clickTab = val => {
        this.setState({
            activeStatus:val,
        },() => {
            this.fetchData()
        })
    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleCate = () => {
        this.CateManage.show()
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/point-mall/add-point-goods/${rowData.id}`
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleDetail = rowData => {
        this.props.history.push({
            pathname:`/time-discount/detail/${rowData.id}`
        })
    }

    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`是否删除该积分商品？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pointMallDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleStatus = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.status = rowData.status == 1 ? 2 : 1;
        Modal.confirm({
            title:`是否立即${rowData.status == 1 ? '下架' : '上架'}该积分商品？`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pointMallStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/pointMall/point-item-detail?id=${row.id}`
        })
        this.ShareModal.show()
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal
                    onRef={e => { this.ShareModal = e }}
                    poster={ this.state.poster }
                    path={ this.state.path }
                ></ShareModal>
                <CateManage onRef={ e => { this.CateManage = e } }></CateManage>
                <PointMallConfig onRef={ e => { this.PointMallConfig = e } }></PointMallConfig>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'积分商品名'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索活动名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'积分商品类型'} className={'mgl20 fl'}>
                            {
                                getFieldDecorator('type',{
                                    initialValue: 0
                                })(
                                    <Select style={{width:170}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        <Select.Option value={1}>商品</Select.Option>
                                        <Select.Option value={2}>优惠券</Select.Option>

                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem label={'分类'} className={'mgl20 fl'}>
                            {
                                getFieldDecorator('category_id',{
                                    initialValue: 0
                                })(
                                    <Select style={{width:170}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        {
                                            this.state.cateLists.map(item => (
                                                <Select.Option value={item.id} key={item.id}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增积分商品</Button>
                    <Button type={'default'} onClick={this.handleCate}  className={`mgl10`}>分类管理</Button>
                    <Button type={'default'} onClick={this.handleConfig}  className={`mgl10`}>积分商城设置</Button>
                </div>
                <div className={``}>
                    <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                        {
                            this.state.tabLists.map((item,index) => (
                                <TabPane tab={ item.name } key={item.status}></TabPane>
                            ))
                        }
                    </Tabs>
                </div>
                <div className={'content-body '}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(PointMall))
