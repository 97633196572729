import React from 'react'
import { connect } from 'react-redux'
import { setShop, removeShop } from '@/redux/actions/shop'
import { setCollapse, setTheme, setBreadCrumb, setHelpCenter } from '@/redux/actions/setting'
import { setMenu } from '@/redux/actions/sideMenu'
import { routes } from '@/router/routes';
import store from '@/redux/store'
import { withRouter, Link } from 'react-router-dom';
import  jsonData from '@/router/menu.json'

class FirstSideBar extends React.Component{
    state = {}
    componentDidMount() {
        this.renderNavMenus((menu) =>  {
            this.props.setMenu(menu.subMenu)
        });
        this.props.history.listen(() => {
            this.renderNavMenus((menu) => {
                this.props.setMenu(menu.subMenu)

            })
        })
    }

    toggle = () => {
        this.props.setCollapse({ isCollapsed : !this.props.collapse.isCollapsed })
    };
    renderNavMenus = (callback) => {
        let currentPath = this.props.history.location.pathname
        const menus = localStorage.getItem('shopMenu') ? JSON.parse(localStorage.getItem('shopMenu')) : []
        // const menus=jsonData;
        
        menus.forEach(menu => {
            menu.selected = false;
            menu.subMenu && menu.subMenu.forEach(child => {
                if(child.children){
                    child.children.forEach(val => {
                        let matchPath = val.path.split('/:')[0]
                        if(currentPath.includes(matchPath)){
                            menu.selected = true
                            if(typeof callback == 'function'){
                                callback(menu)
                            }
                        }
                    })
                }else {
                    let matchPath = child.path.split('/:')[0]
                    if(currentPath.includes(matchPath)){
                        menu.selected = true
                        if(typeof callback == 'function'){
                            callback(menu)
                        }
                    }
                }
            })
        })
        return menus.map( (item,index) => {
            return(
                <li className={`app-item ${item.selected ? 'active' : ''}`} key={index}>
                    <Link to={item.path} className={`app-item-link`}>
                        <span className={`icon ${item.icon}`}></span>
                        <span className={`app-item-txt`}>{item.title}</span>
                    </Link>
                </li>
            )
        })
    };
    render() {
        return(
            <aside className={`side-app-launcher`}>
                <div className={`logo-wrapper`}>
                    {/* <img src={`https://img.cxkoo.com/chengxuan/1/2022-12-07/d5f7cf81efd3e04858048134701bcd85.png`} alt=""/> */}
                </div>
                <div className={`apps-wrapper`}>
                    <ul className={`app-items`}>
                        { this.renderNavMenus() }
                    </ul>
                </div>
            </aside>
        )
    }

}
const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
    setCollapse : data => {
        dispatch(setCollapse(data));
    },
    setTheme : data => {
        dispatch(setTheme(data));
    },
    setBreadCrumb: data => {
        dispatch(setBreadCrumb(data));
    },
    setMenu:data => {
        dispatch(setMenu(data))
    }
})
export default withRouter( connect(mapStateToProps,mapDispatchToProps)(FirstSideBar))
