import $axios from '@/axios/axios.js'

export function itemLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/list`,
        method:'post',
        data
    })
}

export function skuItemLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/goods_list`,
        method:'post',
        data
    })
}


export function itemAdd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/create`,
        method:'post',
        data
    })
}

export function itemDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/delete`,
        method:'post',
        data
    })
}

export function itemDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/detail`,
        method:'post',
        data
    })
}

export function itemEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/edit`,
        method:'post',
        data
    })
}


export function itemCates(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods-category/list`,
        method:'post',
        data
    })
}

export function addCate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods-category/create`,
        method:'post',
        data
    })
}

export function cateDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods-category/detail`,
        method:'post',
        data
    })
}

export function editCate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods-category/edit`,
        method:'post',
        data
    })
}

export function deleteCate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods-category/delete`,
        method:'post',
        data
    })
}

export function addNorms(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/sku/ajax_norms`,
        method:'post',
        data
    })
}

export function addProps(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/sku/ajax_props`,
        method:'post',
        data
    })
}

export function itemStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/setStatus`,
        method:'post',
        data
    })
}

export function setField(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/setField`,
        method:'post',
        data
    })
}


export function itemImport(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/import`,
        method:'post',
        headers:{'Content-Type':'multipart/form-data'},
        data
    })
}

export function itemSetCates(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/setCategory`,
        method:'post',
        data
    })
}

export function marketItemLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/selectGoods`,
        method:'post',
        data
    })
}

export function qianggouItemLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/goods/qianggouGoods`,
        method:'post',
        data
    })
}

export function selectGoods(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/qianggou/selectGoods`,
        method:'post',
        data
    })
}




