import React from 'react'
import { Icon, Dropdown, Menu, Input, Select ,notification} from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAdmin, removeAdmin } from '@/redux/actions/admin'
import { setShop, removeShop } from '@/redux/actions/shop'
import { setMenu, removeMenu } from '@/redux/actions/sideMenu'
import { adminDetail } from '@/api/admin'
import ChangePwd from '@/components/changePwd/changePwd'
import Cookie from 'js-cookie'
import CX from '@/utils/CX'

class Header extends React.Component {
    state = {
        search: undefined,
        search_list: [],
        menuList: []
    }
    componentDidMount() {
        this.fetchData()
        this.handleMenu()
    }
    fetchData = () => {
        let sendData = {},
            admin = this.props.admin;
        if (!admin) {
            adminDetail(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let admin = res.data.admin,
                            shop = res.data.shop,
                            mp_trade_notice = res.data.mp_trade_notice || '',
                            admin_effect_notice=res.data.admin_effect_notice,
                            uploadInfo=res.data.uploadInfo || {};
                        this.props.setShop(shop);
                        this.props.setAdmin(admin);
                        localStorage.setItem('ali_oss', JSON.stringify(uploadInfo));
                        if (mp_trade_notice == 1) {
                            CX.openSocket()
                        } else {
                            CX.closeSocket()
                        }

                        if(admin_effect_notice){
                            notification.info({
                                message: '您的账户即将过期！',
                                description:`有效期：${shop.end_date}，为了不影响您的正常使用，请及时续费！`,
                                duration:10
                            })
                        }
                    }
                })
        }
    }
    handleLogout = () => {
        Cookie.remove('token')
        this.props.removeAdmin();
        this.props.removeShop();
        this.props.removeMenu();
        localStorage.removeItem('shopMenu')
        CX.closeSocket();
        const url=window.location.origin
        window.location.replace(url)
        // this.props.history.push('/login')

    }
    handlePwd = () => {
        this.changePwd.show(res => {
            this.fetchData();
        })
    }

    handleMenu = () => {
        let shopMenu = window.localStorage.getItem("shopMenu"), menu = []
        if (shopMenu) {
            shopMenu = JSON.parse(shopMenu)
        } else {
            return
        }
        shopMenu.forEach((item, index) => {
            item.subMenu && item.subMenu.forEach((val, i) => {
                if (val.children) {
                    menu.push({ title: val.title, path: val.children[0] && val.children[0].path })
                } else {
                    menu.push({ title: val.title, path: val.path })
                }
                val.children && val.children.forEach((child, j) => {
                    if(!child.hide){
                        menu.push({ title: child.title, path: child.path })
                    }
                    
                })
            })

        })
        this.setState({
            menuList: menu
        })

    }
    handleSearch = value => {
        let menu=[]
        value=value.trim()
        if (value) {
            
            menu=this.state.menuList.filter(val => {
                return val.title.includes(value)
            })
        }

        this.setState({
            search_list:menu
        })

    }

    handleChange=val=>{
        
        let path=val.split("&")[0]
        this.props.history.push({
            pathname:path
        })
    }

    render() {
        const DropdownList = (
            <Menu className="">
                <Menu.Item key="role" onClick={this.handlePwd}>
                    <Icon type="lock" />
                    修改密码
                </Menu.Item>
                <Menu.Item key="logout" onClick={this.handleLogout}>
                    <Icon type="logout" />
                    退出登录
                </Menu.Item>
                {/*<Divider></Divider>*/}
               
            </Menu>
        );

        const options = this.state.search_list.map((d,i) => <Select.Option value={d.path+"&"+i } key={i+''}>{d.title}</Select.Option>);
        return (
            <div className={`global-header`}>
                <ChangePwd
                    onRef={e => { this.changePwd = e }}
                    admin={this.props.admin}
                ></ChangePwd>
                <div className={`left`}></div>
                <div className={`right user-wrapper flex flex-end justify-center`}>
                    {/* <div className={`top-search`}>

                        <Select
                            showSearch
                            value={this.state.search}
                            placeholder={`搜索`}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            notFoundContent={null}
                            className={`top-search-input`}
                            style={{ width: 200 }}
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                        >
                            {options}
                        </Select>
                    </div> */}
                    <div className='dropdown-wrap' id='dropdown-wrap' style={{ cursor: 'pointer' }}>
                        <Dropdown getPopupContainer={() => document.getElementById('dropdown-wrap')} overlay={DropdownList}>
                            <div style={{ fontSize: '14px' }}>
                                {this.props.admin ? `欢迎您，${ this.props.admin.name}` : ``}
                                <Icon style={{ fontSize: '13px', cursor: 'pointer', marginLeft: '6px' }} type="caret-down" />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
    setAdmin: data => {
        dispatch(setAdmin(data))
    },
    removeAdmin: () => {
        dispatch(removeAdmin())
    },
    setShop: data => {
        dispatch(setShop(data))
    },
    removeShop: () => {
        dispatch(removeShop())
    },
    removeMenu: () => {
        dispatch(removeMenu())
    },
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

