import React, { Component } from 'react'
import { Button, Input, Checkbox } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import { setAdmin } from '@/redux/actions/admin'
import { setShop } from '@/redux/actions/shop'
import { connect } from 'react-redux'
import { login, getCaptcha  } from '@/api/login'
import Cookie from 'js-cookie'
import CX from '@/utils/CX'
import '@/assets/css/login.scss'

class Login extends Component {
    state = {
        isLoading:false,
        verifyImg:'',
        mobile:'',
        password:'',
        code:'',
        imageKey:'',
        pwdType:'text',
        userKey:1,
    }
    componentDidMount() {

        this.updateVerify()
        this.setState({
            pwdType:'password'
        })

    }

    updateVerify = () => {
        let sendData = {}
        getCaptcha(sendData)
            .then(res => {
                console.log(res)
                if(res.code == 1){
                    let imageKey = res.data.key,
                        verifyImg = res.data.img;
                    this.setState({
                        imageKey, verifyImg
                    })
                }
            })
    }
    login = e => {
        let sendData = {
            mobile:this.state.mobile,
            password:this.state.password,
            img_code:this.state.code,
            _k:this.state.imageKey,
            login_role:this.state.userKey
        }
        this.setState({
            isLoading:true
        })
        login(sendData)
            .then(res => {
                if(res.code == 1){
                    let admin = res.data.admin,
                        shop = res.data.shop,
                        menu_rules=res.data.menu_rules,
                        mp_trade_notice = res.data.mp_trade_notice || '',
                        allMenu = res.data.menu || [],
                        add_auths = shop?.add_auths || [],
                        rules = shop.version?.rules || [],
                        shopAuth = [],
                        token = admin.api_token || ``,
                        staff_id=admin.staff_id,
                        sub_rules=menu_rules?.rules,
                        index_path=`/dashboard`
                    // shopAuth = CX.diff(add_auths,rules);
                    if(staff_id > 0){
                        shopAuth=sub_rules

                        allMenu = allMenu.filter( x => shopAuth.indexOf(x.menu_id) > -1 )
                        allMenu.forEach((item,index) => {
                            item.subMenu = item.subMenu.filter( sub => shopAuth.indexOf(sub.menu_id) > -1 )
                            item.subMenu && item.subMenu.forEach( (child,childKey) => {
                                if(child.children && child.children.length){
                                    let newChildren = child.children.filter( val => shopAuth.indexOf(val.menu_id) > -1 )
                                    let hideChildren=child.children.filter( val => val.hide )
                                    let newHide=hideChildren.filter(val => newChildren.findIndex(f=>f.path==val.selectedPath) != -1 )
    
                                    child.children=[...newChildren,...newHide]
                                }
                            })
                        })
                    }
                   
                    
                  
                    localStorage.setItem(`shopMenu`,JSON.stringify(allMenu))
                    Cookie.set('token',token);
                    // this.props.setAdmin(admin);
                    this.props.setShop(shop);
                   
                   
                    if(allMenu.length>0){
                        let menu=allMenu[0].subMenu[0]
                        if(menu.children){
                            index_path=menu.children[0].path
                        }else{
                            index_path=menu.path
                        }
                    }

                    message.success('登录成功')
                        .then(() => {
                            // this.props.history.push({
                            //     pathname:index_path
                            // })
                           window.location.replace(window.location.origin)
                        })
                    if(mp_trade_notice == 1){
                        CX.openSocket()
                    }else {
                        CX.closeSocket()
                    }

                }else {
                    this.setState({
                        isLoading:false
                    })
                    this.updateVerify()
                }
            })
            .catch(err => {
                this.updateVerify()
            })

    }
    getInputValue = e => {
       let key = e.currentTarget.dataset.name,
           value = e.target.value,
           datas = {};
       datas[key] = value;
       this.setState(datas)
    }
    handleChangeType = () => {
        let pwdType = this.state.pwdType;
        if(pwdType == 'text'){
            pwdType = 'password'
        }else {
            pwdType = 'text'
        }
        this.setState({
            pwdType
        })
    }
    handleLink = e => {
        let link = e.currentTarget.dataset.link || ``;
        link && this.props.history.push({
            pathname:link
        })
    }
    handleChangeUser(key){
        this.setState({
            userKey:key
        })
    }
    render() {
        return(
            <div className={`cx-common-login`}>
                <div className={`login-logo`}>
                    {/* <img src={`https://img.cxkoo.com/chengxuan/1/2022-10-27/9e15ea832931aa825e470dba620f45ec.png`} alt=""/> */}
                </div>
                <div className={`cx-login-slogan`}>
                    <div className={`txt2`} style={{fontWeight:'600'}}>
                        {/* 打造最合适您的<span className={`txt-color mgl5`}>Saas&nbsp;+&nbsp;运营方案</span> */}
                        欢迎登录
                    </div>
                </div>
                <div className={`cx-login-wrap`}>
                    <div className={`cx-login-inner`}>
                        <div className={`title cursor`}>
                            <span className={this.state.userKey==1?'':'off'} onClick={()=>{this.handleChangeUser(1)}} >商家登录</span>
                            <span className={this.state.userKey==2?'mgl40':'off mgl40'} onClick={()=>{this.handleChangeUser(2)}} >员工登录</span>
                        </div>
                        <div className={`form-cont`}>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>登录账号</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入手机号`}
                                        className={`form-input`}
                                        data-name={'mobile'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>登录密码</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`${ this.state.pwdType }`}
                                        placeholder={`输入密码`}
                                        className={`form-input`}
                                        data-name={'password'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                    <div className={`input-type-img mgl5`} onClick={ this.handleChangeType }>
                                        {
                                            this.state.pwdType == 'password'
                                                ?
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/e14f3dd41c6c0dd02f38b6145b737c20.png`} className={`eye-close`} alt=""/>
                                                :
                                                <img src={`https://img.cxkoo.com/chengxuan/3/2021-10-02/3ea5c81a33106884ba67f8a35eaf7079.png`} className={`eye-open`} alt=""/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`form-item`}>
                                <div className={`fi-name`}>安全验证</div>
                                <div className={`form-control`}>
                                    <Input
                                        type={`text`}
                                        placeholder={`输入验证码`}
                                        className={`form-input`}
                                        data-name={'code'}
                                        onChange={this.getInputValue}
                                        onPressEnter={this.login}
                                    />
                                    <img src={this.state.verifyImg} alt="" className={'verify'} onClick={this.updateVerify} />
                                </div>
                            </div>
                            <div className={`form-btn-group`}>
                                <Button type={`primary`} className={`btn-login`} onClick={this.login} loading={this.state.isLoading} >登录</Button>
                            </div>
                        </div>
                        <div className={`form-actions`}>
                            <div className={`left`}>
                                <Checkbox defaultChecked className={`login-check`}>3天内自动登录</Checkbox>
                            </div>
                            <div className={`right`}>
                                <div className={`txt`} onClick={ this.handleLink } data-link="/forget">忘记密码</div>
                                <div className={`txt`} onClick={ this.handleLink } data-link="/register">免费注册</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-layer">
                    {/* <p>Copyright © 2020-2022 cxshope.com All Rights Reserved</p>
                    <p>杭州澄瑄网络科技有限公司版权所有 浙ICP备2021021901号-2</p> */}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setAdmin: data => {
      dispatch(setAdmin(data))
    },
    setShop: data => {
        dispatch(setShop(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)( withRouter(Login))
