import $axios from '@/axios/axios.js'

export function identifyLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/identify`,
        method:'post',
        data
    })
}

export function identifySave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/identify/save`,
        method:'post',
        data
    })
}

export function identifyDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/identify/detail`,
        method:'post',
        data
    })
}

export function identifyDel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/identify/delete`,
        method:'post',
        data
    })
}