import React from 'react'
import { Form, Input, Button, Select, Table, Pagination,DatePicker,Drawer } from 'antd'
import { withRouter } from 'react-router-dom'
import { stockRecord } from '@/api/stock'
import { storeLists } from '@/api/store'
import moment from 'moment';
import Loading from '@/components/Loading'
import { STOCK_IN_TYPES, STOCK_OUT_TYPES } from '@/constants/stock'
import EditForm from './components/EditForm'
const FormItem = Form.Item;
const { RangePicker } = DatePicker
const STOCK_IN_TYPE_IDS = STOCK_IN_TYPES.map(item => { return item.id })
const STOCK_OUT_TYPE_IDS = STOCK_OUT_TYPES.map(item => { return item.id })

class StockManage extends React.Component{
    state = {
        columns:[
            {
                title:'单据号',
                dataIndex:'service_code',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.service_code || '--'}</p>
                    )
                }
            },
            // {
            //     title:'仓库/门店',
            //     dataIndex:'store_name',
            //     render:(text, rowData, index) => {
            //         return (
            //             <p>{rowData.store_name || '--'}</p>
            //         )
            //     }
            // },
            {
                title:'出入库类型',
                dataIndex:'type_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.type_name}</p>
                    )
                }
            },
            {
                title:'出入库数量',
                dataIndex:'stock2',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                STOCK_IN_TYPE_IDS.includes(rowData.type)
                                    ?
                                    <span className={`colorGreen`}>+{rowData.storage_total_num}</span>
                                    :
                                    null
                            }
                            {
                                STOCK_OUT_TYPE_IDS.includes(rowData.type)
                                    ?
                                    <span className={`colorRed`}>-{rowData.storage_total_num}</span>
                                    :
                                    null
                            }

                        </div>
                    )
                }
            },
            {
                title:'操作人员',
                dataIndex:'operater_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.operater_name || `--`}</p>
                    )
                }
            },
            {
                title:'备注',
                dataIndex:'remark',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.remark || `--`}</p>
                    )
                }
            },
            {
                title:'变动时间',
                dataIndex:'operate_time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.operate_time}</p>
                        </div>
                    )
                }
            },


            {
                title:'操作',
                dataIndex:'opts',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleDetail(rowData)}}>查看详情</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            // store_id:'',
            service_code:'',
            name:'',
            type:'',
            start_time:'',
            end_time:''
        },
        tableData:[],
        storeLists:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        drawerVisible:false,
        currentRow:null,

    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        stockRecord(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.records.data,
                        total = res.data.records.total,
                        per_page = res.data.records.per_page,
                        allStockTypes = [ ...STOCK_IN_TYPES,...STOCK_OUT_TYPES];
                    for(var i in lists){
                        for( var j in allStockTypes){
                            if(lists[i]['type'] == allStockTypes[j]['id'] ){
                                lists[i]['type_name'] = allStockTypes[j]['name']
                            }
                        }
                    }
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                delete searchForm.time;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = (type) => {
        this.props.history.push({
            pathname:`/stock/add/${type || 1}`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleDetail = rowData => {
        this.setState({
            drawerVisible:true,
            currentRow :rowData
        })
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form '}>
                        <div className={`clearfix`}>
                            {/* <FormItem label={'仓库/门店'} className={'fl'}>
                                {
                                    getFieldDecorator('store_id',{
                                        initialValue:0
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem> */}
                            <FormItem label={'商品名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称'/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'出入库类型'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('type',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                [ ...STOCK_IN_TYPES,...STOCK_OUT_TYPES].map(item => (
                                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'变动日期'} className={'fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem className={'mgl20 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>


                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={() => { this.handleAdd(1) }} icon={'plus'}>新增入库</Button>
                    <Button type={'default'} onClick={() => { this.handleAdd(2) }} icon={'plus'} className={`mgl10`}>新增出库</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.isLoading ? <Loading /> : null
                }
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`入库详情`}
                            closable={true}
                            width={`60%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={ this.handleDrawerClose }
                            headerStyle={{borderBottom:'0px'}}
                            bodyStyle={{padding:'0px'}}
                        >
                            <EditForm
                                onRef={e => { this.EditForm = e }}
                                record_id={ this.state.currentRow ? this.state.currentRow.id : '' }
                            ></EditForm>
                        </Drawer>
                        :
                        null
                }

            </div>
        )
    }

}

export default Form.create({})(withRouter(StockManage))
