import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import { storeLists } from '@/api/store'
import {  userCreate } from '@/api/user'

const FormItem = Form.Item;

 class CreateUser extends Component {
    state={
        visible:false,
        modalLoading:false,
        storeLists:[],
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }

    saveData=()=>{
        let  sendData = {},callback=this.state.callback
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
               
                sendData.birthday = values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : ''
                sendData.head_img_url='https://img.cxkoo.com/chengxuan/1/2021-10-13/eb909a3a165f33d59f136339eefb3c78.png'

                let pattern = /^1[0-9]{10}$/

                if (!pattern.test(sendData.mobile)) {
                    return message.error("请输入正确的手机号")
                }

                this.setState({
                    modalLoading: true
                })
                userCreate(sendData).then(res => {
                    message.success(res.msg)
                    if (res.code === 1) {
                       if(typeof callback == 'function'){
                         callback()
                       }
                    } 
                    
                    this.setState({
                        modalLoading: false,
                        visible:false
                    })
                })

            }
        })

    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form

        return (
            <Modal
            title={"新增客户"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={700}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form {...formItemLayout} className={'model-form'}>
                
                <Row gutter={24}>
                    <Col span={20}>
                        <FormItem label="姓名">
                            {getFieldDecorator('name', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名'
                                    }
                                ]
                            })(<Input maxLength={15} />)}
                        </FormItem>

                        <FormItem label="备注名">
                            {getFieldDecorator('second_name', {
                                initialValue: undefined,
                            })(<Input maxLength={15} />)}
                        </FormItem>

                        <FormItem label="手机号">
                            {getFieldDecorator('mobile', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入手机号'
                                    }
                                ]
                            })(<Input maxLength={11} type="tel" />)}
                            <span className={`fi-help-text`}>手机号为系统唯一标识，当此手机号的微信用户授权后会自动绑定</span>
                        </FormItem>
                        
                        <FormItem label="登录密码">
                            {getFieldDecorator('password', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入登录密码'
                                    }
                                ]
                            })(<Input.Password mixLength={8} />)}
                            <span className={`fi-help-text`}>手机号为系统唯一标识，当此手机号的微信用户授权后会自动绑定</span>
                        </FormItem>

                        <FormItem label={'生日'} >
                            {
                                getFieldDecorator('birthday', {
                                    initialValue: undefined,
                    
                                })(
                                    <DatePicker style={{width:'100%'}} placeholder='请选择生日' />
                                )
                            }
                        </FormItem>
                       

                        <FormItem label={'性别'} >
                            {
                                getFieldDecorator('sex', {
                                    initialValue: undefined,
                                })(
                                    <Select placeholder={'请选择'}   >
                                        <Select.Option value={1}>男</Select.Option>
                                        <Select.Option value={2}>女</Select.Option>
                                    </Select>
                                )
                            }
                        </FormItem>

                        {/* <FormItem label={'归属门店'} >
                            {
                                getFieldDecorator('store_id', {
                                    initialValue: 0
                                })(
                                    <Select placeholder={'请选择校区'}>
                                        <Select.Option value={0}  >总部</Select.Option>
                                        {
                                            this.state.storeLists.map(store => (
                                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem> */}
                       
                    </Col>
                    <Col span={4}>
                    </Col>
                </Row>
            </Form>

        </Modal>
        )
    }
}

export default Form.create({})(CreateUser)
