import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import '@/assets/css/dashboard/index.scss'
import { getSatistics, goodsRank , exportGoodsRank, userConsume, exportUserConsume } from '@/api/dashboard'
import { Button, Tooltip, Table ,Pagination } from 'antd'
import Loading from '@/components/Loading'
import moment from 'moment'
import Chart from '@/components/Chart/Chart'
import CX from '@/utils/CX'


class Index extends Component{
    state = {
        formData:{},
        tableDataOne:[],
        tableDataTwo:[],
        searchOneForm:{
            order_type:'',
            order_sort:''
        },
        searchTwoForm:{
            order_type:'',
            order_sort:''
        },
        chartData:{
            tooltip:{
                trigger:'axis',
            },
            legend:{
                data:['今日','昨日'],
                top:'20',
                right:'20'
            },
            grid: {
                left: '0%',
                right: '20',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '今日',
                    type: 'line',
                    stack: '今日',
                    data: [0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: '昨日',
                    type: 'line',
                    stack: '昨日',
                    data: [0, 0, 0, 0, 0, 0, 0]
                }
            ],
            color:['#7F64FF','#B6CFFF']
        },
        columnOne:[
            {
                title:'排名',
                dataIndex:'name',
                width:'80px',
                render:(text, rowData, index) => {
                    if(index<3){
                        return (
                            <p className={`lh25`} style={{backgroundColor:'blue',color:'#fff',borderRadius:'50%',width:'20px',height:'20px',textAlign:'center',lineHeight:'20px'}}>{ index+1 || ''}</p>
                        )
                    }else{
                        return (
                            <p className={`lh25`} style={{marginLeft:"7px"}}>{ index+1 || ''}</p>
                        )
                    }
                    
                }
            },
            {
                title:'商品',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || ''}</p>
                    )
                }
            },
            {
                title:'销售额',
                dataIndex:'pay_ment',
                width:140,
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.pay_ment || 0}</p>
                    )
                }
            },
            {
                title:'销量',
                dataIndex:'num',
                width:140,
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.num || 0}</p>
                    )
                }
            },
        ],
        columnTwo:[
            {
                title:'排名',
                dataIndex:'id',
                width:'80px',
                render:(text, rowData, index) => {
                    if(index<3){
                        return (
                            <p className={`lh25`} style={{backgroundColor:'blue',color:'#fff',borderRadius:'50%',width:'20px',height:'20px',textAlign:'center',lineHeight:'20px'}}>{ index+1 || ''}</p>
                        )
                    }else{
                        return (
                            <p className={`lh25`} style={{marginLeft:"7px"}}>{ index+1 || ''}</p>
                        )
                    }
                }
            },
            {
                title:'客户',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.name || ''}</p>
                    )
                }
            },
            {
                title:'支付金额',
                dataIndex:'consume_money',
                width:140,
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>¥ {rowData.consume_money || 0}</p>
                    )
                }
            },
            {
                title:'支付次数',
                dataIndex:'consume_times',
                width:140,
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.consume_times || 0}</p>
                    )
                }
            },
        ],
        page:1,
        total:0,
        per_page:10,
        pageTwo:1,
        totalTwo:0,
        per_pageTwo:10,
        isLoading:false
    }
    componentDidMount() {
        this.fetchData()
        this.fetchDataOne()
        this.fetchDataTwo()
    }
    fetchData = () => {
        let sendData = {}
        let chartData = this.state.chartData
        this.setState({
            isLoading:true
        })
        getSatistics(sendData)
            .then(res => {
                if(res.code == 1){
                    let formData = res.data.board,
                        chart = formData.money.echart;
                    chartData.xAxis.data = chart.today.times;
                    chartData.series[0]['data'] = chart.today.trade_money;
                    chartData.series[1]['data'] = chart.yesterday.trade_money;
                    this.setState({
                        formData,
                        chartData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }


    handleOneSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchOneForm = this.state.searchOneForm;
        if(order){
            if(field === "pay_ment"){
                searchOneForm.order_type = 1;
            }else{
                searchOneForm.order_type = 2;
            }
            searchOneForm.order_sort  = order == 'ascend' ? 'asc' : 'desc';
        }else {
            searchOneForm.order_type = '';
            searchOneForm.order_sort  = '';
        }
        this.setState({
            searchOneForm
        },() => {
            this.fetchDataOne()
        })
    }

    handleTwoSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchTwoForm = this.state.searchTwoForm;
        if(order){
            if(field === "consume_money"){
                searchTwoForm.order_type = 1;
            }else{
                searchTwoForm.order_type = 2;
            }
            searchTwoForm.order_sort = order == 'ascend' ? 'asc' : 'desc';
        }else {
            searchTwoForm.order_type = '';
            searchTwoForm.order_sort  = '';
        }
        this.setState({
            searchTwoForm
        },() => {
            this.fetchDataTwo()
        })
    }

    fetchDataOne = () => {
        let sendData = {};
        sendData = this.state.searchOneForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        goodsRank(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableDataOne:lists,
                        total,per_page,
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

    fetchDataTwo = () => {
        let sendData = {};
        sendData = this.state.searchTwoForm;
        sendData.page = this.state.pageTwo;
        this.setState({
            tableLoading:true
        })
        userConsume(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        totalTwo = res.data.list.total,
                        per_pageTwo = res.data.list.per_page;
                    this.setState({
                        tableDataTwo:lists,
                        totalTwo,per_pageTwo,
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }


    handleOneExport = () =>{
        let sendData = {}
        let searchForm = this.state.searchOneForm;
        sendData = searchForm;
        this.setState({
            searchForm,
            tableLoading:true
        })
        exportGoodsRank(sendData)
            .then(res => {
                let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                    fileName = '商品销售榜单.csv';
                CX.exportExcel(blob,fileName)

            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }


    handleTwoExport = () =>{
        let sendData = {}
        let searchForm = this.state.searchTwoForm;
        sendData = searchForm;
        this.setState({
            searchForm,
            tableLoading:true
        })
        exportUserConsume(sendData)
            .then(res => {
                let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                    fileName = '客户购买力榜单.csv';
                CX.exportExcel(blob,fileName)

            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

    handleLink = (e) => {
        let path = e.currentTarget.dataset.path || ``;
        this.props.history.push({
            pathname:path
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }

    handleTwoCurrentChange = val => {
        this.setState({
            pageTwo:val
        },() => {
            this.fetchDataTwo()
        })
    }
    
    render() {
        let formData = this.state.formData || {} ;
        return(
            <div className="dashboard-radius">
               <div className={`flex justify-start align-center`}>
                   <div className={`data-statistic`}>
                       <div className={`data-title`}>
                           <span className={`fi-title`}>每日待办事项</span>
                           <span className={`fi-sub-title mgl15`}></span>
                       </div>
                       <div className={`data-items-cont mgt30 pdb25`}>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_deliver : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/3">待发货订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_confirm : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/4">待确认订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_pay : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/1">待付款订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.apply_refund : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/6">待退款订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.goods_num : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/stock/stock">已售罄商品</div>
                           </div>
                       </div>
                   </div>
               </div>
                <div className={`data-statistic mgt15`}>
                    <div className={`data-title`}>
                        <span className={`fi-title`}>实时数据</span>
                        <span className={`fi-sub-title mgl15`}>更新时间：{ moment().format('YYYY-MM-DD HH:mm:ss') }</span>
                    </div>
                    <div className={`data-chart-cont mgt25`}>
                        <div className={`left`}>
                            <div className={`left-top`}>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>实际收款金额（元）</div>
                                        <Tooltip placement="top" title={`订单完成时间在统计时间内，微信的金额总和，统计时减去退款的金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.money ? formData.money.total_money : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.money ? formData.money.yesterday_money : 0 }</div>
                                </div>
                            </div>
                            <div className={`left-chart`}>
                                <Chart chartData={this.state.chartData} className={'line-chart'} height={'320px'}></Chart>
                            </div>
                        </div>
                        <div className={`right`}>
                            <div className={`right-items-cont`}>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>新增客户数（人）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过各种渠道新增的客户数量，包括门店手动添加、手动导入的新客户、自助注册的新客户`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.user_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.user_data.yesterday : 0 }</div>
                                </div>
                                {/* <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>新增会员数（人）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过各种渠道开通会员卡的客户数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.member_user_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.member_user_data.yesterday : 0 }</div>
                                </div> */}
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>支付订单数（笔）</div>
                                        <Tooltip placement="top" title={`统计时间内，支付成功的订单数（包含支付后退款、取消的订单）`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.pay_trade_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.pay_trade_data.yesterday : 0 }</div>
                                </div>

                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>订单收款金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，支付成功的订单数（包含支付后退款、取消的订单）`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.trade_payment_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.trade_payment_data.yesterday : 0 }</div>
                                </div>

                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>手续费金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，支付成功的订单数（包含支付后退款、取消的订单）`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.fee_payment_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.fee_payment_data.yesterday : 0 }</div>
                                </div>





                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>退款订单数（笔）</div>
                                        <Tooltip placement="top" title={`统计时间内，退款成功的订单数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.refund_trade_num_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.refund_trade_num_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>实际退款金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过微信支付原路退回的金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.refund_trade_payment_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.refund_trade_payment_data.yesterday : 0 }</div>
                                </div>
                                {/* <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>核销优惠券数（张）</div>
                                        <Tooltip placement="top" title={`统计时间内，客户核销优惠券的数量`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.coupon_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.coupon_data.yesterday : 0 }</div>
                                </div> */}
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>客单价（元）</div>
                                        <Tooltip placement="top" title={`1.即消费客单价，不包括开卡充值的数据；2.订单完成时间在统计时间内，平均每个客户带来的收入`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.single_price_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.single_price_data.yesterday : 0 }</div>
                                </div>
                                {/* <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>开卡充值金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，开卡充值金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.card_deposit_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.card_deposit_data.yesterday : 0 }</div>
                                </div> */}
                                {/* <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>页面浏览量（次）</div>
                                        <Tooltip placement="top" title={`统计时间内，页面被浏览的次数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.page_view_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.page_view_data.yesterday : 0 }</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`data-statistic mgt15`}>
                    <div className={`data-title`}>
                        <span className={`fi-title`}>常用功能</span>
                        <span className={`fi-sub-title mgl15`}></span>
                    </div>
                    <div className={`data-app-cont`}>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/item/add">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/4f3643f9d34f977d1562f42563592f4e.png" alt=""/>
                            <div className={`txt`}>新增商品</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/order/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/e09395a4b881780d220423bf99084ea7.png" alt=""/>
                            <div className={`txt`}>订单管理</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/design/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/a18260a8da40085f091dfb7767b524fc.png" alt=""/>
                            <div className={`txt`}>店铺装修</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/express/template">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/b04f4995b004e79582f68170f026ae9c.png" alt=""/>
                            <div className={`txt`}>运费模板</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/coupon/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/d1e6f766fd61b061d5ae5a7e123d2df2.png" alt=""/>
                            <div className={`txt`}>优惠券</div>
                        </div>
                    </div>
                </div>
                <div className={`mgt25 flex justify-between`}>
                    <div className={'flex1 data-statistic '}>
                        <div className={`data-title flex align-center justify-between`}>
                            <span className={`fi-title`}>商品销量排行榜</span>
                            <span className={`fi-sub-title mgl15`}></span>
                            <div>
                                <Button type={'primary'} onClick={() => {this.handleOneExport()}}>导出</Button>
                            </div>
                        </div>
                        <div className={'content-body mgt15'}>
                            <Table
                                size={`middle`}
                                columns={this.state.columnOne}
                                dataSource={this.state.tableDataOne}
                                loading={this.state.tableLoading}
                                rowKey={record => record.id}
                                pagination={false}
                                onChange={(pagination, filters, sorter) => { this.handleOneSort(pagination, filters, sorter) }}
                            ></Table>
                        </div>
                        <div className={'clearfix mgt10'}>
                            <div className={'fr'}>
                                {
                                    this.state.tableDataOne.length
                                        ?
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total}
                                                    pageSize={this.state.per_page} onChange={this.handleCurrentChange}/>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'flex1 mgl30 data-statistic '}>
                        <div className={`data-title flex align-center justify-between`}>
                            <span className={`fi-title`}>客户购买力排行榜</span>
                            <span className={`fi-sub-title mgl15`}></span>
                            <div>
                                <Button type={'primary'} onClick={() => {this.handleTwoExport()}}>导出</Button>
                            </div>
                        </div>
                        <div className={'content-body mgt15'}>
                            <Table
                                size={`middle`}
                                columns={this.state.columnTwo}
                                dataSource={this.state.tableDataTwo}
                                loading={this.state.tableLoading}
                                rowKey={record => record.id}
                                pagination={false}
                                onChange={(pagination, filters, sorter) => { this.handleTwoSort(pagination, filters, sorter) }}
                            ></Table>
                        </div>
                        <div className={'clearfix mgt10'}>
                            <div className={'fr'}>
                                {
                                    this.state.tableDataTwo.length
                                        ?
                                        <Pagination showQuickJumper current={this.state.pageTwo} total={this.state.totalTwo}
                                                    pageSize={this.state.per_pageTwo} onChange={this.handleTwoCurrentChange}/>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default withRouter(Index)
