import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Drawer, Icon, message } from 'antd'
import {addresslist} from '@/api/user.js'
const FormItem = Form.Item;

class UserAddress extends Component {

    state = {
        columns: [
            {
                title: '客户ID',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.user_id}</p>
                    )
                }
            },
            {
                title: '昵称',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name}</p>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.mobile}</p>
                    )
                }
            },
            {
                title: '收件人',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.contact_name}</p>
                    )
                }
            },
            {
                title: '联系电话',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.phone}</p>
                    )
                }
            },
            {
                title: '详细地址',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.address}</p>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            }
        ],
        searchForm: {
            search: "",
        },
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
       

        this.setState({
            tableLoading: true
        })
        addresslist(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }


    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

   

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }
  

    handleSetting = () => {
        this.props.history.push({
            pathname: '/sign/setting'
        })
    }
  

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户信息'} className={'fl'}>
                                {
                                    getFieldDecorator('search', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号/用户id/昵称搜索' style={{ width: '300px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem className={'fl mgl20'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>

                        </div>
                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
              
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserAddress))