import React from 'react'
import { Form, Input, Radio, DatePicker, Select, Tooltip, Icon, Checkbox, Button, Table, Pagination, Divider, Switch, TimePicker } from 'antd'
import { withRouter } from 'react-router-dom'
import { panicSave, panicDetail } from '@/api/panic-buying'
import { storeLists } from '@/api/store'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import { timeDiscountSave, timeDiscountDetail } from '@/api/time-discount'
import { getConfig, setConfig } from '@/api/config'
import { labelGroupList } from '@/api/user'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment'
import message from '@/utils/message'
import SetItemDiscount from './components/set-item-discount'
import MarketItemPicker from './components/market-item-picker'
import { array } from 'prop-types'
const FormItem = Form.Item;
const format = 'HH:mm';

class PanicBuyingSetting extends React.Component {
    state = {
        formData: {
            advertising_open: 0,
            cover_img: '',
            first_integral: 0,
            fans_integral: 0,
            auto_confirm: 0,
            price_up_ratio: 0,
            commission_ratio: 0,
            simple_num: 0,
            points_grab:0
        },
        isLoading: false,
        storeLists: [],
        tagsLists: [],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        currentItem: ''
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.key = 'panic_buying'
        this.setState({
            isLoading: true
        })
        getConfig({ key: 'panic_buying' })
            .then(res => {
                if (res.code == 1) {
                    let config = res.data.config
                    if (config) {
                        let formData = JSON.parse(config.value)
                        this.setState({
                            formData
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            // eslint-disable-next-line default-case
            switch (name) {
                case 'cover_img':
                    formData.cover_img = imgs[0];
                    break;
                case 'share_img':

                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }
    handleItem = () => {
        let tableData = this.state.tableData || []
        this.MarketItemPicker.show(res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }
                    })
                    lists.push(item)
                })
                this.setState({
                    tableData: lists
                })
            }
        })
    }


    onchangeStatus = async (checked) => {
        let formData = this.state.formData || {};
        if (checked === false) {
            formData.advertising_open = 0
        } else if (checked === true) {
            formData.advertising_open = 1
        }
        this.setState({
            formData
        })
    }

    onchangeOpen = async (checked) => {
        let formData = this.state.formData || {};
        if (checked === false) {
            formData.auto_confirm = 0
        } else if (checked === true) {
            formData.auto_confirm = 1
        }
        this.setState({
            formData
        })
    }

    handleEdit = (rowData, index) => {
        let sendData = {},
            tableData = this.state.tableData || [];
        for (var i in rowData['goods_extend']) {
            if (!rowData[i]) {
                rowData[i] = rowData['goods_extend'][i]
            }
        }
        delete rowData['goods_extend']
        this.setState({
            currentItem: rowData
        }, () => {
            this.SetItemDiscount.show(result => {
                if (result.item.sku_list && result.item.sku_list.length) {
                    let tmp_stock = 0,
                        sku_price_arr = []
                    result.item.sku_list.forEach((sku, skuKey) => {
                        if (sku.is_join) {
                            sku.stock = sku.stock ? Number(sku.stock) : 0
                            sku.sales_price = sku.sales_price ? sku.sales_price : 0
                            tmp_stock = tmp_stock + sku.stock;
                            if (parseFloat(sku.sales_price)) {
                                sku_price_arr.push(parseFloat(sku.sales_price))
                            } else {
                                sku_price_arr.push(0)
                            }
                        }
                    })
                    result.item.stock = tmp_stock;
                    if (sku_price_arr.length) {
                        if (Math.min(...sku_price_arr) == Math.max(...sku_price_arr)) {
                            result.item.sales_price = `${Math.max(...sku_price_arr).toFixed(2)}`

                        } else {
                            result.item.sales_price = `${Math.min(...sku_price_arr).toFixed(2)}~${Math.max(...sku_price_arr).toFixed(2)}`
                        }
                    }

                }
                rowData = result.item;
                rowData.sort = result.sort || ``;
                rowData.basic_sales = result.basic_sales || ``;
                rowData.basic_tobuy_num = result.basic_tobuy_num || ``;
                rowData.can_sale_stock = result.item.stock;
                rowData.show_price = result.item.sales_price;
                tableData[index] = rowData;
                this.setState({
                    tableData
                })
            })
        })
    }
    handleNextStep = () => {
        let formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for (var i in values) {
                    formData[i] = values[i]
                }
                formData.start_time = moment(formData.start_time).format('YYYY-MM-DD HH:mm:ss')
                formData.end_time = moment(formData.end_time).format('YYYY-MM-DD HH:mm:ss')
                if (values.label_ids) {
                    formData.label_ids = values.label_ids.join(',');
                }
                this.setState({
                    formData,
                    step: 2
                })
            }
        })

    }

    showCoupon = () => {
        let row;
        this.couponCreate.show(row, (res) => {

        })
    }

    handleCouponAdd = res => {
        console.log('子组件传递的值', res);
        let formData = this.state.formData;
        formData.goods = res
        this.setState({
            formData
        })
    }


    handlePrevStep = () => {
        this.setState({
            step: 1
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for (var i in values) {
                    formData[i] = values[i]
                }

                this.setState({
                    isLoading: true
                })
                sendData.key = 'panic_buying'
                formData.advertising_open = formData.advertising_open ? 1 : 0;
                formData.auto_confirm = formData.auto_confirm ? 1 : 0;

                sendData.value = formData

                setConfig(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('保存成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname: `/panic-buying/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }
    handleDelete = (rowData, index) => {
        let formData = this.state.formData;
        formData.goods.splice(index, 1);
        this.setState({
            formData
        })
    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 8
            }
        }

        return (
            <div className={`shadow-radius`}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <MarketItemPicker
                    onRef={e => { this.MarketItemPicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></MarketItemPicker>
                <SetItemDiscount
                    onRef={e => { this.SetItemDiscount = e }}
                    item={this.state.currentItem}
                ></SetItemDiscount>
                <Form {...formItemLayout} className={'model-form'}>
                    <div>
                        <div className={`msg-title mgb15`}>抢购活动设置</div>
                        <div className="flex">
                            <div className={`flex1`}>

                                <FormItem
                                    label={
                                        <span>
                                            开启广告位置入口
                                        </span>
                                    }
                                >
                                    {getFieldDecorator('advertising_open', {
                                        initialValue: data.advertising_open || 1,
                                    })(
                                        <Switch checked={data.advertising_open == 1 ? true : false} onChange={this.onchangeStatus}></Switch>
                                    )}
                                </FormItem>
                                <FormItem label={'背景图'} required={true}>
                                    <div className={`clearfix`}>
                                        {
                                            data.cover_img
                                                ?
                                                <PictureCard
                                                    onRef={e => { this.PictureCard = e }}
                                                    imgs={data.cover_img}
                                                    onDel={e => { this.delImg(e, 'cover_img') }}
                                                ></PictureCard>
                                                :
                                                <ImgAdd
                                                    onRef={e => { this.ImgAdd = e }}
                                                    onClick={e => { this.chooseImg(e, 'cover_img') }}
                                                ></ImgAdd>
                                        }
                                    </div>
                                    <span className={'fi-help-text'}>建议尺寸：710x260像素</span>
                                </FormItem>
                                <FormItem label={'无身份用户每场可抢单数'} required={true}>
                                    <div>
                                        {getFieldDecorator('simple_num', {
                                            initialValue: data.simple_num || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入抢单数'
                                                }
                                            ]
                                        })(<Input suffix="单" />)}
                                    </div>
                                    {/* <span className={`fi-help-text`}>默认为0分钟</span> */}
                                </FormItem>
                                <FormItem label={'每笔抢单赠送'} required={true}>
                                    <div>
                                        {getFieldDecorator('points_grab', {
                                            initialValue: data.points_grab || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入积分'
                                                }
                                            ]
                                        })(<Input suffix="积分" />)}
                                    </div>
                                    {/* <span className={`fi-help-text`}>默认为0分钟</span> */}
                                </FormItem>
                                <FormItem label={'首笔抢单赠送邀请人'} required={true}>
                                    <div>
                                        {getFieldDecorator('first_integral', {
                                            initialValue: data.first_integral || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入积分'
                                                }
                                            ]
                                        })(<Input suffix="积分" />)}
                                    </div>
                                    {/* <span className={`fi-help-text`}>默认为0分钟</span> */}
                                </FormItem>
                                <FormItem label={'粉丝每笔抢单赠送邀请人'} required={true}>
                                    <div>
                                        {getFieldDecorator('fans_integral', {
                                            initialValue: data.fans_integral || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入积分'
                                                }
                                            ]
                                        })(<Input suffix="积分" />)}
                                    </div>
                                    {/* <span className={`fi-help-text`}>默认为0分钟</span> */}
                                </FormItem>
                                <FormItem
                                    label={
                                        <span>
                                            抢购订单30分钟自动确认
                                        </span>
                                    }
                                >
                                    {getFieldDecorator('auto_confirm', {
                                        initialValue: data.auto_confirm || 1,
                                    })(
                                        <Switch checked={data.auto_confirm == 1 ? true : false} onChange={this.onchangeOpen}></Switch>
                                    )}
                                </FormItem>
                                <FormItem label={'抢购活动商品价格上调比例'} required={true}>
                                    <div>
                                        {getFieldDecorator('price_up_ratio', {
                                            initialValue: data.price_up_ratio || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入上调比例'
                                                }
                                            ]
                                        })(<Input type="number" min={1} suffix="%" />)}
                                    </div>
                                    <span className={`fi-help-text`}>我的卖单上架时最高上调价格比例</span>
                                </FormItem>
                                <FormItem label={'抢购活动商品委托费用比例'} required={true}>
                                    <div>
                                        {getFieldDecorator('commission_ratio', {
                                            initialValue: data.commission_ratio || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入费用比例'
                                                }
                                            ]
                                        })(<Input suffix="%" />)}
                                    </div>
                                    <span className={`fi-help-text`}>我的卖单上架时委托费用比例</span>
                                </FormItem>
                                <div className={`txtCenter`}>
                                    <Button type={`primary`} onClick={this.saveData}>保存</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}

export default Form.create({})(withRouter(PanicBuyingSetting))

