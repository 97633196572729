import React from 'react'
import  jsonData from '@/router/menu.json'
import { withRouter, Link } from 'react-router-dom'
import { Breadcrumb, Icon } from 'antd'
const menus = localStorage.getItem('shopMenu') ? JSON.parse(localStorage.getItem('shopMenu')) : []
// const menus=jsonData
let subMenu = [];
menus.forEach((item, index) => {
    item.subMenu && item.subMenu.forEach(child => {
        subMenu.push(child);
    })
})

class BreadCrumb extends React.Component {
    createBreadCrumbData = (location, data) => {
        let arrA = [];
        let arrB = [];
        let arrC = [];
        data.forEach(a => {
            if (location.pathname === a.path) {
                arrA.push(a);
            }
            if (a.children && a.children.length > 0) {
                a.children.forEach(b => {
                    if (location.pathname === b.path) {
                        arrB.push(b);
                        arrA.push({
                            icon: a.icon || '',
                            path: a.path,
                            title: a.title
                        });
                    }

                    if (b.path.includes("/:") && location.pathname.includes(b.path.split("/:")[0])) {
                        arrB.push(b);
                        arrA.push({
                            icon: a.icon || '',
                            path: a.path,
                            title: a.title
                        });
                    }

                    if (b.children && b.children.length > 0) {
                        b.children.forEach(c => {
                            if (location.pathname === c.path) {
                                arrC.push(c);
                                arrB.push({
                                    icon: b.icon || '',
                                    path: b.path,
                                    title: b.title
                                });
                                arrA.push({
                                    icon: a.icon || '',
                                    path: a.path,
                                    title: a.title
                                });
                            }
                        });
                    }
                });
            }
        });
        return [...arrA, ...arrB, ...arrC];
    };

    render() {
        const { location } = this.props
        const routes = this.createBreadCrumbData(location, subMenu)
       
        if (!routes.length) return null;
        const itemRender = (route, params, routes, paths) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? <span >{route.icon && <Icon type={route.icon} />} {route.title}</span> : <span>{route.icon && <Icon type={route.icon} />} {route.title}</span>
        };
        return (
            <div className="breadCrumb">
                <Breadcrumb routes={routes} itemRender={itemRender}></Breadcrumb>
            </div>
        );
    }
}

export default withRouter(BreadCrumb)
