import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider, Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists, storeDelete } from '@/api/store'
import { panicLists, panicDel, panicSave, setStatus } from '@/api/panic-buying'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item;
const { TabPane } = Tabs
class PanicBuyingLists extends React.Component {
    state = {
        columns: [
            {
                title: '活动名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '活动类型',
                dataIndex: 'type',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.type == 1
                                    ?
                                    <p>流单专场</p>
                                    :
                                    null
                            }
                            {
                                rowData.type == 2
                                    ?
                                    <p>指定商品</p>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title: '商品数量',
                dataIndex: 'goods_num',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.goods_num}</p>
                    )
                }
            },
            {
                title: '每天活动时间',
                dataIndex: 'start_at',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.start_at}-{rowData.end_at}</p>
                    )
                }
            },
            {
                title: '允许工作日开放',
                dataIndex: 'weekday_open',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.weekday_open == 1
                                    ?
                                    <p>是</p>
                                    :
                                    null
                            }
                            {
                                rowData.weekday_open == 0
                                    ?
                                    <p>否</p>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title: '是否启用',
                dataIndex: 'status',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 2
                                    ?
                                    <Tag color={`orange`}>未启用</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`green`}>已启用</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: 'time',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '25%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 2
                                    ?
                                    <>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleDelete(rowData) }}>删除</Button>
                                        <Divider type={`vertical`}></Divider>
                                    </>
                                    :
                                    null
                            }

                            {
                                rowData.status == 1
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => { this.handleStatus(rowData, 2) }}>结束活动</Button>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => { this.handleStatus(rowData, 1) }}>开启活动</Button>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
        ],
        searchForm: {
            name: ''
        },
        tableData: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        poster: '',
        path: '',
        activeStatus: '0',
        tabLists: [
            {
                name: '全部',
                status: '0',
            },
            {
                name: '未开启',
                status: '2',
            },
            {
                name: '已开启',
                status: '1',
            }
        ]


    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            tableLoading: true
        })
        panicLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    searchForm,
                    page: 1
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname: `/panic-buying/add`
        })
    }

    handleSet = () => {
        this.props.history.push({
            pathname: `/panic-buying/setting`
        })
    }

    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })

    }
    clickTab = val => {
        this.setState({
            activeStatus: val,
        }, () => {
            this.fetchData()
        })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname: `/panic-buying/add/${rowData.id}`
        })
    }
    handleDetail = rowData => {
        this.props.history.push({
            pathname: `/panic-buying/detail/${rowData.id}`
        })
    }

    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title: `是否删除该活动？`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                panicDel(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }
    handleStatus = (rowData, type) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        sendData.status = type || ``;
        if (rowData.type == 2) {
            Modal.confirm({
                title: `活动结束后，客户将不能继续参加该活动，确认提前结束活动吗？`,
                onOk: () => {
                    this.setState({
                        isLoading: true
                    })
                    setStatus(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }

                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                }
            })
        } else if (rowData.type == 1) {
            Modal.confirm({
                title: `是否立即启用该活动？`,
                onOk: () => {
                    this.setState({
                        isLoading: true
                    })
                    setStatus(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }

                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                }
            })
        }
    }
    handleShare = row => {
        this.setState({
            poster: row.code_url,
            path: `/pages/index/index?store_id=${row.id}`
        })
        this.ShareModal.show()
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectRows: selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return (
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={this.state.poster} path={this.state.path} ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'活动名称'} className={'fl'}>
                            {
                                getFieldDecorator('name', {
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索活动名称' />
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary' onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增抢购活动</Button>
                    <Button onClick={this.handleSet} className='mgl20'>抢购活动设置</Button>
                </div>
                <div className={``}>
                    <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                        {
                            this.state.tabLists.map((item, index) => (
                                <TabPane tab={item.name} key={item.status}></TabPane>
                            ))
                        }
                    </Tabs>
                </div>
                <div className={'content-body mgt20'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}
export default Form.create({})(withRouter(PanicBuyingLists))
