import React from 'react'
import { PhotoProvider, PhotoView  } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Form, Steps, Col, Row, Tag, Avatar, Button, Modal, Input, Card, } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ExamineModal from './components/examineModal'
import { signatureDetail, signatureSetStatus } from '@/api/user'
import CX from '@/utils/CX.js'
import '@/assets/css/order/index.scss'
const FormItem = Form.Item



class SignDetail extends React.Component {
    state = {

        isLoading: false,
        formData:{},
        userinfo:{},

    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchData()
        }
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        signatureDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail
                    let user = res.data.user
                    this.setState({
                        formData: detail,
                        userinfo:user
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleCheck=()=>{
       
        this.ExamineModal.show(()=>{

            this.props.history.push({
                pathname: '/sign/lists'
            })
        })
    }
    downloadImg=(img_url)=>{
        let url;
        if(img_url){
            url = img_url
        }else{
            url = 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        }
        
        CX.downloadImage(url,'签名照片')
    }

    render() {
        let userinfo = this.state.userinfo || {}
        let formData = this.state.formData || {}
        return (
            <div className={`shadow-radius`}>


                <Form className={'  '} layout={`inline`}>
                    <div className={`msg-title`}>基本信息</div>
                    <div className="flex pd20">
                        <Card
                            bordered={false}
                            style={{ width: 240 }}
 
                        >
                            <div className="tac">
                                <Avatar size={100} src={ userinfo.head_img_url || "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />
                                <Card.Meta title={userinfo.name} description={'ID:'+userinfo.id} className="mgt20" />
                            </div>

                        </Card>
                        <div className="flex1 mgl20">
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'昵称'} >
                                        <div className={``}>
                                            <p>{userinfo.name}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'ID'} >
                                        <div className={``}>
                                            <p>{userinfo.id}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'真实姓名'} >
                                        <div className={``}>
                                            <p>{formData.real_name || ''}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'客户来源'} >
                                        <div className={``}>
                                            <p>微信公众号</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'手机号'} >
                                        <div className={``}>
                                            <p>{userinfo.mobile}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'性别'} >
                                        <div className={``}>
                                            <p>{userinfo.sex === 0 ? '未知':userinfo.sex === 1? '男':'女'}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'注册时间'} >
                                        <div className={``}>
                                            <p>{userinfo.created_at}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'微信号'} >
                                        <div className={``}>
                                            <p>{ }</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'最后访问'} >
                                        <div className={``}>
                                            <p>{userinfo.last_visit_time || '-'}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'地区'} >
                                        <div className={``}>
                                            <p>{ }</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'生日'} >
                                        <div className={``}>
                                            <p>{userinfo.birthday || '-'}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'邀请人'} >
                                        <div className={``}>
                                            <p>{ }</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className={`msg-title`}>
                        <span>审核信息</span>
                    </div>
                    <div className="flex">
                        <Card style={{ width: 330 }} bordered={false}>
                            <div className="flex align-center">
                                <span>签名照片</span>
                                <Button type='link' className='mgl20' onClick={()=>{this.downloadImg(formData.img_url)}}>下载签名照片</Button>
                            </div>
                            <div className="mgt20">
                                <PhotoProvider>
                                    <PhotoView  src={formData.img_url ||"https://paimaimall.oss-cn-beijing.aliyuncs.com/upload/image/2024/01/25/6d7cbfd96e1c70c3960e58015c042f4a.jpeg"}>
                                    <img src={formData.img_url ||"https://paimaimall.oss-cn-beijing.aliyuncs.com/upload/image/2024/01/25/6d7cbfd96e1c70c3960e58015c042f4a.jpeg"} alt="" height={200} width={240} style={{ objectFit: 'cover' }} className='text_link' />
                                    </PhotoView >
                                </PhotoProvider>
                            </div>
                        </Card>
                        <div className="flex1 pdt50">
                            <Row>
                                <Col span={8}>
                                    签署人信息
                                </Col>
                                <Col span={8}>
                                    持卡人信息
                                </Col>
                                <Col span={8}>
                                    审核信息
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label={'签署人'} >
                                        <div className={``}>
                                            <p>{formData.real_name || ''}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'银行持卡人'} >
                                        <div className={``}>
                                            <p>{ }</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'审核结果'} >
                                        <div className={``}>
                                            <p>{formData.status === 0 ? '待审核': formData.status === 1? "审核通过":'已拒绝'}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <FormItem label={'身份证号'} >
                                        <div className={``}>
                                            <p>{formData.idcard || ''}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem label={'审批人'} >
                                        <div className={``}>
                                            <p>{formData.audit_info? formData.audit_info.name : ''}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}></Col>
                                <Col span={8}>
                                    <FormItem label={'审核人手机号'} >
                                        <div className={``}>
                                            <p>{formData.audit_info? formData.audit_info.mobile: ''}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}></Col>
                                <Col span={8}>
                                    <FormItem label={'审核人意见'} >
                                        <div className={``}>
                                            <p>{formData.remark}</p>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
                <Button type='primary' className='fr mgr20' onClick={()=>{this.handleCheck()}}>审核签名</Button>

                <ExamineModal onRef={e=>this.ExamineModal=e} data={this.state.formData}/>

                {this.state.isLoading ? <Loading /> : null}


            </div>
        )
    }

}

export default Form.create({})(withRouter(SignDetail))
