import React from 'react'
import { Form, Col, Row, Table } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import { timeDiscountDetail } from '@/api/time-discount'
const FormItem = Form.Item

class PanicBuyingDetail extends React.Component {
    state = {
        formData:'',
        columns:[
            {
                title:'商品名称',
                dataIndex:'name',
                width:'30%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p>&yen;{rowData.price}</p>
                    )
                }
            },
            {
                title:'可销售库存',
                dataIndex:'can_sale_stock',
                render:(text, rowData, index) => {
                    return (
                        <div>{ rowData.can_sale_stock || `未设置` }</div>
                    )
                }
            },
            {
                title:'优惠后折扣价',
                dataIndex:'show_price',
                render:(text, rowData, index) => {
                    return (
                        <div>{ rowData.show_price || `未设置` }</div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading:true
        })
        timeDiscountDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail,
                        goods_list = detail.goods_list || [];
                    detail.suit_stores = detail.suit_store_ids;
                    if(detail.limit_buy > 0){
                        detail.limit_buy_type = 1
                    }else {
                        detail.limit_buy_type = 0
                    }

                    this.setState({
                        formData:detail,
                        tableData:goods_list
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    render() {
        let data = this.state.formData || {};
        console.log('data',data)
        return(
            <div className={`shadow-radius`}>
                <div className={`msg-title`} >活动信息</div>
                <div className={`pd15`}>
                    <Form className={``} layout={`inline`}>
                        <Row>
                            <Col span={12}>
                                <FormItem label={`活动名称`}>
                                    <div className={``}>{ data.name }</div>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={`活动时间`}>
                                    <div className={``}>{ data.start_time }~{ data.end_time }</div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label={`优惠叠加`}>
                                    {
                                        data.can_use_coupon == 1
                                            ?
                                            <div className={``}>可叠加优惠券</div>
                                            :
                                            <div className={``}>不可叠加优惠券</div>
                                    }
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={`活动门店`}>
                                    {
                                        data.suit_store_type == 1
                                            ?
                                            <div className={``}>全部门店</div>
                                            :
                                            <div className={``}>{ data.suit_stores && data.suit_stores.length  }家</div>
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label={`参与设置`}>
                                    {
                                        data.join_set == 1
                                            ?
                                            <div className={``}>所有客户</div>
                                            :
                                            data.join_set == 1?
                                            <div className={``}>仅限新客户</div>
                                            :
                                            <div className={``}>仅标签用户</div>

                                    }
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={`限购方式`}>
                                    {
                                        data.limit_buy == 0
                                            ?
                                            <div className={``}>不限购</div>
                                            :
                                            <div className={``}>每人限购{ data.limit_buy }件</div>
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={`msg-title`} >活动商品</div>
                <div className={'content-body mgt10'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(PanicBuyingDetail))
