import React from 'react'
import { Form, Input, Radio, Button, Select, Icon, Checkbox, TreeSelect, Modal, Tooltip } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import '@/assets/css/item/item.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Editor from '@/components/Editor/Editor'
import ItemSku from './item-sku'
import ItemStorePrice from './item-store-price'
import PropTypes from "prop-types";
import { itemAdd, itemCates, addCate, itemDetail, itemEdit } from '@/api/item'
import { expressTemplateLists } from '@/api/express'
import AddCategory from './addCategory'
import message from '@/utils/message'
const FormItem = Form.Item
const { SHOW_ALL } = TreeSelect;
const SaleChannelTip = <div>线上销售：商品支持微信小程序(包含扫码在线购)、H5商城展示和购买。<br/>线下销售：线下销售商品仅PC电脑端直接开单或用于扫桌码点餐使用</div>


class Step1 extends React.Component {
    static contextTypes = {
        setLoading: PropTypes.func,
        setContextState:PropTypes.func,
        showItemStorePrice:PropTypes.bool
    }

    state = {
        formData: '',
        cateLists:[],
        expressLists:[],
        cateVisible:false,
        cateModalLoading:false,
        currentCate:null,
        showEditor:false,  //富文本编辑器
        showSku:false, //sku
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchCate()
        this.fetchExpress()
        if(this.props.match.params.id){
            this.fetchData()
        }else {
            this.setState({
                showEditor:true,
                showSku:true
            })
            this.context.setContextState({
                showItemStorePrice:true,
            })
        }
    }

    fetchData = (params = {}) => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.context.setLoading(true);
        itemDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail,
                        goods_extend = detail.goods_extend;
                    for(var i in goods_extend){
                        if(!detail[i]){
                            detail[i] = goods_extend[i]
                        }
                    }
                    delete detail['goods_extend']
                    if(detail.limit_buy > 0){
                        detail.is_limit_buy = 1;
                    }
                    let showItemStorePrice = !detail.props
                    this.setState({
                        formData:detail,
                        showEditor:true,
                        showSku:true
                    })
                    this.context.setContextState({
                        showItemStorePrice:showItemStorePrice,
                    })

                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })

    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.context.setLoading(true)
        itemCates(sendData)
            .then(res => {
                let lists = res.data.list.data;
                lists.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })
                this.setState({
                    cateLists:lists
                })

            })
            .finally(res => {
                this.context.setLoading(false)
            })
    }
    fetchExpress = (params = {}) => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        if(params.showLoading){
            this.context.setLoading(true);
        }
        expressTemplateLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.data.data;
                    this.setState({
                        expressLists:lists
                    })
                }
            })
            .finally(() => {
                if(params.showLoading){
                    this.context.setLoading(false);
                }
            })
    }
    handleAdd = () => {
        this.setState({
            currentCate:null,
            cateVisible:true
        })
    }
    handleAddCate = () => {
        this.formRef.getData(res => {
            if(res){
                if(this.state.cateModalLoading){ return; }
                this.setState({
                    cateModalLoading:true
                })
                res.pid = 0;
                addCate(res)
                    .then(resp => {
                        if(resp.code == 1){
                            message.success(resp.msg)
                                .then(() => {
                                    this.fetchCate()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            cateModalLoading:false,
                            cateVisible:false
                        })
                    })


            }
        })

    }
    handleCateCancel = () => {
        this.setState({
            cateVisible:false
        })
    }
    handleRadioChange = e => {

    }
    handleInputChange = e => {
        let value = e.target.value,
            name = e.currentTarget.dataset.name,
            formData = this.state.formData || {}
        formData[name] = value;
        this.setState({
            formData
        })
    }

    changeImg = e => {
        let name = e.currentTarget.dataset.name || ``,
            index = e.currentTarget.dataset.index || ``,
            formData = this.state.formData || {};

        this.Gallery.show(imgs => {
            formData[name][index] = imgs[0]
            this.setState({
                formData
            })
        })

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover_img':
                case 'share_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if(formData['pic_urls'].length > 10){
                        formData['pic_urls'] = formData['pic_urls'].slice(0,10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleSort = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = urls.splice(oldIndex,1)[0];
        urls.splice(newIndex,0,targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }
    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }
    saveData = () => {
        let formData = this.state.formData || {},
            sendData = {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                console.log(values);
                sendData = values;
                sendData.cover_img = formData.cover_img || ``;
                sendData.pic_urls = formData.pic_urls || [];
                sendData.share_img = formData.share_img || ``;
                sendData.content = formData.content || ``;
                sendData.store_price = this.ItemStorePrice ? this.ItemStorePrice.getData() : [];
                let skuInfo = this.ItemSku ?  this.ItemSku.getData() : {};
                sendData.norms = skuInfo.norms.length ? skuInfo.norms : '';
                sendData.props = Object.keys(skuInfo.props).length ? skuInfo.props : '';
                sendData.sku_props = Object.keys(skuInfo.sku).length ? skuInfo.sku : '';
                sendData.goods_type = values.goods_type;
                if(sendData.is_limit_buy == 0){
                    sendData.limit_buy = 0
                }
                this.context.setLoading(true);
                if(this.props.match.params.id){
                    sendData.id = this.props.match.params.id
                    itemEdit(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success(res.msg)
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/item/lists`
                                        })
                                    })

                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }else {
                    itemAdd(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success(res.msg)
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/item/lists`
                                        })
                                    })

                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }



            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    {/*<FormItem label={'商品类型'}>*/}
                        {/*{getFieldDecorator('goods_type', {*/}
                            {/*initialValue: data.goods_type || 1,*/}
                            {/*rules: [*/}
                                {/*{*/}
                                    {/*required: true,*/}
                                    {/*message: '请选择商品类型'*/}
                                {/*}*/}
                            {/*]*/}
                        {/*})(*/}
                            {/*<Radio.Group onChange={ this.handleRadioChange } disabled={!!this.props.match.params.id}>*/}
                                {/*<Radio value={1}>普通商品</Radio>*/}
                                {/*/!*<Radio value={2}>服务项目</Radio>*!/*/}
                            {/*</Radio.Group>*/}
                        {/*)}*/}
                    {/*</FormItem>*/}
                    <FormItem
                        label={
                            <span>
                                销售渠道
                                <Tooltip placement={`right`} title={SaleChannelTip}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator('sell_channel', {
                            initialValue: data.sell_channel || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择销售渠道'
                                }
                            ]
                        })(
                            <Radio.Group onChange={ this.handleRadioChange } disabled={!!this.props.match.params.id}>
                                <Radio value={1}>线上销售</Radio>
                                {/* <Radio value={2}>线下销售</Radio> */}
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem
                        label={
                            <span>
                                商品类型
                                {/* <Tooltip placement={`right`} title={SaleChannelTip}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                </Tooltip> */}
                            </span>
                        }
                    >
                        {getFieldDecorator('goods_type', {
                            initialValue: data.goods_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择商品类型'
                                }
                            ]
                        })(
                            <Radio.Group onChange={ this.handleRadioChange } disabled={!!this.props.match.params.id}>
                                <Radio value={1}>普通商品</Radio>
                                <Radio value={3}>抢购商品</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'商品名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入商品名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入商品名称，限50字符'}
                            onChange={this.handleInputChange}
                            data-name={`name`}
                            maxLength={50}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/50</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'统一零售价'}>
                        <div>
                            {getFieldDecorator('price', {
                                initialValue: data.price || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入商品零售价'
                                    }
                                ]
                            })(<Input placeholder={'请输入商品零售价'} onBlur={this.handleInputChange} data-name={`price`} addonBefore={`元`} />)}
                        </div>
                        <span className={`fi-help-text`}>如果商品设置了门店自主定价，则以门店自主定价为准</span>
                    </FormItem>
                    <FormItem label={'统一划线价'}>
                        <div>
                            {getFieldDecorator('market_price', {
                                initialValue: data.market_price || ``,
                            })(<Input placeholder={'请输入商品划线价'} onBlur={this.handleInputChange} data-name={`market_price`} addonBefore={`元`} />)}
                        </div>
                        <span className={`fi-help-text`}>如果商品设置了门店自主定价，则以门店自主定价为准</span>
                    </FormItem>
                    <FormItem label={'商品编码'}>
                        {getFieldDecorator('goods_code', {
                            initialValue: data.goods_code || ``,
                        })(<Input placeholder={'请输入商品编码'} onBlur={this.handleInputChange} data-name={`goods_code`} />)}
                        <span className={`fi-help-text`}>如无编码系统将自动生成</span>
                    </FormItem>
                    <FormItem label={'商品条码'}>
                        {getFieldDecorator('goods_barcode', {
                            initialValue: data.goods_barcode || ``,
                        })(<Input placeholder={'商品条形码'} onBlur={this.handleInputChange} data-name={`goods_barcode`} />)}
                    </FormItem>
                    <FormItem label={'商品副标题'}>
                        {getFieldDecorator('subtitle', {
                            initialValue: data.subtitle || ``,
                        })(<Input
                            placeholder={'商品副标题，最多30个字符'}
                            maxLength={30}
                            onChange={this.handleInputChange}
                            data-name={`subtitle`}
                            suffix={<span>{data.subtitle && data.subtitle.length ? data.subtitle.length : 0}/30</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'商品封面图'} required={true}>
                        <div className={`clearfix`}>
                            {
                                data.cover_img
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.cover_img }
                                        onDel={ e => { this.delImg(e,'cover_img') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'cover_img') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>建议尺寸：750x750像素，单张不超过2M</span>
                    </FormItem>
                    <FormItem label={'商品轮播图'} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.pic_urls && data.pic_urls.length
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.pic_urls }
                                        onDel={ e => { this.delImg(e,'pic_urls') } }
                                        onSort={ e => { this.handleSort(e,'pic_urls') } }
                                    ></PictureCard>
                                    :
                                    null
                            }
                            {
                                data.pic_urls && data.pic_urls.length >= 10
                                    ?
                                    null
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'pic_urls') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>
                                建议尺寸：750x750像素，单张不超过2M
                                <br/>
                                最多可上传10张
                            </span>
                    </FormItem>
                    <FormItem label={'商品分类'}>
                        <div>
                            {
                                getFieldDecorator('category_ids',{
                                    initialValue: data.category_ids || [],
                                    rules:[
                                        {
                                            required:true,
                                            message: '请选择商品分类'
                                        }
                                    ]
                                })(
                                    <TreeSelect
                                        treeData={ this.state.cateLists }
                                        treeCheckable={true}
                                        showCheckedStrategy={SHOW_ALL}
                                        style={{width:'300px'}}
                                        placeholder={'请选择'}
                                    />
                                )
                            }
                            <Button type={'primary'} ghost onClick={this.handleAdd} icon={'plus'} className={`mgl10`}>新增分类</Button>
                        </div>
                    </FormItem>
                    <FormItem label={'商品单位'}>
                        {
                            getFieldDecorator('unit',{
                                initialValue: data.unit || 1,
                                rules:[]
                            })(
                                <Select style={{width:'300px'}} placeholder={'请选择'} >
                                    <Select.Option value={1} key={1}>件</Select.Option>
                                    <Select.Option value={2} key={2}>份</Select.Option>
                                    <Select.Option value={3} key={3}>杯</Select.Option>
                                    <Select.Option value={4} key={4}>个</Select.Option>
                                    <Select.Option value={5} key={5}>套</Select.Option>
                                    <Select.Option value={6} key={6}>片</Select.Option>
                                    <Select.Option value={7} key={7}>次</Select.Option>
                                    <Select.Option value={8} key={8}>人</Select.Option>
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem label={'基础销量'}>
                        {getFieldDecorator('basic_sales', {
                            initialValue: data.basic_sales || ``,
                        })(<Input placeholder={'请输入'} maxLength={30} onBlur={this.handleInputChange} data-name={`basic_sales`} />)}
                    </FormItem>
                    <FormItem label={'排序号'}>
                        {getFieldDecorator('sort', {
                            initialValue: data.sort || ``,
                        })(<Input placeholder={''} maxLength={30} onBlur={this.handleInputChange} data-name={`sort`} />)}
                        <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                    </FormItem>
                    <FormItem label={'重量'}>
                        {getFieldDecorator('weight', {
                            initialValue: data.weight || 0,
                        })(<Input type={`number`} placeholder={'请输入'} maxLength={30} addonBefore={`kg`} onBlur={this.handleInputChange} data-name={`weight`} />)}
                    </FormItem>
                    <FormItem label={'分享标题'}>
                        {getFieldDecorator('share_title', {
                            initialValue: data.share_title || ``,
                        })(<Input placeholder={'请输入'} maxLength={30} />)}
                        <span className={`fi-help-text`}>将展示在分享链接中</span>
                    </FormItem>
                    <FormItem label={'分享图'}>
                        <div className={`clearfix`}>
                            {
                                data.share_img
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.share_img }
                                        onDel={ e => { this.delImg(e,'share_img') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'share_img') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M</span>
                    </FormItem>
                    <div className={`msg-title mgb15`}>商品规格信息（SKU）</div>
                    {
                        this.state.showSku
                            ?
                            <ItemSku onRef={e => {this.ItemSku = e}} item={this.state.formData}></ItemSku>
                            :
                            null
                    }
                    {
                        this.context.showItemStorePrice
                            ?
                            <ItemStorePrice onRef={e => { this.ItemStorePrice = e }} item={this.state.formData}></ItemStorePrice>
                            :
                            null
                    }
                    <div className={`msg-title mgb15`}>其他设置</div>
                    <FormItem label={'是否限购'}>
                        {getFieldDecorator('is_limit_buy', {
                            initialValue: data.is_limit_buy || 0,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否限购'
                                }
                            ]
                        })(
                            <Radio.Group onChange={ this.handleRadioChange }>
                                <Radio value={0}>不限</Radio>
                                <Radio value={1}>限购</Radio>
                            </Radio.Group>
                        )}
                        {
                            getFieldValue('is_limit_buy') == 1
                                ?
                                <span>
                                    {getFieldDecorator('limit_buy', {
                                        initialValue: data.limit_buy || 0,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请设置限购数量'
                                            }
                                        ]
                                    })(
                                        <Input type={'number'} placeholder={`请输入限购数量`} style={{ width:'150px' }}  />
                                    )}
                                    /人
                                </span>
                                :
                                null
                        }

                    </FormItem>
                    {/*<FormItem label={'允许会员使用折扣'}>*/}
                        {/*{getFieldDecorator('allow_member_discount', {*/}
                            {/*initialValue: data.allow_member_discount || 1,*/}
                            {/*rules: [*/}
                                {/*{*/}
                                    {/*required: true,*/}
                                    {/*message: '请选择是否允许使用折扣'*/}
                                {/*}*/}
                            {/*]*/}
                        {/*})(*/}
                            {/*<Radio.Group onChange={ this.handleRadioChange }>*/}
                                {/*<Radio value={1}>允许</Radio>*/}
                                {/*<Radio value={0}>不允许</Radio>*/}
                            {/*</Radio.Group>*/}
                        {/*)}*/}
                    {/*</FormItem>*/}
                    <FormItem label={'物流方式'}>
                        {getFieldDecorator('express_set', {
                            initialValue: data.express_set || [1],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择物流方式'
                                }
                            ]
                        })(
                            <Checkbox.Group >
                                <Checkbox value={1}>快递配送</Checkbox>
                                {/* <Checkbox value={2}>到店自提</Checkbox> */}
                            </Checkbox.Group>
                        )}
                    </FormItem>
                    {
                        getFieldValue('express_set').includes(1)
                            ?
                            <FormItem label={'选择快递模板'}>
                                {
                                    getFieldDecorator('post_template_id',{
                                        initialValue:data.post_template_id || undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择运费模板'
                                            }
                                        ]
                                    })(
                                        <Select style={{width:'300px'}} placeholder={'请选择'} >
                                            {
                                                this.state.expressLists.map((item,index) => (
                                                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/express/template'} target={`_blank`} >
                                    <Button type={'primary'} ghost icon={'plus'} className={`mgl10`}>新增运费模板</Button>
                                </Link>
                                <Button type={'primary'} ghost icon={`sync`} className={`mgl10`} onClick={ () => { this.fetchExpress({ showLoading : true }) } } >刷新模板数据</Button>


                            </FormItem>
                            :
                            null
                    }
                    <div className={`msg-title mgb15`}>商品详情</div>
                    <FormItem label={'商品详情'}>
                        {
                            this.state.showEditor
                                ?
                                <Editor id={'text'} value={data.content} callback={ content => this.EditorChange(content) }></Editor>
                                :
                                null
                        }
                    </FormItem>


                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>



                </Form>
                {
                    this.state.cateVisible
                        ?
                        <Modal
                            title={'添加分类'}
                            visible={this.state.cateVisible}
                            onOk={this.handleAddCate}
                            onCancel={this.handleCateCancel}
                            maskClosable={false}
                            confirmLoading={this.state.cateModalLoading}
                        >
                            <AddCategory
                                data={this.state.currentCate}
                                visible={this.state.cateVisible}
                                wrappedComponentRef={form => {this.formRef = form} }
                            ></AddCategory>
                        </Modal>
                        :
                        null
                }


            </div>
        )

    }
}
export default Form.create({})(withRouter(Step1))
