import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Checkbox, Tooltip, Icon, Switch } from 'antd'
import { getMessageConfig, setMessageConfig ,setMessagePublicConfig} from '@/api/message'
import { getConfig, setConfig } from '@/api/config'
import { adminDetail } from '@/api/admin'
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
import message from '@/utils/message'


const FormItem = Form.Item

class SystemMessage extends React.Component {
    state = {
        subscribe_config: '',
        template_config:'',
        isLoading: false,
        isOpenNotice: 0,
        version_id: ''
    }
    componentDidMount() {
        this.fetchData()
        this.fetchConfig()
        this.fetchAdmin()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading: true
        })
        getMessageConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    let subscribe_config = res.data.subscribe_config || '',
                    template_config=res.data.template_config || ''

                    this.setState({
                        subscribe_config,
                        template_config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    fetchAdmin = () => {
        let sendData = {};
        adminDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let shop = res.data.shop;
                    this.setState({
                        version_id: shop.version_id
                    })
                }
            })
    }
    fetchConfig = () => {
        let sendData = {}
        sendData.key = 'mp_trade_notice'
        this.setState({
            isLoading: true
        })
        getConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    let isOpenNotice = res.data.config.value || 0;
                    this.setState({
                        isOpenNotice
                    })
                    if (isOpenNotice == 1) {
                        CX.openSocket()
                    } else {
                        CX.closeSocket()
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    handleSwitch = val => {
        let sendData = {}
        sendData.key = 'mp_trade_notice';
        if (val) {
            sendData.value = 1;
        } else {
            sendData.value = 0;
        }
        this.setState({
            isLoading: true,
            isOpenNotice: sendData.value
        })
        setConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    if (sendData.value == 1) {
                        message.success('开启成功')
                            .then(() => {
                                window.location.reload()
                            })
                    } else {
                        message.success('消息提醒已关闭')
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }
    handleSave = (e, keyName) => {
        let sendData = {},
            subscribe_config = this.state.subscribe_config || {},
            val = e.target.checked;
        if (val) {
            sendData.is_open = 1
        } else {
            sendData.is_open = 0
        }
        sendData.name = keyName;
        this.setState({
            isLoading: true
        })
        setMessageConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    subscribe_config[keyName]['is_open'] = val;
                    this.setState({
                        subscribe_config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    handleTemSave= (e, keyName) => {
        let sendData = {},
            template_config = this.state.template_config || {},
            val = e.target.checked;
        if (val) {
            sendData.is_open = 1
        } else {
            sendData.is_open = 0
        }
        sendData.name = keyName;
        this.setState({
            isLoading: true
        })
        setMessagePublicConfig(sendData)
            .then(res => {
                if (res.code == 1) {
                    template_config[keyName]['is_open'] = val;
                    this.setState({
                        template_config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    render() {
        let data = this.state.subscribe_config || {}
        let data2=this.state.template_config || {}
        return (
            <div className={`shadow-radius`}>
                <div className={`sysPanel`}>
                    <div className={`sysPanel-con`}>
                        <div className={`sysPanel-title`}>后台消息提醒</div>
                        <div className={`sysPanel-tip`}>开启后，电脑端会有订单消息提醒</div>
                    </div>
                    <div className={`list-switch`}>
                        <Switch onChange={this.handleSwitch} checked={this.state.isOpenNotice == 1 ? true : false}></Switch>
                    </div>
                </div>
                <div className={`content-msg`}>
                    <p>
                        1、使用微信小程序模板消息需先绑定小程序；您需在微信小程序后台开通“订阅消息”功能（行业类目请选择：商家自营 > 机械电子产品，商家自营 > 服饰内衣）。
                    </p>
                    <p>
                        2、每个认证小程序最多可同时启用25个模板ID（小程序相同的模板ID且字段及顺序相同时计为1个）；小程序已添加的模板一旦达到25个，可能无法正常推送消息，此时请商家进入微信公众号后台删除部分不启用的模板。
                    </p>
                </div>
                {
                    this.state.subscribe_config
                        ?
                        <table className="cxtables cxtables-sku mgt15" >
                            <colgroup>
                                <col width={`20%`} />
                                <col width={`40%`} />
                                <col width={`20%`} />
                                <col width={`20%`} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td>消息类型</td>
                                    <td>推送规则</td>
                                    <td>
                                        小程序订阅消息
                                        <Tooltip placement="topLeft" title={`小程序消息启用后，需要用户订阅成功才会收到对应的消息`}>
                                            <Icon type="question-circle" className={`mgl5`} />
                                        </Tooltip>
                                    </td>
                                    <td>公众号模板消息</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="ft-bold">订单消息</td>
                                    <td>
                                        <div className={`lh35`}>订单支付成功后，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.pay_success.is_open}
                                                onChange={(e) => { this.handleSave(e, 'pay_success') }}
                                            ></Checkbox>
                                            启用（类目：{data.pay_success.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={`lh35`}>自提订单核销成功后，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.verification.is_open}
                                                onChange={(e) => { this.handleSave(e, 'verification') }}
                                            ></Checkbox>
                                            启用（类目：{data.verification.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                {
                                    [5, 6, 8].includes(this.state.version_id) ?
                                        <tr>
                                            <td className="ft-bold">包厢预约消息</td>
                                            <td>
                                                <div className={`lh35`}>预订成功后，立即通知</div>
                                            </td>
                                            <td>
                                                <div className={`lh35`}>
                                                    <Checkbox
                                                        className={`mgr5`}
                                                        checked={data.booking.is_open}
                                                        onChange={(e) => { this.handleSave(e, 'booking') }}
                                                    ></Checkbox>
                                                    启用（类目：{data.booking.classify}）
                                                </div>
                                            </td>
                                            <td>-</td>
                                        </tr> : null
                                }

                                <tr>
                                    <td className="ft-bold">优惠券消息</td>
                                    <td>
                                        <div className={`lh35`}>优惠券领取成功时，立即通知（包含其他营销活动发放优惠券）</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.receive_coupon.is_open}
                                                onChange={(e) => { this.handleSave(e, 'receive_coupon') }}
                                            ></Checkbox>
                                            启用（类目：{data.receive_coupon.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td rowSpan={3} className="ft-bold">客户消息</td>
                                    <td>
                                        <div className={`lh35`}>客户成功开通会员卡，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.member_card_open.is_open}
                                                onChange={(e) => { this.handleSave(e, 'member_card_open') }}
                                            ></Checkbox>
                                            启用（类目：{data.member_card_open.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={`lh35`}>客户或商家充值成功时，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.member_card_recharge.is_open}
                                                onChange={(e) => { this.handleSave(e, 'member_card_recharge') }}
                                            ></Checkbox>
                                            启用（类目：{data.member_card_recharge.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={`lh35`}>卡券即将过期时，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.card_expired.is_open}
                                                onChange={(e) => { this.handleSave(e, 'card_expired') }}
                                            ></Checkbox>
                                            启用（类目：{data.card_expired.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td className="ft-bold">活动消息</td>
                                    <td>
                                        <div className={`lh35`}>活动商品即将开售时，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.time_discount_start_notice.is_open}
                                                onChange={(e) => { this.handleSave(e, 'time_discount_start_notice') }}
                                            ></Checkbox>
                                            启用（类目：{data.time_discount_start_notice.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                {
                                    [5, 6, 7].includes(this.state.version_id) ?
                                        <>
                                            <tr>
                                                <td rowSpan="2" className="ft-bold">场馆预约消息</td>
                                                <td>
                                                    <div className={`lh35`}>预订成功后，立即通知</div>
                                                </td>
                                                <td>
                                                    <div className={`lh35`}>
                                                        <Checkbox
                                                            className={`mgr5`}
                                                            checked={data.space_booking_success.is_open}
                                                            onChange={(e) => { this.handleSave(e, 'space_booking_success') }}
                                                        ></Checkbox>
                                                        启用（类目：{data.space_booking_success.classify}）
                                                    </div>
                                                </td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className={`lh35`}>预订失败，立即通知</div>
                                                </td>
                                                <td>
                                                    <div className={`lh35`}>
                                                        <Checkbox
                                                            className={`mgr5`}
                                                            checked={data.space_booking_fail.is_open}
                                                            onChange={(e) => { this.handleSave(e, 'space_booking_fail') }}
                                                        ></Checkbox>
                                                        启用（类目：{data.space_booking_fail.classify}）
                                                    </div>
                                                </td>
                                                <td>-</td>
                                            </tr>
                                        </>

                                        : null
                                }

                                <tr>
                                    <td rowSpan="1" className="ft-bold">活动进度通知</td>
                                    <td>
                                        <div className={`lh35`}>成功后，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.jizan.is_open}
                                                onChange={(e) => { this.handleSave(e, 'jizan') }}
                                            ></Checkbox>
                                            启用（类目：{data.jizan.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>

                                <tr>
                                    <td rowSpan="2" className="ft-bold">拼团消息</td>
                                    <td>
                                        <div className={`lh35`}>拼团结果，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.group_shopping_result.is_open}
                                                onChange={(e) => { this.handleSave(e, 'group_shopping_result') }}
                                            ></Checkbox>
                                            启用（类目：{data.group_shopping_result.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={`lh35`}> 拼团进度有好友参团，立即通知</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.group_shopping_progress.is_open}
                                                onChange={(e) => { this.handleSave(e, 'group_shopping_progress') }}
                                            ></Checkbox>
                                            启用（类目：{data.group_shopping_progress.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td className="ft-bold">签到消息</td>
                                    <td>
                                        <div className={`lh35`}>每天签到提醒</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.sign_notice?.is_open}
                                                onChange={(e) => { this.handleSave(e, 'sign_notice') }}
                                            ></Checkbox>
                                            启用（类目：{data.sign_notice?.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td className="ft-bold">直播开播提醒</td>
                                    <td>
                                        <div className={`lh35`}>直播开播提醒</div>
                                    </td>
                                    <td>
                                        <div className={`lh35`}>
                                            <Checkbox
                                                className={`mgr5`}
                                                checked={data.live_notice?.is_open}
                                                onChange={(e) => { this.handleSave(e, 'live_notice') }}
                                            ></Checkbox>
                                            启用（类目：{data.live_notice?.classify}）
                                        </div>
                                    </td>
                                    <td>-</td>
                                </tr>

                                {
                                    [5, 6, 7].includes(this.state.version_id) ?
                                        <>
                                            <tr>
                                                <td rowSpan="2" className="ft-bold">课程消息</td>
                                                <td>
                                                    <div className={`lh35`}>上课时间确定成功提醒</div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className={`lh35`}>
                                                        <Checkbox
                                                            className={`mgr5`}
                                                            checked={data2.course_table_sign_notice.is_open}
                                                            onChange={(e) => { this.handleTemSave(e, 'course_table_sign_notice') }}
                                                        ></Checkbox>
                                                        启用（类目：{data2.course_table_sign_notice.classify}）
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className={`lh35`}>课程签到成功通知</div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className={`lh35`}>
                                                        <Checkbox
                                                            className={`mgr5`}
                                                            checked={data2.course_table_sign_success.is_open}
                                                            onChange={(e) => { this.handleTemSave(e, 'course_table_sign_success') }}
                                                        ></Checkbox>
                                                        启用（类目：{data2.course_table_sign_success.classify}）
                                                    </div>
                                                </td>
                                            </tr>
                                        </>

                                        : null
                                }
                            </tbody>

                        </table>
                        :
                        null
                }


                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
export default Form.create({})(withRouter(SystemMessage))
